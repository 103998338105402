import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import ComingSoonImage from "../../Resources/Images/comingSoon.png";
import mWebComingSoonImage from "../../Resources/Images/mWebComingSoon.png";
import Design from "../../Resources/Images/comingSoonDesign.svg";
import "./StaticPages.css";
import { FormattedMessage } from 'react-intl';

class ComingSoon extends Component {
  render() {
    let authRedirect = <Redirect to={"/"} />;
    return (
      <div className="comingsoon-main-container">
        
        <div className="comingsoon-desktop-container">
          <div className="comingsoon-left-container">
              <div className="comingsoon-left-container-image">
                <img src={Design} />
              </div>
              <div className="comingsoon-left-container-content">
                <h1 className="comingsoon-left-container-content-h1">
                  <FormattedMessage
                    id={"coming_soon_in_label"}
                    defaultMessage={"Coming Soon in"}
                  />{" "}
                  <br/>
                  <ins style={{textDecoration:"none",color:"red"}}>UK, Mexico, Brazil, Malaysia, Thailand
                  </ins>
                </h1>
                <p className="comingsoon-left-container-content-p1">
                   <FormattedMessage
                    id={"keep_lookout_for_continue_label"}
                    defaultMessage={"Keep a lookout for the Industry's first AI enabled Photography Solution"}
                  />
                  
                </p>
                <p className="comingsoon-left-container-content-p2">
                  <Link
                    to={`/`}
                    style={{ textDecoration: "none", color: "#212121" }}
                  >
                  <FormattedMessage
                    id={"watch_the_space_more_label"}
                    defaultMessage={"WATCH THIS SPACE FOR MORE"}
                  />
                   
                  </Link>
                </p>
              </div>
          </div>
          <div className="comingsoon-right-container">
            <img className="desktop-comingsoon-right-image"
                  src={ComingSoonImage}
                  style={{ float: "right"}}
                />
           <img className="mweb-comingsoon-right-image"
                  src={mWebComingSoonImage}
                  style={{ float: "right"}}
                />
          </div>
        </div>

      </div>
   
    );
  }
}

export default ComingSoon;







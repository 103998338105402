import React, { Component, Fragment } from "react";
import { withStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "../../Axios/OyoStudioWebServiceInstance";
import _ from "lodash";
import GalleryGrid from "react-grid-gallery";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  root: {
    border: "10px solid lightslategray",
    margin: "7rem 2rem",
    borderRadius: "8px",
    overflow: "scroll",
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      width: "40px"
    }
  }
});

class ImagesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryLoader: true,
      images: []
    };
  }

  getGalleryImages = (requirement_id, hideLoader) => {
    if (!hideLoader) {
      this.setState({
        galleryLoader: true
      });
    }

    let url = "/vendors/requirement";
    let params = {
      requirement_id: requirement_id
    };

    axios
      .get(url, { params: params })
      .then(response => {
        let images_data = response.data.data.images;
        let images_grid_data = [];

        images_data.forEach(image => {
          let image_object = {
            src: image.url,
            thumbnail: image.url,
            thumbnailWidth: 320,
            thumbnailHeight: 212,
            caption:
              image.status +
              "(score: " +
              image.score +
              ") => " +
              "URL: " +
              image.url,
            isSelected: image.status == "Accepted"
          };

          images_grid_data.push(image_object);
        });

        this.setState({
          images: images_grid_data,
          galleryLoader: false
        });
      })
      .catch(() => {
        this.setState({
          galleryLoader: false,
          images: []
        });
      });
  };

  componentDidMount = () => {
    let url_array = this.props.location.pathname.split("/");
    let requirement_id = url_array[url_array.length - 2];
    this.getGalleryImages(requirement_id, false);
  };

  render() {
    const { classes } = this.props;
    let galleryDom = null;

    if (this.state.galleryLoader) {
      galleryDom = <CircularProgress className={classes.progress} size={100} />;
    } else {
      let imageSliderDom = (
        <>
          <Typography className={classes.spocDetailsText}>
            No Images Present. Upload Images for this requirement
          </Typography>
        </>
      );
      if (this.state.images && this.state.images.length) {
        imageSliderDom = (
          <>
            <GalleryGrid
              images={this.state.images}
              enableImageSelection={false}
              showLightboxThumbnails={true}
            />
          </>
        );
      }

      galleryDom = <>{imageSliderDom}</>;
    }

    return (
      <div className={classes.root} style={{ position: "relative" }}>
        {galleryDom}
      </div>
    );
  }
}

export default withStyles(styles)(ImagesGrid);

import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import Gallery from "../Gallery/Gallery";
import Uploader from "../S3/Uploader";
import axios from "../../Axios/OyoStudioWebServiceInstance";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = _ => ({
  root: {}
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryImages: [],
      galleryLoader: true,
      imagesCount: 0,
      acceptedImagesCount: 0
    };
  }

  onGalleryImageAddition = (newImageUrl, image_status, image_id) => {
    if (newImageUrl) {
      let oldGalleryImages = Object.assign([], this.state.galleryImages);
      let newGalleryImages = [
        { id: image_id, url: newImageUrl, image_status: image_status }
      ].concat(oldGalleryImages);
      let images_count_data = this.uploadedImagesCount(newGalleryImages);

      this.setState({
        galleryImages: newGalleryImages,
        imagesCount: images_count_data.images_count,
        acceptedImagesCount: images_count_data.accepted_images_count
      });
    }
  };

  onGalleryImageDeletion = image_id => {
    let oldGalleryImages = Object.assign([], this.state.galleryImages);
    if (image_id) {
      let newGalleryImages = oldGalleryImages.filter(obj => {
        return obj.id !== image_id;
      });
      let images_count_data = this.uploadedImagesCount(newGalleryImages);

      this.setState({
        galleryImages: newGalleryImages,
        imagesCount: images_count_data.images_count,
        acceptedImagesCount: images_count_data.accepted_images_count
      });
    }
  };

  uploadedImagesCount = images_array => {
    let images_count_data = {
      images_count: 0,
      accepted_images_count: 0
    };

    if (images_array && images_array.length) {
      let images_count = images_array.length;
      images_count_data.images_count = images_count;

      let accepted_images = images_array.filter(image => {
        return image.image_status == "Accepted";
      });
      let accepted_images_count = accepted_images.length;
      images_count_data.accepted_images_count = accepted_images_count;
    }

    return images_count_data;
  };

  getGalleryImages = (requirement_id, hideLoader) => {
    if (!hideLoader) {
      this.setState({
        galleryLoader: true
      });
    }

    let url = "/vendors/requirement";
    let params = {
      email: localStorage.email,
      requirement_id: requirement_id
    };

    axios
      .get(url, { params: params })
      .then(response => {
        let images_data = response.data.data.images;
        let images = images_data.map(function(image_data) {
          return {
            id: image_data.id,
            url: image_data.url,
            image_status: image_data.status
          };
        });

        let images_count_data = this.uploadedImagesCount(images);
        console.log("images_count_data", images_count_data);
        // let images_count = images.length;
        // let accepted_images = images.filter(image => {
        //   console.log('image', image);
        //   return image.image_status == 'Accepted'
        // })
        // console.log('accepted_images', accepted_images)
        // let accepted_images_count = accepted_images.length;
        this.setState({
          galleryImages: images,
          galleryLoader: false,
          imagesCount: images_count_data.images_count,
          acceptedImagesCount: images_count_data.accepted_images_count
        });
      })
      .catch(() => {
        this.setState({
          galleryLoader: false,
          galleryImages: null,
          imagesCount: 0,
          acceptedImagesCount: 0
        });
      });
  };

  componentDidMount = () => {
    let requirement_id = this.props.requirement_id;
    this.getGalleryImages(requirement_id, false);
  };

  render() {
    const { classes } = this.props;
    let galleryDom = null;

    if (this.state.galleryLoader) {
      galleryDom = <CircularProgress className={classes.progress} size={70} />;
    } else {
      let imageSliderDom = (
        <>
          <Typography className={classes.spocDetailsText}>
            No Images Present. Please choose files and click upload button.
          </Typography>
        </>
      );
      if (this.state.galleryImages && this.state.galleryImages.length) {
        imageSliderDom = (
          <Gallery
            onGalleryImageDeletion={this.onGalleryImageDeletion}
            requirement_images={this.state.galleryImages}
            onUploadImagesRequirementStatusChange={
              this.props.onUploadImagesRequirementStatusChange
            }
          />
        );
      }
      galleryDom = (
        <>
          {imageSliderDom}
          <br />
          <br />
          <Uploader
            images_count={this.state.imagesCount}
            accepted_images_count={this.state.acceptedImagesCount}
            requirement_id={this.props.requirement_id}
            onGalleryImageAddition={this.onGalleryImageAddition}
            onUploadImagesRequirementStatusChange={
              this.props.onUploadImagesRequirementStatusChange
            }
            requirement_name={this.props.requirement_name}
            job_id={this.props.job_id}
          />
        </>
      );
    }
    return <div className={classes.root}>{galleryDom}</div>;
  }
}

export default withStyles(styles)(Header);

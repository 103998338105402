import React from 'react';
import {withStyles} from "@material-ui/core";
import ActionButtons from "../../JobData/ActionButtons/ActionButtons"

const styles = theme => ({
  root: {
    fontFamily: "Inter",
     marginLeft: "48px",
    marginRight: "48px"
  }
});

function JobApprovalRejection(props) {
  const { classes } = props;
  let actionButtonsDom = null;
  let score_calculation_allowed = true //["Live", "Active"].includes(props.jobInfo.property_data.crs_status);
  actionButtonsDom = (
      <ActionButtons
          job_id={props.jobInfo.job_data.job_id}
          allowed_actions={props.jobInfo.permissions.allowed_actions}
          job_completed={props.jobInfo.job_data.job_completed}
          user_type={props.jobInfo.permissions.user_type}
          DrawerClose={props.DrawerClose}
          is_score_calculated={props.jobInfo.job_data.is_score_calculated}
          score_calculation_allowed={score_calculation_allowed}
      />
  );
  return (
      <div className={classes.root}>
        {actionButtonsDom}
      </div>
  );
}


export default withStyles(styles)(JobApprovalRejection);


import React from "react";
import PropTypes from "prop-types";
import { withStyles, Typography } from "@material-ui/core";

const styles = {
  link: {
    textDecoration: "underline",
    color: "rgba(0, 0, 0, 0.54)",
    "&:hover": {
      cursor: "pointer"
    }
  }
};

function CDownloadLink(props) {
  const { classes } = props;
  return (
    <Typography
      className={classes.link}
      onClick={() => window.open(props.href, "_blank")}
    >
      {props.displayName}
    </Typography>
  );
}

CDownloadLink.propTypes = {
  displayName: PropTypes.string,
  href: PropTypes.string.isRequired
};

CDownloadLink.defaultProps = {
  displayName: "File.pdf"
};

export default withStyles(styles)(CDownloadLink);

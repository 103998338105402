import React, {Component} from 'react';
import { withStyles } from "@material-ui/core";
import { CAutoComplete} from "../../../Components/Custom";
import _ from "lodash";
import { injectIntl } from 'react-intl';
import axios from "../../../Axios/OyoStudioWebServiceInstance";


const styles = _ => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px"
  }
})

class Filter extends Component {
  state = {
    filterOptions : {
      jobStatus: [
        { id: "Payment Approved", value: "Payment Approved" },
        { id: "Payment Done", value: "Payment Done" }
      ],
      timeFrame: [
        {id: "Today", value: "Today"},
        {id: "CurrentWeek", value: "Current Week"},
        {id: "CurrentMonth", value: "Current Month (Month Till Date (MTD))"},
        {id: "CurrentYear", value: "CurrentYear (Year Till Date (YTD))"},
        {id: "Custom", value: "Custom"}
      ]
    }
  }

  countryChangeHandler = selectedCountries => {
    let selected = {
      ...this.props.selectedOptions,
      countries: selectedCountries
    };
    this.props.change(selected);
  }

  hubChangeHandler = selectedHubs => {
    let selected = {
      ...this.props.selectedOptions,
      hubs: selectedHubs
    };
    this.props.change(selected);
  }

  jobStatusChangeHandler = selectedJobStatuses => {
    let selected = {
      ...this.props.selectedOptions,
      jobStatus: selectedJobStatuses
    };
    this.props.change(selected);
  }

  timeFrameChangeHandler = selectedTimeFrame => {
    let selected = {
      ...this.props.selectedOptions,
      timeFrame: selectedTimeFrame
    };
    this.props.change(selected);
  }

  loadAsyncValues = (type, waitTime) =>  _.debounce((inputValue, callback) => {
      if (inputValue.length < 3) return;
      let url = "/autocomplete/values";
      let params = {
        item_type: type,
        value: inputValue
      };
      axios
        .get(url, { params: params })
        .then(response => {
          callback(response.data.data);
        })
        .catch(() => {
          callback([]);
        });
    }, waitTime);

  loadJobs = _.debounce((inputValue, callback) => {
    callback(this.state.filterOptions.jobStatus);
  }, 100);

  loadTimeFrames = _.debounce((inputValue, callback) => {
    callback(this.state.filterOptions.timeFrame);
  }, 100);

  render() {
    const { classes, intl} = this.props;
    let loadCountries = this.loadAsyncValues("country", 300);
    let loadHubs = this.loadAsyncValues("hub", 300);
    let countrySelect = (
      <CAutoComplete
        placeholder= {intl.formatMessage({
                        id: "country_select_label",
                        defaultMessage: "Country"
                      })}
        isAsync={true}
        onChange={this.countryChangeHandler}
        loadOptions={loadCountries}
        allowCreate={false}
        isMulti={true}
        valueKey="country"
        labelKey="country"
        style={{
          width: "200px"
        }}
      />
    );

    let hubSelect = (
      <CAutoComplete
        placeholder= {intl.formatMessage({
          id: "hub_select_label",
          defaultMessage: "Hub"
        })}
        isAsync={true}
        onChange={this.hubChangeHandler}
        loadOptions={loadHubs}
        allowCreate={false}
        isMulti={true}
        valueKey="hub"
        labelKey="hub"
        style={{
          width: "200px"
        }}
      />
    );

    let jobSelect = (
      <CAutoComplete
        onChange={this.jobStatusChangeHandler}
        placeholder= {intl.formatMessage({
          id: "job_status_label",
          defaultMessage: "Job Status"
        })}
        isAsync={true}
        loadOptions={this.loadJobs}
        allowCreate={true}
        isMulti={true}
        valueKey="id"
        labelKey="value"
        style={{
          width: "240px"
        }}
      />
    );

    let timeFrameSelect = (
      <CAutoComplete
        onChange={this.timeFrameChangeHandler}
        placeholder= {intl.formatMessage({
          id: "time_frame_select_label",
          defaultMessage: "Time Frame"
        })}
        isAsync={true}
        loadOptions={this.loadTimeFrames}
        allowCreate={true}
        isMulti={false}
        valueKey="id"
        labelKey="value"
        style={{
          marginRight: "30px",
          width: "300px"
        }}
      />
    );

    return (
      <div className={classes.root}>
        {countrySelect}
        {hubSelect}
        {jobSelect}
        {timeFrameSelect}
      </div>
    )
  }
}

export default injectIntl(withStyles(styles)(Filter));
import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { withStyles, Button, Typography } from "@material-ui/core";
import { InputLabel, LinearProgress } from "@material-ui/core";
import CTextField from "../../../Components/Custom/CTextField";
import CSnackbarContent from "../../../Components/Custom/CSnackbarContent";
import axios from "../../../Axios/OyoStudioWebServiceInstance";
import Swal from "sweetalert2";
import { FormattedMessage, injectIntl } from "react-intl";

import { connect } from "react-redux";

import * as actionCreators from "../../../Store/actions/index";

const styles = theme => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    backgroundColor: "white",
    textAlign: "left"
  },
  loginText: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "32px"
  },
  oyoStudioText: {
    color: "rgba(0,0,0,0.38)",
    fontWeight: "bold",
    marginTop: "8px",
    marginBottom: "64px"
  },
  loginRegion: {
    margin: "auto"
  },
  loginButton: {
    width: "100%",
    maxWidth: "370px",
    minWidth: "300px"
  },
  colorPrimary: {
    backgroundColor: "red"
  },
  barColorPrimary: {
    backgroundColor: "black"
  }
});

class ResetPassword extends Component {
  state = {
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    message: null,
    loading: false,
    email: null
  };

  isValid = () => {
    const { intl } = this.props;
    let passwordFormat =
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$";
    let message = null;
    let email = this.state.email || null;
    let oldPassword = this.state.oldPassword || null;
    let newPassword = this.state.newPassword || null;
    let confirmPassword = this.state.confirmPassword || null;
    if (email === null || email.trim() === "") {
      message = intl.formatMessage({
        id: "empty_email_label",
        defaultMessage: "Empty Email, Please Enter Email"
      });
    } else if (this.state.email !== localStorage.email) {
      message = intl.formatMessage({
        id: "invalid_email_label",
        defaultMessage: "Invalid Email, Please enter a valid email"
      });
    } else if (oldPassword === null || oldPassword.trim() === "") {
      message = intl.formatMessage({
        id: "empty_password_label",
        defaultMessage: "Empty Password, Please Enter Password"
      });
    } else if (newPassword === null || newPassword.trim() === "") {
      message = intl.formatMessage({
        id: "empty_password_label",
        defaultMessage: "Empty Password, Please Enter Password"
      });
    } else if (!newPassword.match(passwordFormat)) {
      message = intl.formatMessage({
        id: "password_dose_not_match_constrain_label",
        defaultMessage: "Password should be minimum 8 digits include at least 1 Uppercase letter, 1 Lowercase letter, 1 Special Character and 1 Digit"
      });
    } else if (newPassword !== confirmPassword) {
      message = intl.formatMessage({
        id: "new_password_does_not_match_label",
        defaultMessage: "New Password does not match with confirm password"
      });
    }
    this.setState({
      message: message
    });
    return message === null;
  };

  handleClickShowPassword = password_type => {
    let show_old_password = this.state.showOldPassword;
    let show_new_password = this.state.showNewPassword;
    let show_confirm_password = this.state.showConfirmPassword;
    if (password_type == "old_password") {
      show_old_password = !show_old_password;
    } else if (password_type == "new_password") {
      show_new_password = !show_new_password;
    } else if (password_type == "confirm_password") {
      show_confirm_password = !show_confirm_password;
    }
    this.setState({
      showOldPassword: show_old_password,
      showNewPassword: show_new_password,
      showConfirmPassword: show_confirm_password
    });
  };

  loginClickHandler = () => {
    const { intl } = this.props;
    this.setState({
      loading: true
    });
    let email = this.state.email;
    let oldPassword = this.state.oldPassword;
    let newPassword = this.state.newPassword;
    if (this.isValid()) {
      let url = "/users/password_reset";
      let params = {
        email: email,
        old_password: oldPassword,
        new_password: newPassword
      };
      axios.post(url, params).then(response => {
        if (response.status === 200 && response.data.data) {
          let type = "success";
          let text = intl.formatMessage({
            id: "password_has_successfully_changed_label",
            defaultMessage:
              "Password has been succesfully changed. Login with new password"
          });
          Swal.fire({
            text: text,
            type: type,
            confirmButtonText: intl.formatMessage({
              id: "cool_button_label",
              defaultMessage: "Cool"
            })
          });
          this.clearErrorMessage();
        }
        this.setState({
          loading: false
        });
      });
    } else {
      this.setState({
        loading: false
      });
    }
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { classes, intl } = this.props;
    let resetProgress = null;
    if (this.state.loading) {
      resetProgress = (
        <LinearProgress
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary
          }}
        />
      );
    }
    return (
      <div className={classes.root}>
        <div className={classes.loginRegion}>
          <p
            style={{
              textAlign: "justify",
              color: "red",
              fontSize: "14px",
              width: "300px"
            }}
          >
            {this.state.message}
          </p>
          <form>
            <br />
            <InputLabel htmlFor={"email"}>
              <FormattedMessage
                id={"login_email_label"}
                defaultMessage={"Email"}
              />
            </InputLabel>
            <br />
            <CTextField
              id={"email"}
              autoFocus={true}
              disabled={this.props.loading}
              disableUnderline={true}
              name={"email"}
              width="100px"
              placeholder={"signup_enter_email_label"}
              onChange={this.handleChange}
            />

            <br />
            <br />
            <InputLabel htmlFor={"password"}>
              <FormattedMessage
                id={"old_password_label"}
                defaultMessage={"Old Password"}
              />
            </InputLabel>
            <br />
            <CTextField
              id={"password"}
              type={"password"}
              disabled={this.state.loading}
              placeholder={"old_password_label"}
              name={"oldPassword"}
              onChange={this.handleChange}
              disableUnderline={true}
              showPassword={this.state.showOldPassword}
              handleClickShowPassword={() =>
                this.handleClickShowPassword("old_password")
              }
            />

            <br />
            <br />

            <InputLabel htmlFor={"password"}>
              <FormattedMessage
                id={"new_password_label"}
                defaultMessage={"New Password"}
              />
            </InputLabel>
            <br />
            <CTextField
              id={"password"}
              type={"password"}
              disabled={this.state.loading}
              placeholder={"new_password_label"}
              name={"newPassword"}
              onChange={this.handleChange}
              disableUnderline={true}
              showPassword={this.state.showNewPassword}
              handleClickShowPassword={() =>
                this.handleClickShowPassword("new_password")
              }
            />

            <br />
            <br />

            <InputLabel htmlFor={"password"}>
              <FormattedMessage
                id={"confirm_password_label"}
                defaultMessage={"Confirm Password"}
              />
            </InputLabel>
            <br />
            <CTextField
              id={"password"}
              type={"password"}
              disabled={this.state.loading}
              placeholder={"confirm_password_label"}
              name={"confirmPassword"}
              onChange={this.handleChange}
              disableUnderline={true}
              showPassword={this.state.showConfirmPassword}
              handleClickShowPassword={() =>
                this.handleClickShowPassword("confirm_password")
              }
            />

            <br />
            <br />
            <br />

            <Button
              variant="contained"
              color="primary"
              disabled={this.state.loading}
              className={classes.loginButton}
              onClick={this.loginClickHandler}
              // type="submit"
            >
              <FormattedMessage
                id={"reset_password_button_label"}
                defaultMessage={"Reset Password"}
              />
            </Button>
            {resetProgress}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userType: state.auth.userType,
    error: state.auth.error,
    loading: state.auth.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authorizeUser: (email, password) =>
      dispatch(actionCreators.auth(email, password)),
    clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage())
  };
};

const LoginWithStyles = withStyles(styles)(ResetPassword);
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LoginWithStyles)
);

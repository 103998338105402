import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  FormControl,
  Input,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { FormattedMessage, injectIntl } from "react-intl";
import * as vectors from "../../Resources/Vectors/index";
const styles = _ => ({
  root: {
    width: "100%"
  },
  input: {
    padding: "10px",
    width: "100%",
    height: "48px",
    border: "1px solid rgba(0,0,0,0.08)",
    borderRadius: "4px",
    backgroundColor: "#FAFAFA"
  },
  textArea: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.08)",
    borderRadius: "4px",
    backgroundColor: "#FAFAFA",
    overflow: "scroll"
  }
});

function CTextField(props) {
  const { classes, intl } = props;
  let isPassword = props.type === "password";
  return (
    <Fragment>
      <FormControl className={classes.root}>
        <Input
          id={props.id}
          name={props.name}
          type={props.showPassword ? "text" : props.type || "text"}
          className={props.multiline ? classes.textArea : classes.input}
          disabled={props.loading}
          onChange={props.onChange}
          multiline={props.multiline}
          onPaste={event => {
            if (isPassword) {
              event.preventDefault();
            }
            return !isPassword;
          }}
          onCopy={event => {
            if (isPassword) {
              event.preventDefault();
            }
            return !isPassword;
          }}
          disableUnderline={props.disableUnderline || false}
          placeholder={intl.formatMessage({
            id: props.placeholder
          })}
          required={true}
          autoFocus={props.autoFocus}
          rows={10}
          value={props.value}
          endAdornment={
            isPassword ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={props.handleClickShowPassword}
                >
                  {props.showPassword ? (
                    <img src={vectors.PasswordIconVisOn} />
                  ) : (
                    <img src={vectors.PasswordIconVisOff} />
                  )}
                </IconButton>
              </InputAdornment>
            ) : null
          }
        />
      </FormControl>
    </Fragment>
  );
}

CTextField.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  multiline: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

CTextField.defaultProps = {
  type: "text",
  multiline: false
};

export default injectIntl(withStyles(styles)(CTextField));

let footerPageIds = {
    world_fastes_hotel_chain_label                              :   "World's fastest growing hotel chain.",
    about_us_label                                              :   "About us",
    faqs_label                                                  :   "FAQs",
    contact_us_label                                            :   "Contact us",
    oyo_official_blog_label                                     :   "OYO official blog",
    download_oyo_app_label                                      :   "Download OYO app for exciting offers ",
    learn_more_label                                            :   " Learn more",
    feeling_lucky_label                                         :   "Feeling lucky? Try out a random OYO. ",
    find_oyo_label                                              :   " Find OYO",
    oyo_home_page_label                                         :   "OYO Homepage",
    terms_of_use_label                                          :   "Terms of Use",
    partner_ship_agreement_label                                :   "Partnership Agreement",
    privacy_policy_label                                        :   "Privacy Policy"

}


export default {
    ...footerPageIds
}

let navBarDom = {
    navbar_login_label                              :   "Gabung",
    navbar_faqs_label                               :   "Tanya Jawab",
    navbar_contact_us_label                         :   "Hubungi kami",
    navbar_blog_label                               :   "Blog",
    navbar_about_us_label                           :   "Tentang kami" ,
    appbar_join_now_button_label                    :   "Bergabung sekarang",
    language_label                                  :   "Bahasa",
}

let userDom = {
    navbar_user_my_profile_label                    :   "Profil saya",
    navbar_user_reset_password_label                :   "Setel Ulang Kata Sandi",
    navbar_user_logout_label                        :   "Keluar"
}

let postLogInDom = {
    navbar_post_login_general_label                 :   "UMUM",
    navbar_post_login_my_account_label              :   "AKUN SAYA",
    navbar_post_login_change_password_label         :   "Ganti kata sandi",
    navbar_post_login_log_out_label                 :   "Keluar"
}

let preLogInDom = {
    navbar_pre_login_join_link_label                :   "Ikuti",
    navbar_pre_login_home_link_label                :   "Rumah",
}

let logoLabel = {
    world_leading_photography_parter_network_label  :"Jaringan mitra fotografi terkemuka dunia"
}

export default {
    ...navBarDom,
    ...userDom,
    ...postLogInDom,
    ...preLogInDom,
    ...logoLabel
}
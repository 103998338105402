let faqsPageIds ={
  frequently_asked_questions_label                  :   "Pertanyaan yang Sering Diajukan",
  any_questions_label                               :   "Ada pertanyaan?",
  we_are_here_to_help_label                         :   "Kami di sini untuk membantu",
  contact_us_label                                  :   "Hubungi kami",
  how_to_join_frame_labes                           :   "Bagaimana cara bergabung dengan OYO Frames?",
  click_on_the_label                                :   "Klik pada",
  join_now_label                                    :   "BERGABUNG SEKARANG",
  join_frame_with_details_label                     :   "tombol di beranda. Isi aplikasi dengan informasi yang benar dan sedetail mungkin sebelum mengirimkannya. Anda akan diminta untuk mengirimkan tautan google drive dari portofolio Anda. Anda akan dapat mulai memotret untuk OYO hanya setelah Anda menerima undangan dari kami dan memiliki akun OYO Frames yang disetujui.",
  where_you_can_shoot_question_label                :   "Di mana semua geografi dapat saya potret?",
  where_you_can_shoot_question_answer_label         :   "OYO Frames saat ini aktif di India. Kami meluncurkan di AS dan Inggris segera.",
  guidline_or_rule_question_label                   :   "Apakah ada pedoman atau aturan untuk diikuti saat melakukan pemotretan?",
  guidline_or_rule_question_answer_label            :   "Ya, OYO memiliki panduan fotografi yang dapat diakses oleh seseorang setelah masuk ke portal Frames.",
  selection_criteria_question_label                 :   "Apa kriteria seleksi?",
  selection_criteria_question_answer_label_p1       :   "Setelah Anda mengirimkan aplikasi Anda, tim kami akan memeriksa gambar dan video yang dikirimkan dan memeriksa silang dengan referensi yang diberikan, untuk memvalidasi kepemilikan dan memastikan mereka memenuhi harapan mitra kami. Kualitas akan diutamakan daripada kuantitas, pengalaman sebelumnya di industri perhotelan, khususnya ",
  selection_criteria_question_answer_label_p2       :   " hotel murah ",
  selection_criteria_question_answer_label_p3       :   "akan menjadi keuntungan yang pasti.",
  specific_equipment_question_label                 :   "Apakah ada peralatan khusus yang diperlukan untuk menjadi mitra fotografi di OYO?",
  oyo_require_photographer_have_label               :   "OYO mengharuskan fotografer untuk memiliki:",
  camera_details_label                              :   "Kamera minimum 20 megapiksel.",
  kind_of_lenses_label                              :   "Dua jenis lensa:",
  first_kind_of_lense                               :   "16mm hingga 40mm",
  second_kind_of_lense                              :   "24mm hingga 105mm",
  flashlight_label                                  :   "Senter",
  tripod_label                                      :   "Tripod"
}


export default {
    ...faqsPageIds
}
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./StaticPages.css";
import * as images from "../../Resources/Carousels/index";
import * as vectors from "../../Resources/Vectors/index";

import Ritesh from "../../Resources/Images/Ritesh.png";
import MapImage from "../../Resources/Images/Map.png";
import CityLogo from "../../Resources/Images/Citylogo.svg";
import HotelLogo from "../../Resources/Images/Hotellogo.svg";
import RoomLogo from "../../Resources/Images/Roomlogo.svg";

import HomeDom5 from "../../Resources/Images/HomeDom5.svg";
import { FormattedMessage } from 'react-intl';
import {Dialog ,Button,withStyles} from '@material-ui/core';
import {
  Caption1,
  Caption2,
  Caption3,
  Caption4,
  Caption5,
  Caption6
} from "../../Resources/Carousels/index";
import PropTypes from 'prop-types';
import Odometer from 'react-odometerjs';
import Slider from "react-slick";
import Carousel, { Dots } from '@brainhubeu/react-carousel';


const styles = theme => ({
  root: {},
  paperFullWidth: {
    width: "90%",
    margin: "0  5%"
  }
});


const dsmData={
    "oyoId": "ABC123",
    "overallScore": {
        "displayName": "Overall Score",
        "value": 91.79
    },
    "scoreBreakup": [
        {
            "displayName": "Aesthetic Score",
            "value": 80.5
        },
        {
            "displayName": "Technical Score",
            "value": 91.6
        },
        {
            "displayName": "ExIF Score",
            "value": 92.4
        }
    ],
    "comments": [
        {
            "message": "Image Exposure is not proper"
        },
        {
            "message": "Multiple Entries"
        }
    ]
}
class HomePage extends Component {
  constructor(props){
    super(props);
    this.state= {
      galaryTransalateValue: 0,
      testimonialTransalateValue: 0,
      galaryImagesFlag:false,
      odometerValue1: 0,
      odometerValue2: 0,
      odometerValue3: 0
    }
  }
  
   handlePrevButton = () => {
 
      let prev=this.state.galaryTransalateValue;
     if(prev!== 0)
       prev=prev+380;
      else
       prev=-8360;

      this.setState({galaryTransalateValue:prev})
  }

 componentDidMount = () => {
   this.setState({odometerValue1:3200, odometerValue2:43000,odometerValue3:550});
 }

 handleTestimonialPrevButton = () => {
 
      let prev=this.state.testimonialTransalateValue;
     if(prev!== 0)
       prev=prev+315;
      else
       prev=-1260;

      this.setState({testimonialTransalateValue:prev})
  }

  handleNextButton = () => {
   
      let next=this.state.galaryTransalateValue;
      if(next!== -8360)
         next=next-380;
       else
        next=0;
     
      this.setState({galaryTransalateValue:next})
  }

   handleTestimonialNextButton = () => {
   
      let next=this.state.testimonialTransalateValue;
      if(next!== -1260)
         next=next-315;
       else
        next=0;
     
      this.setState({testimonialTransalateValue:next})
  }
  render() {
    const { classes } = this.props;
    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={"/jobs/search"} />;
    }

   const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: <div className="prev-button-slick-slider" onClick={()=>this.slider.slickPrev()}><img src={vectors.PrevArrow} /></div>,
      nextArrow: <div className="next-button-slick-slider" onClick={()=>this.slider.slickNext()}><div className="right-arrow-circle-container"><img className="right-arrow-image" src={vectors.VectorRight}/></div></div>
    };
   const mWebSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <div className="prev-button-slick-slider" onClick={()=>this.slider.slickPrev()}><img src={vectors.PrevArrow} /></div>,
      nextArrow: <div className="next-button-slick-slider" onClick={()=>this.slider.slickNext()}><div className="right-arrow-circle-container"><img className="right-arrow-image" src={vectors.VectorRight}/></div></div>
    };
    // First DOM
    let firstDom = (

      <div className="first-dom-container">
        <div className="first-dom-container-left">
               <p >
                <FormattedMessage
                id={"welcome_to_oyo_frame_label"}
                defaultMessage={"Welcome to OYO Frames"}
                />
              </p>

              <h1 >
                <FormattedMessage
                id={"be_a_part_and_continue_label"}
                defaultMessage={"Be a part of world's largest photography network"}
                />
              </h1>
        </div>
       

        <div className="first-dom-container-right">
          <div className="first-dom-right-image-container">
            <img className="desktop-hero-image"
                src={vectors.HomePageHeroImage}
              />
            <img className="mweb-hero-image"
                src={vectors.mWebHomePageHeroImage}
              />
          </div>  
        </div>

      </div>
    );

    // Ritesh Quote
    let secondDom = (
     
      <div className="second-dom-container">
        
              <div className="second-outer-container">
                <div className="second-dom-inner-container">
                  <div className="second-dom-background-image"></div>  
                  <img  className="second-dom-ritesh-image" src={Ritesh}  />
                  <div className="second-dom-content-container">
                        <p className="second-dom-container-p">
                        <i>
                          <FormattedMessage
                          id={"second_dom_speech_label"}
                          defaultMessage={"OYO is the fastest-growing and second-largest hotel \
                          chain in the world. With OYO Frames, we welcome all \
                          photographers and videographers to be an integral part \
                          of OYO’s journey as we scale globally. It's still" + " Day 0"+ "at OYO"}
                          />
                        </i>
                        </p>
                        <h6 className="second-dom-container-h6">
                            <FormattedMessage
                            id={"second_dom_name_heading"}
                            defaultMessage={"RITESH AGARWAL, "}
                            />
                          <i>
                            <FormattedMessage
                            id={"second_dom_name_post"}
                            defaultMessage={"Founder & Group CEO, OYO Hotels & Homes"}
                            />
                            </i>
                        </h6>
                  </div>
                    <img className="second-dom-container-image-1"
                      src={images.Vector10}
                    />
                    <img className="second-dom-container-image-2"
                      src={vectors.IsQuote}
                    />

                </div>

              </div>
      </div>
   
    );

    // Statistics DOM


   
    let thirdDom = (

      <div className="third-dom-container">
       <div className="mweb-third-container">
          <Carousel 
            clickToChange
            slidesPerPage={2}
            itemWidth={258}
            infinite={true}
            dots
            >
             <div className="first-inner-container">
                  <img  src={vectors.HomeDom3a} />
                  <h1 >550+</h1>  
                  <p >
                    <FormattedMessage
                      id={"photoshop_happened_label"}
                      defaultMessage={"Photoshoots that happened in the last 30 days across the OYO network globally"}
                      />
                  </p>
         </div>
          <div className="second-inner-container">
                    <img src={vectors.HomeDom3b}  />
                    <h1>43,000+</h1>
                    <p>
                      <FormattedMessage
                        id={"image_clicked_label"}
                        defaultMessage={"Images clicked per day in OYO"}
                        />
                    </p>
          </div>

          <div className="third-inner-container">
                    <img src={vectors.HomeDom3c}  />
                    <h1>550+</h1>
                    <p>
                      <FormattedMessage
                        id={"new_cities_added_label"}
                        defaultMessage={"New cities added to OYO's global footprint this month"}
                        />
                      </p>
          </div>
        </Carousel>
       </div>

      <div className="desktop-third-container">
          <div className="first-inner-container">
                  <img  src={vectors.HomeDom3a} />
                <div  className="odometer-container">
                  <Odometer
                    format=",ddd"
                    duration={ 1*1500 }
                    value={ this.state.odometerValue1 }
                    />
      <h1 className="odometer-plus-header">+</h1>  
                </div>       
                  <p >
                    <FormattedMessage
                      id={"photoshop_happened_label"}
                      defaultMessage={"Photoshoots that happened in the last 30 days across the OYO network globally"}
                      />
                  </p>
            </div>

            <div className="second-inner-container">
                  <img src={vectors.HomeDom3b}  />
                   <div  className="odometer-container-2">
                  <Odometer
                    format="(,ddd)"
                    duration={ 1*500 }
                    value={ this.state.odometerValue2 }
                    />
                    <h1 className="odometer-plus-header">+</h1>  
                </div>    
                  <p>
                    <FormattedMessage
                      id={"image_clicked_label"}
                      defaultMessage={"Images clicked per day in OYO"}
                      />
                  </p>
             </div>

            <div className="third-inner-container">
                  <img src={vectors.HomeDom3c}  />
                  <div  className="odometer-container-3">
                    <Odometer
                    format="(,ddd)"
                    duration={ 1*1500 }
                    value={ this.state.odometerValue3 }
                    />  
      <h1 className="odometer-plus-header">+</h1>  
                  </div>    
                  <p>
                     <FormattedMessage
                      id={"new_cities_added_label"}
                      defaultMessage={"New cities added to OYO's global footprint this month"}
                      />
                    </p>
              </div>

        </div>
      </div>
    );

    //Testimonials
    let testimonialImageArray = [
      images.Vendor1,
      images.Vendor2,
      images.Vendor3,
      images.Vendor4,
      images.Vendor5,
      images.Vendor6
    ];
    let testimonialCaptionArray = [
      <Caption1 />,
      <Caption2 />,
      <Caption3 />,
      <Caption4 />,
      <Caption5 />,
      <Caption6 />
    ];
    let testimonialPreview = [];
    for (let i = 0; i <= testimonialImageArray.length - 1; i++) {
      testimonialPreview.push(
        <div className="testimonial" key={`key-${i}`}>
          <div className="testimonial-image">
            <img src={testimonialImageArray[i]} width="100%" />
          </div>
          {testimonialCaptionArray[i]}
        </div>
      );
    }
    let testimonialDom = (
      
     <div>
       <div className="testimonial-dom-container">
        
        
        <div className="testimonial-dom-left-container">
         
         <div className="testimonial-prev-button" onClick={()=>this.handleTestimonialPrevButton()}><img src={vectors.PrevArrow} /></div>
         <div className="testimonial-all-box-outer-container">
           <div className="testimonial-all-box-container" style={{transition: "transform 0.4s ease-in-out 0s" ,transform: `translateX(${this.state.testimonialTransalateValue}px)`}}>
           <div className="individual-testimonial-slide-container">
             <img  className="testimonial-individual-image" src={images.Vendor1}  />
             <Caption1 className="testimonial-individual-caption"/>
           </div>
           <div className="individual-testimonial-slide-container">
             <img className="testimonial-individual-image" src={images.Vendor2}  />
             <Caption2 className="testimonial-individual-caption"/>
           </div>
           <div className="individual-testimonial-slide-container">
             <img  className="testimonial-individual-image" src={images.Vendor3}  />
             <Caption3 className="testimonial-individual-caption"/>
           </div>
           <div className="individual-testimonial-slide-container">
             <img className="testimonial-individual-image" src={images.Vendor4}  />
             <Caption4 className="testimonial-individual-caption"/>
           </div>
           <div className="individual-testimonial-slide-container">
             <img className="testimonial-individual-image" src={images.Vendor5}  />
             <Caption5 className="testimonial-individual-caption"/>
           </div>
           <div className="individual-testimonial-slide-container">
             <img className="testimonial-individual-image" src={images.Vendor6}  />
             <Caption6 className="testimonial-individual-caption"/>
           </div>
         </div>
         </div>
         <div className="testimonial-next-button" onClick={()=>this.handleTestimonialNextButton()}><div className="right-arrow-circle-container"><img className="right-arrow-image" src={vectors.VectorRight}/></div></div>

        </div>

         <div className="testimonial-dom-right-container">
            <p className="testimonial-dom-right-container-p">
              <FormattedMessage
                    id={"from_oyo_frame_community_label"}
                    defaultMessage={"From the OYO Frames Community"}
                    />
            </p>
            <h4 className="testimonial-dom-right-container-h4">
              
                <FormattedMessage
                    id={"number_of_photograph_onboard_label"}
                    defaultMessage={"500+ Photographers are already onboarded globally on OYO Frames"}
                    />
              
            </h4>
              <img  className="testimonial-dom-right-container-img"src={images.Vector1} />
         </div>
     
      </div> 


        <div className="mweb-testimonial-container">
           <Carousel
              clickToChange
              slidesPerPage={2}
              dots={true}
              itemWidth={280}
              infinite={true}
            >
           <div className="individual-testimonial-slide-container">
             <img  className="testimonial-individual-image" src={images.Vendor1}  />
             <Caption1 className="testimonial-individual-caption"/>
           </div>

           <div className="individual-testimonial-slide-container">
             <img className="testimonial-individual-image" src={images.Vendor2}  />
             <Caption2 className="testimonial-individual-caption"/>
           </div>

           <div className="individual-testimonial-slide-container">
             <img  className="testimonial-individual-image" src={images.Vendor3}  />
             <Caption3 className="testimonial-individual-caption"/>
           </div>

           <div className="individual-testimonial-slide-container">
             <img className="testimonial-individual-image" src={images.Vendor4}  />
             <Caption4 className="testimonial-individual-caption"/>
           </div>

           <div className="individual-testimonial-slide-container">
             <img className="testimonial-individual-image" src={images.Vendor5}  />
             <Caption5 className="testimonial-individual-caption"/>
           </div>

           <div className="individual-testimonial-slide-container">
             <img className="testimonial-individual-image" src={images.Vendor6}  />
             <Caption6 className="testimonial-individual-caption"/>
           </div>

           </Carousel>
         </div>
     
     </div>


    
    );
   
 
    let fifthDom = (
      <div className="fifth-dom-container">

        <div className="fifth-dom-left-container">

          <h2 className="fifth-dom-left-container-header">
            <FormattedMessage
              id={"the_power_of_being_an_label"}
              defaultMessage={"The power of being an"}
            /> 
            <br /> 
            <FormattedMessage
              id={"oyo_framer_label"}
              defaultMessage={" \"OYO Framer\""}
            /> 
          </h2>
          <p className="fifth-dom-left-container-p">
            <FormattedMessage
              id={"turning_photography_dreams_label"}
              defaultMessage={"Turning Photography dreams into reality"}
            /> 
            
          </p>
          <div className="fifth-dom-left-container-description">
            
            <div className="fifth-dom-left-container-inner-left-description">
              <img src={vectors.HomeDom5a} />
                <p>
                <FormattedMessage
                  id={"number_of_photograph_onboard_label"}
                  defaultMessage={"500+ Photographers are already onboarded globally on OYO Frames"}
                  />
                <b>{" "}
                  <FormattedMessage
                    id={"million_label"}
                    defaultMessage={"1.14 Mn"}
                  />
                </b> {" "}
                <FormattedMessage
                    id={"viewer_on_daily_basis"}
                    defaultMessage={"viewers on a daily basis"}
                  />
              </p>
            </div>

            <div className="fifth-dom-left-container-inner-right-description">
              <img src={vectors.HomeDom5b} />
              <p>
                <FormattedMessage
                    id={"grow_with_a_company_thats_growing"}
                    defaultMessage={"Grow with a company that's growing "}
                  /> {" "}
                <b>10X</b>{" "}
                <FormattedMessage
                    id={"annually_label"}
                    defaultMessage={" annually"}
                  /> 
              </p>
            </div>

          </div>

          <div className="fifth-dom-left-container-description">
            
            <div className="fifth-dom-left-container-inner-left-description">
              <img src={vectors.HomeDom5c} />
              <p>
                <FormattedMessage
                    id={"join_frame_and_gain_access_label"}
                    defaultMessage={"Join Frames & gain access to OYO's global footprint of"}
                  /> {" "}
                  <b>800+</b>{" "}
                <FormattedMessage
                    id={"cities_label"}
                    defaultMessage={"cities"}
                  /> 
              </p>
            </div>
          
          <div className="fifth-dom-left-container-inner-right-description">
              <img src={vectors.HomeDom5d} />
              <p>
                <FormattedMessage
                    id={"work_alongside_photographer_label"}
                    defaultMessage={"Work alongside the best photographers across the globe"}
                  /> 
              </p>
            </div>

          </div>

        </div>

        <div className="fifth-dom-right-container">
          <h2 className="fifth-dom-right-container-header">
            <FormattedMessage
              id={"the_power_of_being_an_label"}
              defaultMessage={"The power of being an"}
            /> 
            <br /> 
            <FormattedMessage
              id={"oyo_framer_label"}
              defaultMessage={" \"OYO Framer\""}
            /> 
          </h2>
          <p className="fifth-dom-right-container-p">
            <FormattedMessage
              id={"turning_photography_dreams_label"}
              defaultMessage={"Turning Photography dreams into reality"}
            /> 
            
          </p>
              <img src={HomeDom5}  />
        </div>

      </div> 
   
    );

    //Requirement
    let sixthDom = (
      <div className="sixth-dom-container">
        <h4>
          <FormattedMessage
                id={"we_are_looking_for_partners_label"}
                defaultMessage={"We are looking for partners with"}
              />  
        </h4>
        <div className="sixth-dom-images-container">
          <div className="sixth-dom-individual-image-container">
                  <img src={images.Req1}  />
                  <h6>
                     <FormattedMessage
                      id={"passion_for_photography_label"}
                      defaultMessage={"Passion for Photography"}
                    />  
                  </h6>
                  <p>
                    <FormattedMessage
                      id={"passion_will_always_and_continue_label"}
                      defaultMessage={"Passion will always make you push a little farther, and strive to be better than you were the day before"}
                    />  
                  </p>
          </div>

          <div className="sixth-dom-individual-image-container">
                  <img src={images.Req2}  />
                  <h6>
                    <FormattedMessage
                      id={"intuitive_imaginative_mind_label"}
                      defaultMessage={"Intuitive & Imaginative Mind"}
                    /> 
                    </h6>
                  <p>
                    <FormattedMessage
                      id={"photography_is_afterall_and_continue_label"}
                      defaultMessage={"Photography is afterall an artform and the photographer, the artist"}
                    /> 
                  </p>
          </div>

          <div className="sixth-dom-individual-image-container">
                  <img src={images.Req3}  />
                  <h6>
                    <FormattedMessage
                      id={"proven_experience_label"}
                      defaultMessage={"Proven Experience"}
                    /> 
                    </h6>
                  <p>
                    <FormattedMessage
                      id={"budget_hotel_make_and_continue_label"}
                      defaultMessage={"Budget Hotels make for tricky photography. Become a part of OYO Frames if you understand the nitty-gritties"}
                    />
                  </p>
          </div>

          <div className="sixth-dom-individual-image-container">
                  <img src={images.Req4}  />
                  <h6>
                    <FormattedMessage
                      id={"eye_for_details_label"}
                      defaultMessage={"Eye for Detail"}
                    /> 
                    </h6>
                  <p>
                    <FormattedMessage
                      id={"even_the_smallest_and_continue_label"}
                      defaultMessage={"Even the smallest things can make or break a photograph"}
                    /> 
                  </p>
          </div>

        </div>

      <div className="mweb-sixth-dom-container">
        <Carousel
              clickToChange
              slidesPerPage={2}
              dots={true}
              itemWidth={266}
              infinite={true}
        >

          <div className="sixth-dom-individual-image-container">
                  <img src={images.Req1}  />
                  <h6>
                     <FormattedMessage
                      id={"passion_for_photography_label"}
                      defaultMessage={"Passion for Photography"}
                    />  
                  </h6>
                  <p>
                    <FormattedMessage
                      id={"passion_will_always_and_continue_label"}
                      defaultMessage={"Passion will always make you push a little farther, and strive to be better than you were the day before"}
                    />  
                  </p>
          </div>

          <div className="sixth-dom-individual-image-container">
                  <img src={images.Req2}  />
                  <h6>
                    <FormattedMessage
                      id={"intuitive_imaginative_mind_label"}
                      defaultMessage={"Intuitive & Imaginative Mind"}
                    /> 
                    </h6>
                  <p>
                    <FormattedMessage
                      id={"photography_is_afterall_and_continue_label"}
                      defaultMessage={"Photography is afterall an artform and the photographer, the artist"}
                    /> 
                  </p>
          </div>

          <div className="sixth-dom-individual-image-container">
                  <img src={images.Req3}  />
                  <h6>
                    <FormattedMessage
                      id={"proven_experience_label"}
                      defaultMessage={"Proven Experience"}
                    /> 
                    </h6>
                  <p>
                    <FormattedMessage
                      id={"budget_hotel_make_and_continue_label"}
                      defaultMessage={"Budget Hotels make for tricky photography. Become a part of OYO Frames if you understand the nitty-gritties"}
                    />
                  </p>
          </div>

          <div className="sixth-dom-individual-image-container">
                  <img src={images.Req4}  />
                  <h6>
                    <FormattedMessage
                      id={"eye_for_details_label"}
                      defaultMessage={"Eye for Detail"}
                    /> 
                    </h6>
                  <p>
                    <FormattedMessage
                      id={"even_the_smallest_and_continue_label"}
                      defaultMessage={"Even the smallest things can make or break a photograph"}
                    /> 
                  </p>
          </div>
        </Carousel>
      </div>

      </div>
    
    );

 
    let carouselDom = (
      
      <div className="galary-dom-container">
            <h4 >
              <FormattedMessage
                  id={"our_gallery_label"}
                  defaultMessage={"Our Gallery"}
                /> 
            </h4>
           
              
            <div className="galary-dom-images-container">     
              {/* <img  className="galary-group-images" src={images.GalaryGroupImage}/> */}
                <img  className="galary-left-portion-images" src={images.GalaryLPortionImage}/>
                <img  className="galary-right-portion-images" src={images.GalaryRPortionImage}/>
               <div className="galary-slide-slick-container">
                <Slider {...settings}>
                    <div className="individual-slick-image-container">
                          <img src={images.Img01}  />
                    </div>
                    <div className="individual-slick-image-container">
                          <img src={images.Img02}  />
                    </div>
                    <div className="individual-slick-image-container">
                          <img src={images.Img03} />
                    </div>
                    <div className="individual-slick-image-container">
                          <img src={images.Img04} />
                    </div>
                    <div className="individual-slick-image-container">
                          <img src={images.Img05} />
                    </div>
                    <div className="individual-slick-image-container">
                          <img src={images.Img06} />
                    </div>
                    <div className="individual-slick-image-container">
                          <img src={images.Img07} />
                    </div>
                    <div className="individual-slick-image-container">
                          <img src={images.Img08} />
                    </div>
                    <div className="individual-slick-image-container">
                          <img src={images.Img09} />
                    </div>
                    <div className="individual-slick-image-container">
                            <img src={images.Img10} />
                    </div>
                    <div className="individual-slick-image-container">
                          <img src={images.Img11} />
                    </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img12} />
                      </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img13} />
                      </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img14} />
                      </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img15} />
                      </div>
                      <div className="individual-slick-image-container">
                          <img src={images.Img16} />
                      </div>
                      <div className="individual-slick-image-container">
                          <img src={images.Img17} />
                      </div>
                      <div className="individual-slick-image-container">
                          <img src={images.Img18} />
                      </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img19} />
                      </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img20} />
                      </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img21} />
                      </div>
                      <div className="individual-slick-image-container">
                              <img src={images.Img22} />
                      </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img23} />
                      </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img24} />
                      </div>
                      <div className="individual-slick-image-container">
                            <img src={images.Img25} />
                      </div>
                    
                     </Slider>
                   </div>
              </div>
      
       <div className="mweb-galary-dom-images-container">     
              {/* <img  className="galary-group-images" src={images.GalaryGroupImage}/> */}
                <img  className="mweb-galary-left-portion-images" src={images.GalaryLPortionImage}/>
                <img  className="mweb-galary-right-portion-images" src={images.GalaryRPortionImage}/>
               <div className="mweb-galary-slide-slick-container">
                <Slider {...mWebSettings}>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img01}  />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img02}  />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img03} />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img04} />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img05} />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img06} />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img07} />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img08} />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img09} />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                            <img src={images.Img10} />
                    </div>
                    <div className="mweb-individual-slick-image-container">
                          <img src={images.Img11} />
                    </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img12} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img13} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img14} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img15} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                          <img src={images.Img16} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                          <img src={images.Img17} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                          <img src={images.Img18} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img19} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img20} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img21} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                              <img src={images.Img22} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img23} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img24} />
                      </div>
                      <div className="mweb-individual-slick-image-container">
                            <img src={images.Img25} />
                      </div>
                    
                     </Slider>
                   </div>
              </div>
      
      </div>

    );

let mapDom = (
    <div className="map-dom-container">
            <h4 className="map-dom-header">
              <FormattedMessage
                  id={"southasia_largest_hotel_label"}
                  defaultMessage={"OYO is South Asia’s largest hotel"}
                /> 
            </h4>
            <img className="map-dom-map-img" src={MapImage}/>
            <div className="map-city-details-container">
              <img className="map-dom-city-img" src={CityLogo}/>
              <p className="map-dom-cities">
                <b className="map-data-text">800+</b> {" "}

                <FormattedMessage
                    id={"total_cities_label"}
                    defaultMessage={"Cities"}
                  /> 
              </p>
            </div>
            <div className="map-verticle-line-1"></div>
            <div className="map-hotel-details-container">
                <img className="map-dom-hotel-img"src={HotelLogo}/>
                <p className="map-dom-hotels">
                  <b className="map-data-text">35000+</b> {" "}
                  <FormattedMessage
                      id={"exclusive_hotel_label"}
                      defaultMessage={"Exclusive Hotels"}
                    /> 
                </p>
            </div>
            <div className="map-verticle-line-2"></div>
            <div className="map-room-details-container">
                <img className="map-dom-room-img" src={RoomLogo}/>
                 <p className="map-dom-rooms">
                  <b className="map-data-text">1.2M+</b><br />
                  <FormattedMessage
                      id={"exclusive_rooms_label"}
                      defaultMessage={"Exclusive Rooms"}
                    /> 
                </p>
            </div>
    </div>
)




    let homePageDom = (
      <div style={{ marginTop: "3rem" }}>
        {firstDom}
        {secondDom}
        {thirdDom}
        {testimonialDom}
        {fifthDom}
        {sixthDom}
        {carouselDom}
        {/* {galaryDom} */}
        {mapDom}
        
      </div>
    );

    return (
      <div className="home-page-main-container">
        {authRedirect}
        {homePageDom}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userType: state.auth.userType,
    error: state.auth.error,
    loading: state.auth.loading
  };
};


Dialog.propTypes = {

  fullWidth: PropTypes.bool,
  
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
 
};


export default connect(mapStateToProps)(withStyles(styles)(HomePage)); 

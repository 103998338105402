import React from "react";
import { withStyles, Button } from "@material-ui/core";


const styles = _ => ({
  blue: {
    fontFamily: "Inter",
    backgroundColor: "rgba(34, 152, 218, 0.08)",
    color: "#2298DA",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    padding: "6px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "-0.1px",
    borderRadius: "8px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  yellow: {
    fontFamily: "Inter",
    backgroundColor: "rgba(223, 150, 65, 0.12)",
    color: "#DF9641",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    padding: "6px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "-0.1px",
    borderRadius: "8px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  grey: {
    fontFamily: "Inter",
    backgroundColor: "#F2F2F2",
    color: "#212121",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    padding: "6px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "-0.1px",
    borderRadius: "8px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  red: {
    fontFamily: "Inter",
    backgroundColor: "rgba(238, 42, 36, 0.12)",
    color: "#EE2A24",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    padding: "6px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "-0.1px",
    borderRadius: "8px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  green: {
    fontFamily: "Inter",
    backgroundColor: "rgba(0, 178, 138, 0.12)",
    color: "#00B28A",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    padding: "6px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "-0.1px",
    borderRadius: "8px",
    paddingLeft: "10px",
    paddingRight: "10px"
  }
});

function StatusBar(props) {
  const { classes } = props;
  let cssClassName = "";
  let statusName = props.status;
  switch (props.status) {
    case "Requested":
      cssClassName = classes.blue;
      break;
    case "In Progress":
      statusName = "Pending with Photographer";
      cssClassName = classes.yellow;
      break;
    case "Rejected":
      cssClassName = classes.red;
      break;
    case "Completed":
      cssClassName = classes.green;
      break;
    case "Partial Submission Requested":
      cssClassName = classes.yellow;
      break;
    case "Partial Submission rejected":
      cssClassName = classes.red;
      break;
    case "Partially Completed":
      cssClassName = classes.green;
      break;
    case "Payment Done":
      cssClassName = classes.green;
      break;
    case "Payment Approved":
      cssClassName = classes.green;
      break;
    case "Cancelled":
      cssClassName = classes.grey;
      break;
    case "Editing In Progress":
      statusName = "Pending with Editor";
      cssClassName = classes.yellow;
      break;
    case "Soft Live Created":
      statusName = "Soft Live Created";
      cssClassName = classes.blue;
      break;
    case "Soft Live Requested":
      statusName = "Soft Live Requested";
      cssClassName = classes.yellow;
      break;
    case "Soft Live Completed":
      statusName = "Soft Live Completed";
      cssClassName = classes.green;
      break;
    default:
      cssClassName = classes.grey;
      break;
  }

  return <div className={cssClassName}>{statusName}</div>;
}

export default withStyles(styles)(StatusBar);

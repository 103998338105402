import React, { Component } from "react";
import axios from "../../Axios/OyoStudioWebServiceInstance";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";
import DataComponent from "../Photography/Jobs/AllJobs/JobsInfo/DataComponent";
import { FaTrash } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "react-bootstrap/Carousel";
import Swal from "sweetalert2";
import { FormattedMessage ,injectIntl } from 'react-intl';
const styles = theme => ({
  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "rgba(0,0,0, 0.5)",
    zIndex: 1,
    textAlign: "center",
    overflowX: "scroll",
    overflowY: "scroll"
  },
  formField: {
    width: "50%",
    backgroundColor: "white",
    border: "2px solid black",
    marginTop: "10%",
    padding: "25px",
    textAlign: "center",
    borderRadius: "10px",
    overflowX: "scroll",
    overflowY: "scroll",
    display: "inline-block"
  },
  cards: {
    margin: "2px",
    padding: "10px",
    color: "black",
    borderRadius: "10px",
    backgroundColor: "#A1C3D1",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle",
    fontWeight: "bold",
    textAlign: "left",
    overflow: "scroll"
  },
  uploadedImage: {
    maxWidth: "800px",
    maxHeight: "800px",
    width: "100%"
  },
  removeImage: {
    color: "red",
    cursor: "pointer"
  },
  acceptedImageStatus: {
    color: "black",
    backgroundColor: "lightGreen",
    textAlign: "center",
    borderRadius: "2px"
  },
  rejectedImageStatus: {
    color: "black",
    backgroundColor: "red",
    textAlign: "center",
    borderRadius: "2px"
  },
  noPhotographs: {
    fontSize: "10em",
    fontFamily: "Apple Color Emoji",
    display: "inline-block",
    textAlign: "center",
    overflow: "scroll"
  }
});

class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryImages: [],
      galleryLoader: true,
      uploaded: this.props.requirement_counts.uploaded,
      rejected: this.props.requirement_counts.rejected,
      accepted: this.props.requirement_counts.accepted
    };
    this.getGalleryImages = this.getGalleryImages.bind(this);
    this.openImagePage = this.openImagePage.bind(this);
    this.onGalleryImageDeletion = this.onGalleryImageDeletion.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  componentDidMount = () => {
    let requirement_id = this.props.requirement.requirement_id;
    this.getGalleryImages(requirement_id, false);
  };

  getGalleryImages = (requirement_id, hideLoader) => {
    if (!hideLoader) {
      this.setState({
        galleryLoader: true
      });
    }
    let url = "/vendors/requirement";
    let params = {
      email: localStorage.email,
      requirement_id: requirement_id
    };
    axios
      .get(url, { params: params })
      .then(response => {
        let images_data = response.data.data.images;

        let images = images_data.map(function(image_data) {
          return {
            id: image_data.id,
            url: image_data.url,
            image_status: image_data.status,
            rejection_reason: image_data.rejection_reason
          };
        });

        this.setState({
          galleryImages: images,
          galleryLoader: false
        });
      })
      .catch(() => {
        this.setState({
          galleryLoader: false,
          galleryImages: null
        });
      });
  };

  openImagePage = image_grid_url => {
    window.open(image_grid_url, "_blank");
  };

  onGalleryImageDeletion = image_id => {
    let oldGalleryImages = Object.assign([], this.state.galleryImages);
    if (image_id) {
      let newGalleryImages = oldGalleryImages.filter(obj => {
        return obj.id !== image_id;
      });
      this.setState({
        galleryImages: newGalleryImages
      });
    }
  };

  deleteImage(image_id, image_status) {
    const { intl } = this.props;
    Swal.fire({
      title:intl.formatMessage({
                      id: "are_you_sure_label",
                      defaultMessage:"Are you sure?"
                    }),
      text: intl.formatMessage({
                      id: "you_wont_be_able_to_revert_this_label",
                      defaultMessage:"You won't be able to revert this!"
                    }),
      type: "warning",
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
                          id: "cancel_buttoon_default_label",
                          defaultMessage:"Cancel"
                        }),
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({
                          id: "yes_delete_it_button_label",
                          defaultMessage:"Yes, delete it!"
                        }),
    }).then(result => {
      if (result.value) {
        let url = "/photoshoot_jobs/delete_image";
        let params = { image_id: image_id };
        axios
          .put(url, params)
          .then(response => {
            Swal.fire(intl.formatMessage({
                      id: "deleted_label",
                      defaultMessage:"Deleted!"
                    }), intl.formatMessage({
                      id: "your_file_has_been_deleted_label",
                      defaultMessage:"Your file has been deleted."
                    }), "success");
            this.onGalleryImageDeletion(image_id);
            if (image_status == "Accepted") {
              this.props.onUploadRequirementsCountChange("Accepted", -1);
              this.setState({
                uploaded: this.state.uploaded - 1,
                accepted: this.state.accepted - 1
              });
            } else if (image_status == "Not Accepted") {
              this.props.onUploadRequirementsCountChange("Not Accepted", -1);
              this.setState({
                uploaded: this.state.uploaded - 1,
                rejected: this.state.rejected - 1
              });
            }
            if (!response.data.data.requirement_satisfied) {
              this.props.onDeleteImageRequirementStatusChange(
                "Not Accepted",
                response.data.data.job_status.other_requirements,
                response.data.data.job_status.accepted_requirements
              );
            }
          })
          .catch(() => {});
      }
    });
  }

  render() {
    const { classes ,intl } = this.props;
    let imageCarousel = null;
    let imagePreview = null;
    if (this.state.galleryLoader) {
      imageCarousel =<CircularProgress size={70} />;
    } else {
      if (this.state.galleryImages && this.state.galleryImages.length) {
   
        imagePreview = this.state.galleryImages.map((image, index) => (
          <Carousel.Item key={`key-${index}`}>
            <img className={classes.uploadedImage} src={image.url} />
            <br />
            <Carousel.Caption>
              <div className="row">
                <div className="col-sm-6">
                  <span
                    className={
                      image.image_status === "Accepted"
                        ? "btn btn-success"
                        : "btn btn-danger"
                    }
                  >
                    {image.image_status === "Accepted" ? "Accepted" : "Rejected"}
                  </span>
                </div>
                <div className="col-sm-6">
                  {image.image_status === "Accepted" ?
                  <span
                    className="btn btn-danger"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.deleteImage(image.id, image.image_status);
                    }}
                  >
                    <FaTrash />
                  </span>
                  : <span
                          className="btn btn-danger"
                      >
                        {image.rejection_reason}
                  </span>}
                  <br />
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ));
        imageCarousel = <Carousel>{imagePreview}</Carousel>;
      } else {
        imageCarousel = (
          <>
            <span
              aria-label="No Images Uploaded"
              role="img"
              className={classes.noPhotographs}
            >
              🏠
            </span>
            <p>
                <FormattedMessage
                        id={"no_photograph_in_bucket_label"}
                        defaultMessage={"No Photographs in your bucket!"}
                        /></p>
            <p>
                <FormattedMessage
                        id={"how_about_uploading_photos_label"}
                        defaultMessage={"How about uploading some photographs?"}
                        /></p>
          </>
        );
      }
    }

    let headerDom = null;
    let requirementName = null;
    let statusHeader = null;
    let statusContent = null;
    let requirement = this.props.requirement;
    let image_grid_url =
      "/jobDetails" +
      "/imageGrid/" +
      this.props.job_id +
      "/" +
      requirement.requirement_id +
      "/" +
      requirement.name;
    requirementName = (
      <div>
        {requirement.name}
        <span style={{ color: "red", fontSize: "20px" }}>
          <b>{requirement.is_optional ? "" : " *"}</b>
        </span>
      </div>
    );
    if (this.state.uploaded) {
      statusHeader = `${intl.formatMessage({
                        id: "uploaded_label",
                        defaultMessage: "Uploaded"
                      })}: ${this.state.uploaded} | ${intl.formatMessage({
                        id: "requirement_label",
                        defaultMessage: "Requirement"
                      })}:  ${requirement.accepeted_min_count}`;
      statusContent = (
        <div>
          <span style={{ color: "green" }}>
            <b>{this.state.accepted} {" "}
              <FormattedMessage
                        id={"approved_label"}
                        defaultMessage={"approved"}
                        /> </b>
          </span>{" "}
          |{" "}
          <span style={{ color: "red" }}>
            <b>{this.state.rejected} {" "}
            <FormattedMessage
                        id={"rejected_label"}
                        defaultMessage={"rejected"}
                        /></b>
          </span>
        </div>
      );
    } else {
      statusHeader = `${intl.formatMessage({
                        id: "required_label",
                        defaultMessage: "Required"
                      })}`;
      statusContent = `${intl.formatMessage({
                        id: "minimum_label",
                        defaultMessage: "Minimum"
                      })}  ${requirement.accepeted_min_count} ${intl.formatMessage({
                        id: "photos_label",
                        defaultMessage: "photos"
                      })}`;
    }
    headerDom = (
      <div className={classes.cards}>
        <div className="row">
          <div className="col-sm-6">
            <DataComponent
              header={requirement.requirement_type}
              value={requirementName}
            />
          </div>
          <div className="col-sm-6">
            <DataComponent header={statusHeader} value={statusContent} />
          </div>
        </div>
      </div>
    );

    return (
      <div className={classes.root}>
        <div className={classes.formField}>
          <div className="row">
            <div className="col-sm-12">
              <h1>{headerDom}</h1>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">{imageCarousel}</div>
            <div className="col-sm-2"></div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-4">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.openImagePage(image_grid_url);
                }}
              >
                  <FormattedMessage
                        id={"view_all_images_label"}
                        defaultMessage={"View All Images"}
                        />
              </button>
            </div>
            <div className="col-sm-4">
              <button
                type="cancel"
                className="btn btn-danger"
                onClick={this.props.onClosePopUp}
              >
                 <FormattedMessage
                        id={"go_back_label"}
                        defaultMessage={"Go Back"}
                        /> 
              </button>
            </div>
            <div className="col-sm-2"></div>
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(ImageViewer));

let footerPageIds = {
    world_fastes_hotel_chain_label                              :   "Jaringan hotel yang paling cepat berkembang di dunia.",
    about_us_label                                              :   "Tentang kami",
    faqs_label                                                  :   "Tanya Jawab",
    contact_us_label                                            :   "Hubungi kami",
    oyo_official_blog_label                                     :   "Blog resmi OYO",
    download_oyo_app_label                                      :   "Unduh aplikasi OYO untuk penawaran menarik",
    learn_more_label                                            :   " Belajarlah lagi",
    feeling_lucky_label                                         :   "Merasa beruntung? Cobalah OYO acak.",
    find_oyo_label                                              :   " Temukan OYO",
    terms_of_use_label                                          :   "Syarat Penggunaan",
    partner_ship_agreement_label                                :   "Perjanjian Kemitraan",
    privacy_policy_label                                        :   "Kebijakan pribadi"


}


export default {
    ...footerPageIds
}
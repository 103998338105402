let jobGeneralIds = {
    header_list_of_job_label                    :"List of Jobs",
    city_type_label                             :"City",
    vendor_code_label                           :"Vendor Code",
    job_status_label                            :"Job Status"
}

let tabBarIds = { 
   active_task_label                            :"Active task",
   completed_task_label                         :"Completed",
   rejected_task_label                          :"Rejected/Cancelled",
   payment_done_task_label                      :"Payment Approved/Done"
}

let noDataAvailableInTableIds = {
    no_jobs_in_the_list_label                   :"No Jobs in your list",
    mail_at_label                               :"Mail at",
    if_you_are_facing_issue_continue_label      :"if you’re facing an issue"

}

let expansionTableIds = {
    job_id_Label                                :"Job Id",
    property_label                              :"Property",
    address_label                               :"Address",
    table_requested_by_label                    :"Requested By",
    shoot_date_label                            :"Shoot Date",
    status_label                                :"Status",



}


let jobInfoIds = {
    job_details_label                           :"Job Details",
    property_details_label                      :"Property Details",
    oyo_id_label                                :"OYO ID",
    oyo_type_lable                              :"Type",
    property_address_label                      :"Property Address",
    city_type_label                             :"City Type",
    requirements_label                          :"Requirements",
    category_level_label                        :"Category Level",
    property_level_label                        :"Property Level",
    view_lavel_lebel                            :"View Level"

}


let actionsButtonsId ={
    accept_button_label                                 :"Accept",
    reject_button_label                                 :"Reject",
    cancel_button_label                                 :"Cancel",
    submit_button_label                                 :"Submit",
    send_to_editor_button_label                         :"Send to Editor",
    send_back_to_photographer_button_label              :"Send back to Photographer",
    sync_requirements_button_label                      :"Sync Requirements",
    mark_as_completed_button_label                      :"Mark as Completed",
    approve_partial_completion_request_button_label     :"Approve Partial Completion Request",
    reject_partical_completion_button_label             :"Reject Partial Completion Request",
    reassign_job_button_label                           :"Reassign Job",
    approve_for_payment_button_label                    :"Approve For Payment",
    mark_payment_done_button_label                      :"Mark Payment Done",
    calculate_score_button_label                        :"Calculate Score",
    view_Score_button_label                             :"View Score",
    sync_to_crs_button_label                            :"Sync to CRS",
    upload_sync_to_crs_button_label                     :"Upload & Sync to CRS",
    view_details_button_label                           :"View Details"

}

export default {
    ...jobGeneralIds,
    ...tabBarIds,
    ...noDataAvailableInTableIds,
    ...expansionTableIds,
    ...jobInfoIds,
    ...actionsButtonsId
}
let loginIds = {
    login_to_your_account_label                 :"Login to your account",
    login_email_label                           :"Email",
    login_password_label                        :"Password",
    login_button_label                          :"Log in",
    reset_password_button_label                 :"Reset Password",
    old_password_label                          :"Old Password",
    new_password_label                          :"New Password",
    confirm_password_label                      :"Confirm Password",
    login_as_employee_label                     :"Login as employee",
    login_as_employee_label_with_azure          :"Login as employee with azure",
    donot_have_account_yet_labe                 :"Don’t have a account yet ?",
    
}


let signupIds = {
   join_to_the_network_label                    :"Join the Network",
   sign_up_for_oyo_frame_label                  :"Sign Up to OYO Frames",
   select_a_profile_label                       :"Select a profile",
   select_editor_label                          :"Editor",
   select_photographer_label                    :"Photographer",
   join_now_button_label                        :"JOIN NOW",
   access_benefit_of_our_label                  :"Access benefits of our",
   photography_partner_network_label            :"photography partner Network",
   access_to_parter_network_label               :"Access to OYO's partner network",
   group_learning_forums_label                  :"Group Learning Forums",
   training_workshops_courses_label             :"Training Workshops & Certification Courses",
   signup_enter_name_label                      :"Name",
   signup_enter_email_label                     :"Email" ,
   signup_enter_phone_number_label              : "Phone",
   signup_enter_link_for_photo_profile_label    :"Link for Photography Profile",
   email_cannot_be_empty_label                  :"E-Mail cannot be empty" ,
   invalid_email_address_label                  :"Invalid E-Mail Address",
   invalid_phone_number_label                   :"Invalid Phone Number",
   name_cannot_be_empty_label                   :"Name cannot be empty",
   i_have_read_and_understood_label             :"I'have read and understood our",
   terms_of_services_label                      :"Terms of Services",
   and_label                                    :"and",
   privacy_policy_label                         :"Privacy Policy",
   fill_the_checkbox_value                      :"Please select the Terms and Conditions"


}  

let resetPasswordIds = {
  empty_email_label                             :"Empty Email, Please Enter Email",
  invalid_email_label                           :"Invalid Email, Please enter a valid email",
  empty_password_label                          :"Empty Password, Please Enter Password",
  password_dose_not_match_constrain_label       :"Password should be minimum 8 digits include at least 1 Uppercase letter, 1 Lowercase letter, 1 Special Character and 1 Digit",
  new_password_does_not_match_label             :"New Password does not match with confirm password",
};

export default {
    ...loginIds,
    ...signupIds,
    ...resetPasswordIds
}
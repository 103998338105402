let firstDom = {
    welcome_to_oyo_frame_label                                  :   "Welcome to OYO Frames",
    be_a_part_and_continue_label                                :   "Be a part of world's largest photography network",
    first_dom_join_the_network_label                            :   "Join the network"
}

let secondDom ={
    second_dom_speech_label                                     :   "OYO is the fastest-growing and second-largest hotel \
                                                                    chain in the world. With OYO Frames, we welcome all \
                                                                    photographers and videographers to be an integral part \
                                                                    of OYO’s journey as we scale globally. It's still" + " Day 0"+ "at OYO",
    second_dom_name_heading                                     :   "RITESH AGARWAL,",
    second_dom_name_post                                        :   "OYO CEO"
}


let thirdDom ={
    photoshop_happened_label                                    :   "Photoshoots that happened in the last 30 days across the OYO network globally",
    image_clicked_label                                         :   "Images clicked per day in OYO",
    new_cities_added_label                                      :   "New cities added to OYO's global footprint this month"
}

let testimonialDom = {
    from_oyo_frame_community_label                              :   "From the OYO Frames Community",
    number_of_photograph_onboard_label                          :   "500+ Photographers are already onboarded globally on OYO Frames"

}

let fifthDomContent = {
    reach_an_audience_of_approximately_label                    :   "Reach an audience of approximately",
    million_label                                               :   "1.14 Mn",
    viewer_on_daily_basis                                       :   "viewers on a daily basis",
    grow_with_a_company_thats_growing                           :   "Grow with a company that's growing",
    annually_label                                              :   "annually",
    join_frame_and_gain_access_label                            :   "Join Frames & gain access to OYO's global footprint of",
    cities_label                                                :   "cities",
    work_alongside_photographer_label                           :   "Work alongside the best photographers across the globe"
}

let fifthDom ={
    the_power_of_being_an_label                                 :   "The power of being an ",
    oyo_framer_label                                            :   "\"OYO Framer\"",
    turning_photography_dreams_label                            :   "Turning Photography dreams into reality",

}

let sixthDom  ={
    we_are_looking_for_partners_label                           :   "We are looking for partners with",
    passion_for_photography_label                               :   "Passion for Photography",
    passion_will_always_and_continue_label                      :   "Passion will always make you push a little farther, and strive to be better than you were the day before",
    intuitive_imaginative_mind_label                            :   "Intuitive & Imaginative Mind",
    photography_is_afterall_and_continue_label                  :   "Photography is afterall an artform and the photographer, the artist",
    proven_experience_label                                     :   "Proven Experience",
    budget_hotel_make_and_continue_label                        :   "Budget Hotels make for tricky photography. Become a part of OYO Frames if you understand the nitty-gritties",
    eye_for_details_label                                       :   "Eye for Detail",
    even_the_smallest_and_continue_label                        :   "Even the smallest things can make or break a photograph",

}


let carouselDom = {
        our_gallery_label                                       :   "Our Gallery"
}

let mapDom = {
    southasia_largest_hotel_label                               :  "OYO is South Asia’s largest hotel",
    total_cities_label                                          :   "Cities",
    exclusive_hotel_label                                       :   "Exclusive Hotels",
    exclusive_rooms_label                                       :   "Exclusive Rooms"
}

export default {
    ...firstDom,
    ...secondDom,
    ...testimonialDom,
    ...thirdDom,
    ...fifthDom,
    ...fifthDomContent,
    ...sixthDom,
    ...carouselDom,
    ...mapDom
}

import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core";
// import ExpansionTable from '../../../../Custom/ExpansionTable';
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import RequirementHeader from "../RequirementHeader/RequirementHeader";
// import { monthNames} from '../../../../../Utils/DateUtils';
// import {OrderListContext} from '../../Context/Context'

const styles = theme => ({
  root: {
    margin: "10px",
    padding: "10px",
    borderWidth: "2px",
    borderRadius: "10px",
    backgroundColor: "white"
  },

  header: {
    display: "flex",
    columnCount: 7
  },
  summaryClassName: {
    "& div": {
      maxWidth: "100%"
    }
  },
  text: {
    height: "100%",
    overflowWrap: "break-word"
  },
  details: {
    display: "block"
  },
  table: {},
  property: {
    fontSize: "10em",
    fontFamily: "Apple Color Emoji",
    display: "inline-block",
    textAlign: "center"
  },
  propertyAssigned: {
    color: "rgba(0,0,0,0.54)"
  },
  supportEmail: {
    color: "rgba(0,0,0,0.87)",
    marginTop: "5em",
    padding: "2em"
  }
});

class RequirementsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job_id: this.props.job_id
    };
  }

  componentDidMount = () => {
  };

  componentDidUpdate = prevProps => {};

  onOrderModify = () => {
    setTimeout(() => {
      if (this.context.refresh) this.context.refresh();
    }, 1500);
  };

  render() {
    const { classes } = this.props;
    let requirementsDom = null;
    if (this.props.requirements.length > 0) {
      requirementsDom = (
        <>
          <div className={classes.root}>
            <RequirementHeader
              job_id={this.state.job_id}
              requirements={this.props.requirements}
              requirements_count={this.props.requirements_count}
              job_details={this.props.job_details}
              onUploadImagesJobComplete={this.props.onUploadImagesJobComplete}
              country={this.props.country}
              //   fetchVendors  = {this.fetchVendors}
            />
          </div>
          {/* TODO : Uncomment after handling from backend*/}
        </>
      );
    }

    return (
      // <OrderListContext.Provider value={{refresh: this.onOrderModify}}>
      <div>{requirementsDom}</div>

      // </OrderListContext.Provider>
    );
  }
}

export default withStyles(styles)(RequirementsList);

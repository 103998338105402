import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import DataComponent from "../../AllJobs/JobsInfo/DataComponent";

const styles = theme => ({
  cardsHeader: {
    margin: "10px",
    padding: "10px",
    color: "white",
    borderBottom: "solid",
    borderWidth: "2px",
    borderColor: "grey",
    borderRadius: "10px",
    backgroundColor: "black",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle"
  },
  spocDetails: {
    margin: "5px",
    padding: "10px",
    float: "center",
    verticalAlign: "middle",
    textAlign: "center",
    minHeight: "96px",
    border: "1px solid rgba(0,0,0,0.08)",
    backgroundColor: "white",
    borderRadius: "10px",
    overflow: "scroll"
  }
});

function SpocDetails(props) {
  const { classes } = props;

  return (
    <div>
      <div className={classes.cardsHeader}>
        <div className="row">
          <div className="col-sm-12">OYO SPOC DETAILS</div>
        </div>
      </div>
      <div className={classes.spocDetails}>
        <div className="row">
          <div className="col-sm-4">
            <DataComponent
              header="Name"
              value={props.oyoSpocData.oyo_spoc_name}
            />
          </div>
          <div className="col-sm-4">
            <DataComponent
              header="Phone"
              value={props.oyoSpocData.oyo_spoc_phone}
            />
          </div>
          <div className="col-sm-4">
            <DataComponent
              header="Email"
              value={props.oyoSpocData.oyo_spoc_email}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(SpocDetails);

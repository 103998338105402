import navbarIds from "./Appbar"
import homePageIds from "./StaticPage/HomePage"
import contactUsPageIds from "./StaticPage/ContactsUsPage"
import faqsPageIds  from "./StaticPage/FAQSPage"
import comingSoonPageIds from "./StaticPage/ComingSoonPage"
import footerPageIds from "./Footer/index"
import AuthIds from "./Auth/index"
import Jobs from "./Jobs/index"
import JobData from "./JobsData/index"
import SwalFireIds from "./SwalFireActions/index"
import MisReport from "./MisReport/index";
export default {
  ...navbarIds,
  ...homePageIds,
  ...contactUsPageIds,
  ...faqsPageIds,
  ...footerPageIds,
  ...AuthIds,
  ...Jobs,
  ...JobData,
  ...SwalFireIds,
  ...comingSoonPageIds,
  ...MisReport
}

import React from "react";
import Card from "@material-ui/core/Card";

export function Tag(props) {
  let text = "";
  let color = "";
  if (props.text === "partial_shoot") {
    text = "Partial Shoot";
    color = "#FF3C3C";
  } else if (props.text === "sent_back") {
    text = "Sent Back";
    color = "#0F06BF";
  }
  return (
    <div
      style={{
        align: "left",
        marginTop: "3px",
        fontWeight: "bold",
        marginBottom: "3px",
        width: "100px",
        fontSize: "12px",
        color: "white",
        borderRadius: "20px",
        boxShadow: "0 4px 8px 0",
        padding: "8px",
        textAlign: "center",
        backgroundColor: color
      }}
    >
      {text}
    </div>
  );
}

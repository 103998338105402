let loginIds = {
    login_to_your_account_label                 :"masuk ke akun Anda",
    login_email_label                           :"Surel",
    login_password_label                        :"Kata sandi",
    login_button_label                          :"Gabung",
    reset_password_button_label                 :"Setel Ulang Kata Sandi",
    old_password_label                          :"password lama",
    new_password_label                          :"kata sandi baru",
    confirm_password_label                      :"konfirmasi sandi",
    login_as_employee_label                     :"Masuk sebagai karyawan",
    login_as_employee_label_with_azure          :"Masuk sebagai karyawan dengan Azure",
    donot_have_account_yet_labe                 :"Belum punya akun ?",
    
}


let signupIds = {
   join_to_the_network_label                    :"Bergabunglah dengan Jaringan",
   sign_up_for_oyo_frame_label                  :"Daftar ke OYO Frames",
   select_a_profile_label                       :"Pilih profil",
   select_editor_label                          :"Editor",
   select_photographer_label                    :"Juru potret",
   join_now_button_label                        :"BERGABUNG SEKARANG",
   access_benefit_of_our_label                  :"Akses manfaat dari kami",
   photography_partner_network_label            :"mitra fotografi Network",
   access_to_parter_network_label               :"Akses ke jaringan mitra OYO",
   group_learning_forums_label                  :"Forum Pembelajaran Kelompok",
   training_workshops_courses_label             :"Lokakarya Pelatihan & Kursus Sertifikasi",
   signup_enter_name_label                      :"Nama",
   signup_enter_email_label                     :"Surel" ,
   signup_enter_phone_number_label              :"Telepon",
   signup_enter_link_for_photo_profile_label    :"Tautan untuk Profil Fotografi",
   email_cannot_be_empty_label                  :"E-Mail tidak boleh kosong" ,
   invalid_email_address_label                  :"Alamat email salah",
   invalid_phone_number_label                   :"Nomor Telepon Tidak Valid",
   name_cannot_be_empty_label                   :"Nama tidak boleh kosong",

   i_have_read_and_understood_label             :"Saya sudah membaca dan mengerti",
   terms_of_services_label                      :"Ketentuan Layanan",
   and_label                                    :"dan",
   privacy_policy_label                         :"Kebijakan pribadi",
   fill_the_checkbox_value                      :"Silakan pilih Syarat dan Ketentuan"

}

let resetPasswordIds = {
  empty_email_label                             :"Empty Email, Please Enter Email",
  invalid_email_label                           :"Invalid Email, Please enter a valid email",
  empty_password_label                          :"Empty Password, Please Enter Password",
  password_dose_not_match_constrain_label       :"Password should be minimum 8 digits include at least 1 Uppercase letter, 1 Lowercase letter, 1 Special Character and 1 Digit",
  new_password_does_not_match_label             :"New Password does not match with confirm password",
};

export default {
    ...loginIds,
    ...signupIds,
    ...resetPasswordIds
}
import React, { Component, Fragment } from "react";
import SpocDetails from "./SpocDetails";
import JobDetails from "./JobDetails";
import { withStyles ,Drawer,SwipeableDrawer,CircularProgress} from "@material-ui/core";
import CButton from "../../../../../Components/Custom/CButton";
import PropertyDetails from "./PropertyDetails";
import JobRequirements from "./JobRequirements";
import axios from "../../../../../Axios/OyoStudioWebServiceInstance";
import ViewDetails from "./ViewDetails";
import JobApprovalRejection from "./JobApprovalRejection";
import * as vectors from "../../../../../Resources/Vectors/index";
const styles = theme => ({
  root: {
    display: "block"
  },
  circularProgressContainer: {
    width: "700px"
  },
  progress: {
    marginLeft: "300px"
  },
  paper: {
    overflowY: "unset"
  },
  windowCloseButton: {
    width: "28px",
    height: "28px",
    position: "absolute",
    left: "-60px",
    top: "26px"
  },
 jobsDom: {
   overflow: "auto"
  }
});

class JobsInfo extends Component {
state = {
    rightDrawer:true,
    jobInfo:{},
    jobLoading:false
  };

componentDidMount = () => {
  var url =`/vendors/job_preview`;
  var  params = {
      job_id: this.props.jobId,
      email: localStorage.email
    };
   axios
      .get(url, { params: params })
      .then(response => {
        this.setState({
          jobInfo: response.data.data,
          jobLoading: true
        });
      })
      .catch(() => {
        this.setState({
          jobInfo: {},
          jobLoading: false
        });
      });
  
}
 toggleDrawer = (side, open) => {
   if(!open)
     this.setState({ jobLoading: false });
   this.setState({ rightDrawer: open });
   this.props.toggleDrawer(open);
  };

  render() {
    let { classes } = this.props;
     let jobsDOM = null;
    if (!this.state.jobLoading) {
      jobsDOM = (<div className={classes.circularProgressContainer}><CircularProgress className={classes.progress} size={70} /></div>)
    } else {
       jobsDOM = (  
              <div className={classes.jobsDom} >
              <JobDetails jobInfo={this.state.jobInfo} />  
              <PropertyDetails jobInfo={this.state.jobInfo}/>
              <JobRequirements jobInfo={this.state.jobInfo}/>
              <JobApprovalRejection jobInfo={this.state.jobInfo} DrawerClose={() => this.toggleDrawer('right', false)}/>
              <ViewDetails jobInfo={this.state.jobInfo}/>
              </div>
        );
    }
    return (
      <Fragment>
        <div className={classes.root}>
              <SwipeableDrawer
                classes={{ paper: classes.paper }}
                anchor="right"
                open={this.state.rightDrawer}
                onClose={() => this.toggleDrawer('right', false)}
                onOpen={() => this.toggleDrawer('right', true)}
              >
              <img className={classes.windowCloseButton} src={vectors.WindowCloseWhite} onClick={() => this.toggleDrawer('right', false)}/>
              {jobsDOM}
          </SwipeableDrawer>
         </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(JobsInfo);

import React from 'react';

import { withStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Header from "./Header/Header";
import Filter from "./Filter/Filter";
import DateRange from "./DateRange/DateRange";
import axios from "../../Axios/OyoStudioWebServiceInstance";
import moment from "moment";
import {showAlert} from "../../Utils/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  root: {
    marginLeft: "165px",
    marginRight: "165px",
    marginTop: "82px",
    marginBottom: "100px"
  },
  button: {
    margin: theme.spacing.unit
  },
  buttonBlock: {
    marginTop: "30px",
  }
});


class MisReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        countries: [],
        hubs: [],
        jobStatus: [],
        timeFrame: null
      },
      startDate: null,
      endDate: null,
      loading: false
    };
  }

  optionChangeHandler = options => {
    this.setState({
      filters: options
    })
  }

  dateChangeHandler = (value, name) => {
    let newState = {
      ...this.state,
      [name]: value
    };
    this.setState(newState);
  }

  getStartAndEndTimestamps = (type) => {
    let start, end;
    switch(type) {
      case ("Today") :
        start = moment().startOf("day");
        end = moment();
        break;
      case ("CurrentWeek") :
        start = moment().startOf("week");
        end = moment();
        break;
      case ("CurrentMonth") :
        start = moment().startOf("month");
        end = moment();
        break;
      case ("CurrentYear") :
        start = moment().startOf("year");
        end = moment();
        break;
      case ("Custom") :
        start = moment(this.state.startDate);
        end = moment(this.state.endDate).endOf("day");
        break;
      default :
        start = moment().startOf("day");
        end = moment().endOf("day");
    }
    start = Math.floor(start/1000);
    end = Math.floor(end/1000);
    return [start, end];
  };


  exportReport = () => {
    this.setState({loading: true});
    let {filters} = this.state;
    let params = {
      country_names: filters.countries && filters.countries.length ? filters.countries.join() : null,
      hub_names: filters.hubs && filters.hubs.length ? filters.hubs.join() : null,
      job_statuses: filters.jobStatus && filters.jobStatus.length ? filters.jobStatus.join() : null
    };
    let [startTime, endTime] = this.getStartAndEndTimestamps(filters.timeFrame);
    if(filters.timeFrame) {
      params["timestamp_from"] = startTime.valueOf();
      params["timestamp_to"] = endTime.valueOf();
    }
    let url = "mis/mis_report";
    axios.get(url, {
      params: params
    })
      .then(res => {
        if(res.data && res.data.data && res.data.data.report_csv_link) {
          this.setState({loading: false});
          this.downloadCsv(res.data.data.report_csv_link);
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({loading: false});
      });
  };

   downloadFile = (data, fileName="MIS_Report.csv", type="text/csv") => {
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = window.URL.createObjectURL(
        new Blob([data], { type })
      );
      a.setAttribute("download", fileName);
      a.click();
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
  };

  downloadCsv = (url) => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        this.downloadFile(blob);
        this.setState({loading: false});
      })
      .catch(err => {
        showAlert("", err.message);
        this.setState({loading: false});
      });
  };

  render() {
    let {classes} = this.props;
    let {startDate, endDate, loading} = this.state;
    let isCustomTime = this.state.filters.timeFrame === "Custom";
    let bodyDom = loading ?
      (<CircularProgress className={classes.progress} size={100} />) : (
      <>
        <Filter
          selectedOptions={this.state.filters}
          change={this.optionChangeHandler}
          isSoftLive={false}
        />
        {isCustomTime ? (
          <DateRange startDate={startDate} endDate={endDate} changeDate={this.dateChangeHandler}/>
        ) : null}
        <div className={classes.buttonBlock}>
          <Button  variant="contained" onClick={this.exportReport} color="primary" className={classes.button}> Export Report </Button>
        </div>
      </>
    );

    return (
      <div className={classes.root}>
        <Header />
        {bodyDom}
      </div>
    )
  }
}

export default withStyles(styles)(MisReport);
import { I18N } from '../actionTypes';
import { updateObject } from '../utility';

const initialState = {
  locale           : "id",
  countryName      : "INDONESIA"
}

const changeLocale = (state, action) => {
  return updateObject(state, {
    //TODO : Validate action.locale
    locale : action.locale
  });
}

export const I18NReducer = ( state = initialState, action ) => {
  switch ( action.type ) {
      case I18N.CHANGE_LOCALE             : return changeLocale(state, action);
      default:
          return state;
  }
};

import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import CButton from "../../../../../Components/Custom/CButton";
import axios from "../../../../../Axios/OyoStudioWebServiceInstance";
import Swal from "sweetalert2";
import {injectIntl} from 'react-intl';
import I18DSM from "./I18_DSM";

const MIN_SCORE_TO_PASS = 85;

const EDITOR_JOB_REJECTION_REASONS = {
  0: "Select a reason",
  1: "Shoot not done in Natural Light (Curtains Closed)",
  2: "Shoot done using Flash",
  3: "Less number of images",
  4: "Images not in 3 exposures",
  5: "MRC folders not made by photographer",
  6: "Images are blur",
  7: "Inappropriate images - Light blotting, Night facade, Bracketing, Dark images",
  8: "Wrong Property Branding/Runner Cushion Branding",
  9: "Issues with the raw drive link",
  10: "Mandatory folders missing",
  11: "Empty folders",
  12: "Other property raw link",
  13: "PAF/TR filled incorrectly",
  14: "Perspective issues/Wrong angles captured",
  15: "Others"
};

const VENDOR_JOB_CANCELLATION_REASONS = {
  1: "Property not ready for photoshoot",
  2: "Photoshoot re-scheduled by TR",
  3: "Room category information unavailable",
  4: "Incorrect categories",
  5: "Incorrect Amenities",
  6: "Incorrect Model Type/Property Type (SMART, OTH, Capital O, etc.)",
  7: "Property readiness updates not received from TR"
};

const QC_SEND_BACK_SOFT_LIVE_REASONS = {
  0: "Select a reason",
  1: "Mandate Carts- Washroom/Facade/Reception Missing",
  2: "MRC Images Missing in Cart",
  3: "MRC cart missing- Tech",
  4: "MRC Pictures Incorrect Tagged",
  5: "MRC Not Matching With Transformation",
  6: "Facade Name Mismatched with Property Name",
  7: "Duplicate Images",
  15: "Others"
}

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center"
  },
  buttons: {
    padding: "20px"
  },
  rejectionReasonText: {
    color: "rgba(0,0,0,0.38)",
    fontWeight: "bold",
    marginTop: "8px",
    marginBottom: "20px"
  }
});

class ActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job_completed: this.props.job_completed,
      dsmDrawerFlag:false,
      is_score_calculated:this.props.is_score_calculated,
      threshold_validation_message: this.props.threshold_validation_message,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      job_completed: props.job_completed,
      is_score_calculated: props.is_score_calculated,
      threshold_validation_message: props.threshold_validation_message,
    };
  }

  calculateScore = function(job_id) {
    if (!this.props.score_calculation_allowed) {
      Swal.fire({
        type: "info",
        title: "Property not ready",
        text:  "Score can only be calculated once Property's 'CRS Status' is either 'Active' or 'Live', Please contact the requester for the same.",
        allowOutsideClick: () => !Swal.isLoading()
      });
      return;
    }
    const { intl } = this.props;
    let url = "/photoshoot_jobs/job_score";
    let params = {
      job_id: job_id
    };
    axios
        .get(url, { params: params })
        .then(response => {
          let dialogue_type = null;
          dialogue_type =
              response.data.data >= MIN_SCORE_TO_PASS ? "success" : "warning";
          Swal.fire({
            position: "center",
            type: dialogue_type,
            // title: intl.formatMessage({
            //             id: "your_score_is_label",
            //             defaultMessage:"Your score is"
            //           }),
            html: response.data.data,
            showConfirmButton: true,
            confirmButtonText: intl.formatMessage({
              id: "confirm_button_default_label",
              defaultMessage:"OK"
            }),
            allowOutsideClick: () => !Swal.isLoading()
          }).then(result => {
            if (result.value) {
              // window.location.reload();
            }
          });
        })
        .catch(() => {
          this.setState({});
        });
  };


  reassignJob = params => {
    const { intl } = this.props;
    let entity = null;
    let entityArray = null;
    let reassignmentReason = null;
    let deadline = null;
    const { value: formValues } = Swal.fire({
      title: intl.formatMessage({
        id: "reassign_job_label",
        defaultMessage:"Reassign Job"
      }),
      html:
          `<br><label for="entity"><b>${intl.formatMessage({
            id: "reassign_to_label",
            defaultMessage:"Reassign to:"
          })} &nbsp</b></label>` +
          `<input type="radio" name="entity" value="Photographer"checked> ${intl.formatMessage({
            id: "photographer_label",
            defaultMessage:"photographer_label"
          })} &nbsp` +
          `<input type="radio" name="entity" value="Editor"> Editor<br>` +
          `<select id="deadline" class="swal2-input"><option value = 0> ${intl.formatMessage({
            id: "provide_deadline_label",
            defaultMessage:"Provide deadline"
          })}</option><option value = 3>3 ${intl.formatMessage({
            id: "days_with_capital_label",
            defaultMessage:"Days"
          })} </option><option value = 5>5 ${intl.formatMessage({
            id: "days_with_capital_label",
            defaultMessage:"Days"
          })}</option><option value = 7>7 ${intl.formatMessage({
            id: "days_with_capital_label",
            defaultMessage:"Days"
          })}</option></select><br>` +
          `<textarea id="reassignmentReason" class="swal2-input" placeholder="${intl.formatMessage({
            id: "reason_for_reassignment_label",
            defaultMessage:"Reason for reassignment"
          })}" />`,
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
        id: "cancel_buttoon_default_label",
        defaultMessage:"Cancel"
      }),
      confirmButtonText: intl.formatMessage({
        id: "reassign_job_label",
        defaultMessage:"Reassign Job"
      }),
      type: "question",
      preConfirm: () => {
        entityArray = document.getElementsByName("entity");
        for (let i = 0; i < entityArray.length; i++) {
          if (entityArray[i].checked) {
            entity = entityArray[i].value;
          }
        }
        reassignmentReason = document.getElementById("reassignmentReason")
            .value;
        reassignmentReason = reassignmentReason.trim();
        if (reassignmentReason == "") {
          Swal.showValidationMessage(intl.formatMessage({
            id: "alert_you_need_to_provide_reason",
            defaultMessage:"ALERT: You need to provide the reason!"
          }));
        } else {
          reassignmentReason = reassignmentReason + "=>" + entity;
        }
        deadline = document.getElementById("deadline").value;
        if (deadline == 0) {
          Swal.showValidationMessage(intl.formatMessage({
                id: "alert_you_need_to_provide_deadline",
                defaultMessage:"ALERT: You need to provide the deadline!"
              })
          );
        }
      }
    }).then(result => {
      if (result.value) {
        var params = {};
        let url = "/vendors/job";
        if (entity == "Photographer") {
          params = {
            job_id: this.props.job_id,
            status: "In Progress",
            reassignment_reason_by_admin: reassignmentReason,
            admin_expiration_days: deadline
          };
        } else if (entity == "Editor") {
          params = {
            job_id: this.props.job_id,
            status: "Editing In Progress",
            reassignment_reason_by_admin: reassignmentReason,
            admin_expiration_days: deadline
          };
        }
        axios
            .put(url, params)
            .then(response => {
              if (response.data.code === 200) {
                Swal.fire({
                  position: "center",
                  type: "success",
                  title: intl.formatMessage({
                    id: "job_reassigned_successfully_label",
                    defaultMessage:"Job reassigned successfully!"
                  }),
                  showConfirmButton: true,
                  allowOutsideClick: () => !Swal.isLoading()
                }).then(result => {
                  if (result.value) {
                    window.location.reload();
                  }
                });
              } else {
                Swal.fire({
                  type: "error",
                  title: "Oops...",
                  text: intl.formatMessage({
                    id: "something_went_wrong_alone_label",
                    defaultMessage:"Something went wrong!"
                  }),
                  allowOutsideClick: () => !Swal.isLoading()
                }).then(result => {
                  if (result.value) {
                    window.location.reload();
                  }
                });
              }
            })
            .catch(() => {
              this.setState({});
            });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  approveForPayment = () => {
    const { intl } = this.props;
    let params = {
      job_id: this.props.job_id,
      status: "Payment Approved"
    };
    Swal.fire({
      title: intl.formatMessage({
        id: "are_you_sure_label",
        defaultMessage:"Are you sure?"
      }),
      text: intl.formatMessage({
        id: "you_wont_be_able_to_revert_this_label",
        defaultMessage:"You won't be able to revert this!"
      }),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32CD32",
      cancelButtonText: intl.formatMessage({
        id: "no_abort_label",
        defaultMessage:"No, abort!"
      }),
      confirmButtonText: intl.formatMessage({
        id: "yes_approve_for_payment_label",
        defaultMessage:"Yes, approve for payment!"
      })
    }).then(result => {
      if (result.value) {
        let url = "/vendors/job";
        axios
            .put(url, params)
            .then(response => {
              window.location.reload();
            })
            .catch(() => {
              this.setState({});
            });
      }
    });
  };

  markPaymentDone = () => {
    const { intl } = this.props;
    let params = {
      job_id: this.props.job_id,
      status: "Payment Done"
    };
    Swal.fire({
      title: intl.formatMessage({
        id: "are_you_sure_label",
        defaultMessage:"Are you sure?"
      }),
      text: intl.formatMessage({
        id: "you_wont_be_able_to_revert_this_label",
        defaultMessage:"You won't be able to revert this!"
      }),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32CD32",
      cancelButtonText: intl.formatMessage({
        id: "no_abort_label",
        defaultMessage:"No, abort!"
      }),
      confirmButtonText: intl.formatMessage({
        id: "yes_mark_payment_done",
        defaultMessage:"Yes, mark payment done!"
      })
    }).then(result => {
      if (result.value) {
        let url = "/vendors/job";
        axios
            .put(url, params)
            .then(response => {
              window.location.reload();
            })
            .catch(() => {
              this.setState({});
            });
      }
    });
  };

  sendForSubmission = function(params) {
    const { intl } = this.props;
    let partial_submission_reasons = null;
    let etncCheck1 = null;
    let etncCheck2 = null;
    let otaDriveLink = null;

    if(this.state.is_score_calculated){
      if (this.state.job_completed) {
        Swal.mixin({
          confirmButtonText: intl.formatMessage({
            id: "next_with_capital_label",
            defaultMessage:"Next"
          })+" &rarr;",
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({
            id: "cancel_buttoon_default_label",
            defaultMessage:"Cancel"
          }),
          progressSteps: ["1", "2"]
        })
            .queue([
              {
                title: intl.formatMessage({
                  id: "congratulations_label",
                  defaultMessage:"Congratulations!"
                }),
                html:
                    `<p>${intl.formatMessage({
                      id: "all_requirements_have_been_passed_label",
                      defaultMessage:"All requirements have been passed."
                    })}<br/>${intl.formatMessage({
                      id: "kindly_proceed_to_submit_label",
                      defaultMessage:"Kindly proceed to submit!"
                    })}</p>` +
                    `<input id="otaDriveLink" class="swal2-input" placeholder= "${intl.formatMessage({
                      id: "ota_google_drive_link_label",
                      defaultMessage:"OTA Google Drive Link"
                    })}" />`,
                preConfirm: () => {
                  otaDriveLink = document.getElementById("otaDriveLink").value;
                  if (!otaDriveLink) {
                    Swal.showValidationMessage(intl.formatMessage({
                          id: "alert_you_need_to_provide_link_label",
                          defaultMessage:"ALERT: You need to provide the link!"
                        })
                    );
                  } else {
                    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
                    // return fetch(proxyurl + otaDriveLink)
                    //     .then(response => {
                    //       if (!response.ok) {
                    //         throw new Error(response.statusText);
                    //       }
                    //       return;
                    //     })
                    //     .catch(error => {
                    //       Swal.showValidationMessage(intl.formatMessage({
                    //         id: "alert_invalid_link_label",
                    //         defaultMessage:"ALERT: Invalid Link"
                    //       }));
                    //     });
                  }
                },
                type: "success",
                allowOutsideClick: () => !Swal.isLoading()
              },
              {
                title: intl.formatMessage({
                  id: "editor_declarations_label",
                  defaultMessage:"Editor Declaration"
                }),
                html:
                    `<input type="checkbox" id="etnc1" > ${intl.formatMessage({
                      id: "checkbox_minimum_image_count_label",
                      defaultMessage:"I have met the minimum image count in each category "
                    })}<br/>` +
                    `<input type="checkbox" id="etnc2" > ${intl.formatMessage({
                      id: "checkbox_raw_images_received_label",
                      defaultMessage:"Raw images received for this property has been edited and placed in correct MRC buckets "
                    })} <br/> ` +
                    `<input type = "checkbox" id="etnc3" > ${intl.formatMessage({
                      id: "checkbox_no_objectionable_content_label",
                      defaultMessage:"There is no objectionable content in the images uploaded "
                    })} <br/>` +
                    `<input type="checkbox" id="etnc4" > ${intl.formatMessage({
                      id: "checkbox_no_duplicate_image_label",
                      defaultMessage:"There are no duplicate images"
                    })} <br/>` +
                    `<input type="checkbox" id="etnc5" > ${intl.formatMessage({
                      id: "checkbox_images_uploaded_in_correct_dim_label",
                      defaultMessage:"The images uploaded are in correct dimension and resolution "
                    })}  <br/><br/>`,
                confirmButtonText: intl.formatMessage({
                  id: "confirm_submit_button_label",
                  defaultMessage:"Submit"
                }),
                preConfirm: () => {
                  etncCheck1 =
                      document.getElementById("etnc1").checked &&
                      document.getElementById("etnc2").checked &&
                      document.getElementById("etnc3").checked &&
                      document.getElementById("etnc4").checked &&
                      document.getElementById("etnc5").checked;
                  if (!etncCheck1) {
                    Swal.showValidationMessage(intl.formatMessage({
                          id: "alert_you_need_to_agree_to_terms_label",
                          defaultMessage:"ALERT: You need to agree to the terms!"
                        })
                    );
                  }
                }
              }
            ])
            .then(result => {
              if (result.value) {
                let url = "/vendors/job";
                params["status"] = "Completed";
                params["ota_drive_link"] = otaDriveLink;
                axios
                    .put(url, params)
                    .then(response => {
                      Swal.fire({
                        position: "center",
                        type: "success",
                        title: intl.formatMessage({
                          id: "photographs_submitted_successfully_label",
                          defaultMessage:"Photographs have been submitted successfully!"
                        }),
                        showConfirmButton: true,
                        allowOutsideClick: () => !Swal.isLoading()
                      }).then(result => {
                        if (result.value) {
                          window.location.reload();
                        }
                      });
                    })
                    .catch(() => {
                      this.setState({});
                    });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
              }
            });
        return;
      } else {
        const swalWithBootstrapButtons = Swal.mixin({
          confirmButtonText: intl.formatMessage({
            id: "next_with_capital_label",
            defaultMessage:"Next"
          })+" &rarr;",
          cancelButtonText: intl.formatMessage({
            id: "cancel_buttoon_default_label",
            defaultMessage:"Cancel"
          }),
          showCancelButton: true,
          progressSteps: ["1", "2"]
        })
            .queue([
              {
                title: intl.formatMessage({
                  id: "oops_requirement_not_passed_label",
                  defaultMessage:"Oops!! Requirements not passed!"
                }),
                html:
                    `
                    <p>${this.state.threshold_validation_message}</p>
                    <p>${intl.formatMessage({
                      id: "all_requirement_must_proceed_label",
                      defaultMessage:"All requirements must meet to proceed.<br/>Kindly submit after completing task!"
                    })}<br/>${intl.formatMessage({
                      id: "kindly_submit_after_completing_task_label",
                      defaultMessage:"Kindly submit after completing task!"
                    })}</p>` +
                    `<input id="otaDriveLink" class="swal2-input" placeholder="${intl.formatMessage({
                      id: "ota_google_drive_link_label",
                      defaultMessage:"OTA Google Drive Link"
                    })}"/>` +
                    `<textarea id="partialSubmissionReason" class="swal2-input" placeholder="${intl.formatMessage({
                      id: "reason_for_partial_submission_label",
                      defaultMessage:"Reason for partial submission"
                    })}"/>`,
                type: "warning",
                preConfirm: () => {
                  otaDriveLink = document.getElementById("otaDriveLink").value;
                  partial_submission_reasons = document.getElementById(
                      "partialSubmissionReason"
                  ).value;
                  partial_submission_reasons = partial_submission_reasons.trim();
                  if (!otaDriveLink) {
                    Swal.showValidationMessage(
                        intl.formatMessage({
                          id: "alert_you_need_to_provide_link_label",
                          defaultMessage:"ALERT: You need to provide the link!"
                        })
                    );
                  } else if (!partial_submission_reasons) {
                    Swal.showValidationMessage(
                        intl.formatMessage({
                          id: "alert_you_need_to_provide_reason",
                          defaultMessage:"ALERT: You need to provide the reason!"
                        })
                    );
                  } else {
                    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
                    // return fetch(proxyurl + otaDriveLink)
                    //     .then(response => {
                    //       console.log(response);
                    //       if (!response.ok) {
                    //         throw new Error(response.statusText);
                    //       }
                    //       return;
                    //     })
                    //     .catch(error => {
                    //       Swal.showValidationMessage(intl.formatMessage({
                    //         id: "alert_invalid_link_label",
                    //         defaultMessage:"ALERT: Invalid Link"
                    //       }));
                    //     });
                  }
                },
                allowOutsideClick: () => !Swal.isLoading()
              },
              {
                title: intl.formatMessage({
                  id: "editor_declarations_label",
                  defaultMessage:"Editor Declaration"
                }),
                html:
                    `<input type="checkbox" id="etnc6" > ${intl.formatMessage({
                      id: "checkbox_minimum_image_count_label",
                      defaultMessage:"I have met the minimum image count in each category "
                    })}<br/>` +
                    `<input type="checkbox" id="etnc7" > ${intl.formatMessage({
                      id: "checkbox_raw_images_received_label",
                      defaultMessage:"Raw images received for this property has been edited and placed in correct MRC buckets "
                    })} <br/> ` +
                    `<input type = "checkbox" id="etnc8" > ${intl.formatMessage({
                      id: "checkbox_no_objectionable_content_label",
                      defaultMessage:"There is no objectionable content in the images uploaded "
                    })} <br/>` +
                    `<input type="checkbox" id="etnc9" > ${intl.formatMessage({
                      id: "checkbox_no_duplicate_image_label",
                      defaultMessage:"There are no duplicate images"
                    })} <br/>` +
                    `<input type="checkbox" id="etnc10" > ${intl.formatMessage({
                      id: "checkbox_images_uploaded_in_correct_dim_label",
                      defaultMessage:"The images uploaded are in correct dimension and resolution "
                    })}  <br/><br/>`,
                confirmButtonText: intl.formatMessage({
                  id: "submit_for_partial_approval_button_label",
                  defaultMessage:"Submit for partial approval"
                }),
                preConfirm: () => {
                  etncCheck2 =
                      document.getElementById("etnc6").checked &&
                      document.getElementById("etnc7").checked &&
                      document.getElementById("etnc8").checked &&
                      document.getElementById("etnc9").checked &&
                      document.getElementById("etnc10").checked;
                  if (!etncCheck2) {
                    Swal.showValidationMessage(
                        intl.formatMessage({
                          id: "alert_you_need_to_agree_to_terms_label",
                          defaultMessage:"ALERT: You need to agree to the terms!"
                        })
                    );
                  }
                }
              }
            ])
            .then(result => {
              if (result.value) {
                let url = "/vendors/job";
                params["status"] = "Partial Submission Requested";
                params["ota_drive_link"] = otaDriveLink;
                params[
                    "partial_submission_reasons_by_editor"
                    ] = partial_submission_reasons;
                axios
                    .put(url, params)
                    .then(response => {
                      Swal.fire({
                        position: "center",
                        type: "success",
                        title: intl.formatMessage({
                          id: "photographs_submitted_successfully_label",
                          defaultMessage:"Photographs have been submitted successfully!"
                        }),
                        showConfirmButton: true,
                        allowOutsideClick: () => !Swal.isLoading()
                      }).then(result => {
                        if (result.value) {
                          window.location.reload();
                        }
                      });
                    })
                    .catch(() => {
                      this.setState({});
                    });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
              }
            });
        return;
      }
    }
    else{
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: intl.formatMessage({
          id: "score_not_calculated_hit_cal_score_first_label",
          defaultMessage:"Score not calculated. Please hit Calculate Score first."
        }),
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.value) {
          // window.location.reload();
        }
      });
    }
  };

  sendToEditor = function(params) {
    const { intl } = this.props;
    let ptncCheck = "";
    let link = "";
    let comments = "";
    let uniqueImagesCount = null;
    const { value: formValues } = Swal.mixin({
      confirmButtonText: intl.formatMessage({
        id: "next_with_capital_label",
        defaultMessage:"Next"
      })+" &rarr;",
      cancelButtonText: intl.formatMessage({
        id: "cancel_buttoon_default_label",
        defaultMessage:"Cancel"
      }),
      showCancelButton: true,
      progressSteps: ["1", "2"]
    })
        .queue([
          {
            title: intl.formatMessage({
              id: "send_for_editing_label",
              defaultMessage:"Send for Editing"
            }),
            html:
                `<input id="driveLink" class="swal2-input" placeholder="Google Drive Link" />` +
                `<label for="uniqueImagesCount">${intl.formatMessage({
                  id: "number_of_unique_images_label",
                  defaultMessage:"Number of Unique Images:"
                })}</label> <input type="number" id="uniqueImagesCount" class="swal2-input" />` +
                `<textarea id="linkComments" class="swal2-input" placeholder="${intl.formatMessage({
                  id: "additional_notes_label",
                  defaultMessage:"Additional notes"
                })}"/>`,
            preConfirm: () => {
              link = document.getElementById("driveLink").value;
              comments = document.getElementById("linkComments").value;
              comments = comments.trim();
              uniqueImagesCount = document.getElementById("uniqueImagesCount")
                  .value;
              if (!link) {
                Swal.showValidationMessage(
                    intl.formatMessage({
                      id: "alert_you_need_to_provide_link_label",
                      defaultMessage:"ALERT: You need to provide the link!"
                    })
                );
              } else if (uniqueImagesCount <= 0) {
                Swal.showValidationMessage(intl.formatMessage({
                  id: "invalid_image_count_label",
                  defaultMessage:"ALERT: Invalid Images count!"
                }));
              } else {
                // const proxyurl = "https://cors-anywhere.herokuapp.com/";
                // return fetch(proxyurl + link)
                //     .then(response => {
                //       console.log(response);
                //       if (!response.ok) {
                //         throw new Error(response.statusText);
                //       }
                //       return;
                //     })
                //     .catch(error => {
                //       Swal.showValidationMessage(intl.formatMessage({
                //         id: "alert_invalid_link_label",
                //         defaultMessage:"ALERT: Invalid Link"
                //       }));
                //     });
              }
            }
          },
          {
            title: intl.formatMessage({
              id: "photographer_declaration_label",
              defaultMessage:"Photographer Declaration"
            }),
            html:
                `<input type="checkbox" id="ptnc1" >  ${intl.formatMessage({
                  id: "checkbox_minimum_image_count_label",
                  defaultMessage:"I have met the minimum image count in each category "
                })} <br/>` +
                `<input type="checkbox" id="ptnc2" > ${intl.formatMessage({
                  id: "phtoshot_conducted_exposure_property_label",
                  defaultMessage:"The photoshoot was conducted in 3 exposure and only raw images of this property have been submitted in the link"
                })} <br/> ` +
                `<input type = "checkbox" id="ptnc3" > ${intl.formatMessage({
                  id: "checkbox_no_objectionable_content_label",
                  defaultMessage:"There is no objectionable content in the images uploaded "
                })} <br/>` +
                `<input type="checkbox" id="ptnc4" > ${intl.formatMessage({
                  id: "checkbox_no_duplicate_image_label",
                  defaultMessage:"There are no duplicate images"
                })} <br/><br/>`,
            confirmButtonText: intl.formatMessage({
              id: "send_to_editor_button_label",
              defaultMessage:"Send to Editor"
            }),
            preConfirm: () => {
              ptncCheck =
                  document.getElementById("ptnc1").checked &&
                  document.getElementById("ptnc2").checked &&
                  document.getElementById("ptnc3").checked &&
                  document.getElementById("ptnc4").checked;
              if (!ptncCheck) {
                Swal.showValidationMessage(
                    intl.formatMessage({
                      id: "alert_you_need_to_agree_to_terms_label",
                      defaultMessage:"ALERT: You need to agree to the terms!"
                    })
                );
              }
            }
          }
        ])
        .then(result => {
          if (result.value) {
            let url = "/vendors/job";
            params["drive_link"] = link;
            params["status"] = "Editing In Progress";
            if (comments) {
              params["comments_for_editor"] = comments;
            }
            params["unique_images_count"] = uniqueImagesCount;
            axios
                .put(url, params)
                .then(response => {
                  if (response.data.data.status == "Editing In Progress") {
                    Swal.fire({
                      position: "center",
                      type: "success",
                      title: intl.formatMessage({
                        id: "link_sent_to_editor_label",
                        defaultMessage:"Link sent to Editor!"
                      }),
                      showConfirmButton: true,
                      allowOutsideClick: () => !Swal.isLoading()
                    }).then(result => {
                      if (result.value) {
                        window.location.reload();
                      }
                    });
                  } else {
                    Swal.fire({
                      type: "error",
                      title: "Oops...",
                      text: intl.formatMessage({
                        id: "something_went_wrong_alone_label",
                        defaultMessage:"Something went wrong!"
                      }),
                      //footer: '<a href>Why do I have this issue?</a>'
                      allowOutsideClick: () => !Swal.isLoading()
                    }).then(result => {
                      if (result.value) {
                        window.location.reload();
                      }
                    });
                  }
                })
                .catch(() => {
                  this.setState({});
                });
          }
        });
  };

  sendToPhotographer = params => {
    const { intl } = this.props;
    let rejectionReason = null;
    let otherComment = null;

    const { value: formValues } = Swal.fire({
      title: intl.formatMessage({
        id: "rejection_reason_label",
        defaultMessage:"Rejection Reason"
      }),
      html:
          `<select id="rejectionReason" class="swal2-input" onChange="(value == 15) ? (document.getElementById('otherComment').removeAttribute('hidden')) : (document.getElementById('otherComment').setAttribute('hidden', true))"><option value=0>${intl.formatMessage({
            id: "select_a_reason_label",
            defaultMessage:"Select a reason"
          })}</option>
                    <option value=1>${intl.formatMessage({
            id: "shoot_not_done_in_natural_light_label",
            defaultMessage:"Shoot not done in Natural Light(Curtains Closed)"
          })}</option>
                    <option value=2>${intl.formatMessage({
            id: "shoot_done_using_flash_label",
            defaultMessage:"Shoot done using Flash"
          })}</option><
                    option value=3>${intl.formatMessage({
            id: "less_no_of_images_label",
            defaultMessage:"Less number of images"
          })}</option>
                    <option value=4>${intl.formatMessage({
            id: "image_not_in_exposures_label",
            defaultMessage:"Images not in 3 Exposures"
          })}</option>
                    <option value=5>${intl.formatMessage({
            id: "mrc_folder_not_made_by_photographer_label",
            defaultMessage:"MRC folders not made by photographer"
          })}</option>
                    <option value=7>${intl.formatMessage({
            id: "inappropriate_images_light_blottig_label",
            defaultMessage:"Inappropriate images - Light blotting, Night facade, Bracketing, Dark images"
          })}</option>
                    <option value=8>${intl.formatMessage({
            id: "wrong_property_branding_runner_label",
            defaultMessage:"Wrong Property Branding/Runner Cushion Branding"
          })}</option>
                    <option value=9>${intl.formatMessage({
            id: "issues_with_the_raw_drive_link_label",
            defaultMessage:"Issues with the raw Drive link"
          })}</option>
                    <option value=10>${intl.formatMessage({
            id: "mandatory_folders_missing_label",
            defaultMessage:"Mandatory folders missing"
          })}</option>
                    <option value=11>${intl.formatMessage({
            id: "empty_folders_label",
            defaultMessage:"Empty folders"
          })}</option>
                    <option value=12>${intl.formatMessage({
            id: "other_property_row_link_label",
            defaultMessage:"Other property raw link"
          })}</option>
                    <option value=13>${intl.formatMessage({
            id: "paf_tr_filled_incorrectly_label",
            defaultMessage:"PAF/TR filled incorrectly"
          })}</option>
                    <option value=14>${intl.formatMessage({
            id: "perspectice_issues_angles_captured_label",
            defaultMessage:"Perspective issues/Wrong angles captured"
          })}</option>
                    <option value=15>${intl.formatMessage({
            id: "others_label",
            defaultMessage:"Others"
          })}</option></select >` +
          `<input id="otherComment" class="swal2-input" placeholder="${intl.formatMessage({
            id: "please_specify_the_rejection_reasons_label",
            defaultMessage:"Please specify the rejection reason"
          })}" hidden/>`,
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
        id: "cancel_buttoon_default_label",
        defaultMessage:"Cancel"
      }),
      // confirmButtonText: "Cancel",
      confirmButtonText: intl.formatMessage({
        id: "send_back_to_photographer_button_label",
        defaultMessage:"Send Back To Photographer"
      }),
      confirmButtonColor: "#d33",
      type: "question",
      preConfirm: () => {
        rejectionReason = document.getElementById("rejectionReason").value;
        otherComment = document.getElementById("otherComment").value;
        if (rejectionReason == 0) {
          Swal.showValidationMessage(intl.formatMessage({
            id: "alert_you_need_to_provide_reason",
            defaultMessage:"ALERT: You need to provide the reason!"
          }));
        } else if (rejectionReason == 15) {
          if (!otherComment) {
            Swal.showValidationMessage(
                intl.formatMessage({
                  id: "alert_please_provide_rejection_reason",
                  defaultMessage:"ALERT: Please specify the rejection reason!"
                })
            );
          }
        }
      }
    }).then(result => {
      if (result.value) {
        let message = otherComment
            ? EDITOR_JOB_REJECTION_REASONS[rejectionReason] +
            " (" +
            otherComment +
            ")"
            : EDITOR_JOB_REJECTION_REASONS[rejectionReason];
        let url = "/vendors/job";
        let params = {
          job_id: this.props.job_id,
          status: "In Progress",
          photoshoot_job_rejection_by_editor: message
        };
        // console.log("params value is",params);
        axios
            .put(url, params)
            .then(response => {
              if (response.data.code === 200) {
                Swal.fire({
                  position: "center",
                  type: "success",
                  title:intl.formatMessage({
                    id: "send_back_to_photographer_button_label",
                    defaultMessage:"Send Back To Photographer"
                  })+"!",
                  showConfirmButton: true,
                  confirmButtonText: intl.formatMessage({
                    id: "confirm_button_default_label",
                    defaultMessage:"OK"
                  }),
                  allowOutsideClick: () => !Swal.isLoading()
                }).then(result => {
                  if (result.value) {
                    window.location.reload();
                  }
                });
              } else {
                Swal.fire({
                  type: "error",
                  title: "Oops...",
                  text: intl.formatMessage({
                    id: "something_went_wrong_alone_label",
                    defaultMessage:"Something went wrong!"
                  }),
                  allowOutsideClick: () => !Swal.isLoading()
                }).then(result => {
                  if (result.value) {
                    window.location.reload();
                  }
                });
              }
            })
            .catch(() => {
              this.setState({});
            });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });

  };

  adminRejectJob = () => {
    const { intl } = this.props;
    const PARTIAL_SUBMISSION_REJECTION_REASON = {
      "Images Property Score less than 85": `${intl.formatMessage({
        id: "image_property_score_less_then_85_label",
        defaultMessage:"Images Property Score less than 85"
      })}`,
      "Less Number of Images": `${intl.formatMessage({
        id: "less_then_no_of_images_label",
        defaultMessage:"Less Number of Images"
      })}`,
      "Images Property Score less than 85 and Less Number of Images":
          `${intl.formatMessage({
            id: "images_property_score_and_less_images_label",
            defaultMessage:"Images Property Score less than 85 and Less Number of Images"
          })}`
    };
    const { value: reason } = Swal.fire({
      title: intl.formatMessage({
        id: "rejection_reason_label",
        defaultMessage:"Rejection Reason"
      }),
      input: "select",
      inputOptions: PARTIAL_SUBMISSION_REJECTION_REASON,
      inputPlaceholder: intl.formatMessage({
        id: "select_a_reason_label",
        defaultMessage:"Select a reason"
      }),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
        id: "cancel_buttoon_default_label",
        defaultMessage:"Cancel"
      }),
      confirmButtonText: intl.formatMessage({
        id: "reject_button_label",
        defaultMessage:"Reject"
      }),
      confirmButtonColor: "#d33",
      type: "question",
      preConfirm: value => {
        console.log("reason var value",value);
        if (!value) {
          Swal.showValidationMessage(intl.formatMessage({
            id: "alert_you_need_to_provide_reason",
            defaultMessage:"ALERT: You need to provide the reason!"
          }));
        }
      }
    }).then(reason => {
      // console.log("reason value",reason.value);
      if (reason.value) {
        let url = "/vendors/job";
        let params = {
          job_id: this.props.job_id,
          status: "Partial Submission Rejected",
          photoshoot_job_rejection_by_admin: reason.value
        };
        // console.log("params value is ",params);
        axios
            .put(url, params)
            .then(response => {
              window.location.reload();
            })
            .catch(() => {
              this.setState({});
            });
      } else if (reason.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  adminAcceptJob = () => {
    const { intl } = this.props;
    const DEADLINE_ON_PARTIAL_APPROVAL = {
      10: `10 ${intl.formatMessage({
        id: "days_with_capital_label",
        defaultMessage:"Days"
      })}`,
      15: `15 ${intl.formatMessage({
        id: "days_with_capital_label",
        defaultMessage:"Days"
      })}`,
      30: `30 ${intl.formatMessage({
        id: "days_with_capital_label",
        defaultMessage:"Days"
      })}`
    };
    const { value: days } = Swal.fire({
      title: intl.formatMessage({
        id: "deadline_label",
        defaultMessage:"Deadline"
      }),
      input: "select",
      inputOptions: DEADLINE_ON_PARTIAL_APPROVAL,
      inputPlaceholder: intl.formatMessage({
        id: "days_to_complete_the_job_label",
        defaultMessage:"Days to complete the job"
      }),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
        id: "cancel_buttoon_default_label",
        defaultMessage:"Cancel"
      }),
      confirmButtonText: intl.formatMessage({
        id: "approve_button_label",
        defaultMessage:"Approve"
      }),
      confirmButtonColor: "#1AB64F",
      type: "question",
      preConfirm: value => {
        if (!value) {
          Swal.showValidationMessage(
              intl.formatMessage({
                id: "alert_you_need_to_provide_deadline",
                defaultMessage:"ALERT: You need to provide the deadline!"
              })
          );
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(days => {
      if (days.value) {
        let url = "/vendors/job";
        let params = {
          job_id: this.props.job_id,
          status: "Partially Completed",
          admin_expiration_days: days.value
        };
        axios
            .put(url, params)
            .then(response => {
              window.location.reload();
            })
            .catch(() => {
              this.setState({});
            });
      } else if (days.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  cancelJob = () => {
    const { intl } = this.props;
    let params = {
      job_id: this.props.job_id,
      status: "Cancelled",
      photoshoot_job_rejection_by_admin: "Forcefully Cancelled by Admin"
    };
    Swal.fire({
      title: intl.formatMessage({
        id: "are_you_sure_label",
        defaultMessage:"Are you sure?"
      }),
      text: intl.formatMessage({
        id: "you_wont_be_able_to_revert_this_label",
        defaultMessage:"You won't be able to revert this!"
      }),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonText: intl.formatMessage({
        id: "no_abort_label",
        defaultMessage:"No, abort!"
      }),
      confirmButtonText: intl.formatMessage({
        id: "yes_cancel_job_button_label",
        defaultMessage:"Yes, cancel job!"
      })
    }).then(result => {
      if (result.value) {
        let url = "/vendors/job";
        axios
            .put(url, params)
            .then(response => {
              window.location.reload();
            })
            .catch(() => {
              this.setState({});
            });
      }
    });
  };

  vendorCancelJob = () => {
    const { intl } = this.props;
    let cancellationReason = null;
    let driveLink = null;
    const { value: formValues } = Swal.fire({
      title: intl.formatMessage({
        id: "cancellation_reason_label",
        defaultMessage:"Cancellation Reason"
      }),
      html:
          `<select id="cancellationReason" class="swal2-input" onChange="(value == 1) ? (document.getElementById(${driveLink}).removeAttribute(${`hidden`})) : (document.getElementById(${driveLink}).setAttribute(${`hidden`}, true))" >
        <option value=0>${intl.formatMessage({
            id: "select_a_reason_label",
            defaultMessage:"Select a reason"
          })}</option>
        <option value=1>${intl.formatMessage({
            id: "property_not_ready_for_photoshot_label",
            defaultMessage:"Property not ready for photoshoot"
          })}</option>
        <option value=2>${intl.formatMessage({
            id: "photoshot_resheduled_by_tr_label",
            defaultMessage:"Photoshoot re-scheduled by TR"
          })}</option>
        <option value=3>${intl.formatMessage({
            id: "room_category_info_unavailable_label",
            defaultMessage:"Room category information unavailable"
          })}</option>
        <option value=4>${intl.formatMessage({
            id: "incorrect_categories_label",
            defaultMessage:"Incorrect categories"
          })}</option>
        <option value=5>${intl.formatMessage({
            id: "incorrect_amenities_label",
            defaultMessage:"Incorrect Amenities"
          })}</option>
        <option value=6>${intl.formatMessage({
            id: "incorrect_model_type_label",
            defaultMessage:"Incorrect Model Type/Property Type (SMART, OTH, Capital O, etc.)"
          })}</option>
        <option value=7>${intl.formatMessage({
            id: "property_readiness_updates_not_come_from_tr_label",
            defaultMessage:"Property readiness updates not received from TR"
          })}</option></select >` +
          `<input id="driveLink" class="swal2-input" placeholder="${intl.formatMessage({
            id: "google_drive_link_for_unready_properties_label",
            defaultMessage:"Google Drive Link for unready properties\ images"
          })}" hidden/>`,
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({
        id: "cancel_buttoon_default_label",
        defaultMessage:"Cancel"
      }),
      confirmButtonColor: "#d33",
      cancelButtonText: intl.formatMessage({
        id: "no_abort_label",
        defaultMessage:"No, abort!"
      }),
      type: "question",
      preConfirm: () => {
        cancellationReason = document.getElementById("cancellationReason")
            .value;
        driveLink = document.getElementById("driveLink").value;
        if (cancellationReason == 0) {
          Swal.showValidationMessage(intl.formatMessage({
            id: "alert_you_need_to_provide_reason",
            defaultMessage:"ALERT: You need to provide the reason!"
          }));
        } else if (cancellationReason == 1) {
          if (!driveLink) {
            Swal.showValidationMessage(
                intl.formatMessage({
                  id: "alert_provide_link_for_images_unready_properties_label",
                  defaultMessage:"ALERT: You need to provide the link for the images of unready properties!"
                })
            );
          }
          // const proxyurl = "https://cors-anywhere.herokuapp.com/";
          // return fetch(proxyurl + driveLink)
          //     .then(response => {
          //       console.log(response);
          //       if (!response.ok) {
          //         throw new Error(response.statusText);
          //       }
          //       return;
          //     })
          //     .catch(error => {
          //       Swal.showValidationMessage(intl.formatMessage({
          //         id: "alert_invalid_link_label",
          //         defaultMessage:"ALERT: Invalid Link"
          //       }));
          //     });
        }
      }
    }).then(result => {
      if (result.value) {
        let url = "/vendors/job";
        let params = {
          job_id: this.props.job_id,
          status: "Cancelled",
          photoshoot_job_cancellation_by_vendor:
              VENDOR_JOB_CANCELLATION_REASONS[cancellationReason] +
              "=>" +
              driveLink
        };
        axios
            .put(url, params)
            .then(response => {
              window.location.reload();
            })
            .catch(() => {
              this.setState({});
            });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  rejectJob = () => {
    const { intl } = this.props;
    const VENDOR_JOB_REJECTION_REASONS = {
      "Photographer not available on allocated shoot date":
          `${intl.formatMessage({
            id: "photographer_not_available_on_allocated_shoot_date_lable",
            defaultMessage:"Photographer not available on allocated shoot date"
          })}`,
      "Specific equipment not available on shoot date":
          `${intl.formatMessage({
            id: "specific_equipment_not_available_on_shoot_date_label",
            defaultMessage:"Specific equipment not available on shoot date"
          })}`,
      "Natural calamity i.e. Floods / Snow / Earthquake":
          `${intl.formatMessage({
            id: "natural_calamity_food_snow_earthquake_label",
            defaultMessage:"Natural calamity i.e. Floods / Snow / Earthquake"
          })}`,
      "Human Calamity i.e. Riots / Strike etc":
          `${intl.formatMessage({
            id: "human_calamity_riots_strike_label",
            defaultMessage:"Human Calamity i.e. Riots / Strike etc"
          })}`,
      "Multiple requests raised":`${intl.formatMessage({
        id: "multiple_requests_raised_label",
        defaultMessage:"Multiple requests raised"
      })}`,
      "Location too far": `${intl.formatMessage({
        id: "location_too_far_label",
        defaultMessage:"Location too far"
      })}`,
      "Shoot Rescheduled by BD": `${intl.formatMessage({
        id: "shoot_rescheduled_by_bd_label",
        defaultMessage:"Shoot Rescheduled by BD"
      })}`,
      "Property occupied or not vacant for shoots": `${intl.formatMessage({
        id: "property_occupied_or_not_vacant_for_shoots_label",
        defaultMessage:"Property occupied or not vacant for shoots"
      })}`,
      "OBF NOT REALISED": `${intl.formatMessage({
        id: "obf_not_realized_label",
        defaultMessage:"OBF NOT REALISED"
      })}`
    };
    const { value: reason } = Swal.fire({
      title: intl.formatMessage({
        id: "rejection_reason_label",
        defaultMessage:"Rejection Reason"
      }),
      input: "select",
      inputOptions: VENDOR_JOB_REJECTION_REASONS,
      inputPlaceholder: intl.formatMessage({
        id: "select_a_reason_label",
        defaultMessage:"Select a reason"
      }),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
        id: "cancel_buttoon_default_label",
        defaultMessage:"Cancel"
      }),
      confirmButtonText: intl.formatMessage({
        id: "reject_button_label",
        defaultMessage:"Reject"
      }),
      confirmButtonColor: "#d33",
      type: "question",
      preConfirm: value => {
        if (!value) {
          Swal.showValidationMessage(intl.formatMessage({
            id: "alert_you_need_to_provide_reason",
            defaultMessage:"ALERT: You need to provide the reason!"
          }));
        }
      }
    }).then(reason => {
      if (reason.value) {
        let url = "/vendors/job";
        let params = {
          job_id: this.props.job_id,
          status: "Rejected",
          photoshoot_job_rejection_by_admin: reason.value
        };
        axios
            .put(url, params)
            .then(response => {
              window.location.reload();
            })
            .catch(() => {
              this.setState({});
            });
      } else if (reason.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  acceptJob = () => {
    const { intl } = this.props;
    let photographerName = null;
    let photographerEmail = null;
    let photographerContact = null;
    const { value: formValues } = Swal.mixin({
      confirmButtonText: intl.formatMessage({
        id: "accept_button_label",
        defaultMessage:"Accept"
      }),
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
        id: "cancel_buttoon_default_label",
        defaultMessage:"Cancel"
      }),
    })
        .fire({
          title: intl.formatMessage({
            id: "accept_the_job_label",
            defaultMessage:"Accept the Job"
          }),
          type: "question",
          html:
              `<p>${intl.formatMessage({
                id: "accept_the_job_label",
                defaultMessage:"You need to provide the photographer's details first!"
              })}` +
              `<input id="photographerName" class="swal2-input" placeholder="${intl.formatMessage({
                id: "name_of_the_photographer_label",
                defaultMessage:"Name of Photographer"
              })}"/>` +
              `<input id="photographerEmail" class="swal2-input" placeholder="${intl.formatMessage({
                id: "email_of_the_photographer_label",
                defaultMessage:"Email of Photographer"
              })}"/>` +
              `<input id="photographerContact" class="swal2-input" placeholder="${intl.formatMessage({
                id: "contact_no_of_the_photographer_label",
                defaultMessage:"Contact No. of Photographer"
              })}"/>`,
          preConfirm: () => {
            photographerName = document
                .getElementById("photographerName")
                .value.trim();
            photographerEmail = document
                .getElementById("photographerEmail")
                .value.trim();
            photographerContact = document
                .getElementById("photographerContact")
                .value.trim();
            let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let contactFormat = /(^(\+?-? *[0-9]+)([,0-9 ]*)([0-9 ])*$)|(^ *$)/;
            // Supported format of contact number
            // XXX-XXX-XXXX
            // XXX.XXX.XXXX
            // XXX XXX XXXX
            if (!(photographerName && photographerEmail && photographerContact)) {
              Swal.showValidationMessage(
                  intl.formatMessage({
                    id: "alert_need_to_provide_details_label",
                    defaultMessage:"ALERT: You need to provide the details!"
                  })
              );
            } else if (!photographerEmail.match(mailFormat)) {
              Swal.showValidationMessage(
                  intl.formatMessage({
                    id: "alert_invalid_email_address_label",
                    defaultMessage:"ALERT: Invalid Email Address!"
                  }));
            } else if (!photographerContact.match(contactFormat)) {
              Swal.showValidationMessage(
                  intl.formatMessage({
                    id: "alert_invalid_contact_no_label",
                    defaultMessage:"ALERT: Invalid Contact Number!"
                  }));
            }
          }
        })
        .then(result => {
          if (result.value) {
            let url = "/vendors/job";
            let params = {
              job_id: this.props.job_id,
              status: "In Progress",
              photographer_name: photographerName,
              photographer_email: photographerEmail,
              photographer_contact: photographerContact
            };
            axios
                .put(url, params)
                .then(response => {
                  window.location.reload();
                })
                .catch(() => {
                  this.setState({});
                });
          }
        });
  };

  syncImagesToCRS = params => {
    const { intl } = this.props;
    let url = "/vendors/sync_to_crs";
    axios
        .post(url, params)
        .then(response => {
          Swal.fire({
            position: "center",
            type: "success",
            title: intl.formatMessage({
              id: "photographs_have_been_synced_to_crs_label",
              defaultMessage:"Photographs have been synced to CRS!"
            }),
            showConfirmButton: true,
            confirmButtonText: intl.formatMessage({
              id: "confirm_button_default_label",
              defaultMessage:"OK"
            }),
            allowOutsideClick: () => !Swal.isLoading()
          }).then(result => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch(() => {
          this.setState({});
        });
  };

  uploadAndSyncImagesToCRS = params => {
    const { intl } = this.props;
    var url = "/vendors/upload_and_sync_to_crs";
    var flag = null;
    if(this.state.is_score_calculated){
      params["flag"] = !this.state.job_completed;
      axios
          .post(url, params)
          .then(response => {
            Swal.fire({
              position: "center",
              type: "success",
              title: intl.formatMessage({
                id: "photographs_have_been_uploaded_and_synced_to_crs",
                defaultMessage:"Photographs have been uploaded & synced to CRS!"
              }),
              showConfirmButton: true,
              confirmButtonText: intl.formatMessage({
                id: "confirm_button_default_label",
                defaultMessage:"OK"
              }),
              allowOutsideClick: () => !Swal.isLoading()
            }).then(result => {
              if (result.value) {
                window.location.reload();
              }
            });
          })
          .catch(() => {
            this.setState({});
          });
    }
    else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: intl.formatMessage({
          id: "score_not_calculated_hit_cal_score_first_label",
          defaultMessage:"Score not calculated. Please hit Calculate Score first."
        }),
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.value) {
          // window.location.reload();
        }
      });
    }
  };

  updateRequirements = photoshoot_job_id => {
    let url = "/requirements/sync_requirements";
    let params = {
      photoshoot_job_id: photoshoot_job_id
    };

    axios
        .post(url, params)
        .then(response => {
          let message = response.data.data.message;
          let success = response.data.data.success;
          let is_updated = response.data.data.is_updated;
          if (success == true) {
            Swal.fire({
              position: "center",
              type: "success",
              title: message,
              allowOutsideClick: () => !Swal.isLoading()
            }).then(result => {
              if (is_updated) {
                window.location.reload();
              }
            });
          }
        })
        .catch((error) => {
          console.log("Something went wrong",error);
        });
  };

  markCompleted = job_id => {
    const { intl } = this.props;
    let url = "/photoshoot_jobs/complete_job";
    let params = {
      job_id: job_id
    };
    axios.post(url, params)
        .then(response => {
          let success = response.data.code === 200;
          // let is_updated = response.data.is_updated;
          if (success) {
            Swal.fire({
              position: "center",
              type: "success",
              title: response.data.data,
              allowOutsideClick: () => !Swal.isLoading()
            }).then(result => {
              if (success) {
                window.location.reload();
              }
            });
          }
          else {
            Swal.fire({
              position: "center",
              type: "error",
              title: intl.formatMessage({
                id: "something_went_wrong_alone_label",
                defaultMessage:"Something went wrong!"
              }),
              allowOutsideClick: () => !Swal.isLoading()
            }).then(result => {
            });
          }
        })
        .catch(() => {
          console.log("Something went wrong");
        });
  };

  acceptSoftLive = () => {
    const { intl } = this.props;
    if(this.state.is_score_calculated){
      if (this.state.job_completed) {
            let params = {
            job_id: this.props.job_id,
            status: "Soft Live Completed",
          };
    Swal.fire({
      title: intl.formatMessage({
        id: "how_was_the_shoot_label",
        defaultMessage:"How was the shoot?"
      }),
      type: "question",
      html: 'Please rate the images for this shoot Good/Bad based on guidelines. Also, mention the reason for the same.<br/><br/>' +
          '<div id="shoot_type">' +
          '<input type="radio" id="good_shoot" name="shoot_type" value="Good Shoot">&nbsp;&nbsp;' +
          '<label for="good_shoot">Good Shoot</label>&nbsp;&nbsp;' +
          '<input type="radio" id="bad_shoot" name="shoot_type" checked value="Bad Shoot">&nbsp;&nbsp;' +
          '<label for="bad_shoot"> Bad Shoot </label>&nbsp;&nbsp;' +
          '</div>'+
          '<input id="shoot_type_reason" label="reason" placeholder="Please mention the reason" class="swal2-input">',
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
        id: "no_abort_label",
        defaultMessage:"No, abort!"
      }),
      confirmButtonText: intl.formatMessage({
        id: "yes_approve_all_images",
        defaultMessage:"Yes, approve all Images!"
      }),
      preConfirm: () => {
        let is_good_shoot = document.getElementById('good_shoot').checked;
        let is_bad_shoot = document.getElementById('bad_shoot').checked;
        let reason = document.getElementById('shoot_type_reason').value;
        if(!((is_bad_shoot || is_good_shoot) && reason))
          Swal.showValidationMessage(intl.formatMessage({
            id: "alert_you_need_to_provide_reason",
            defaultMessage: "ALERT: You need to provide the reason!"
          }));
      }
    }).then(result => {
      if (result.value) {
        let is_good_shoot = document.getElementById('good_shoot').checked;
        let shoot_type_reason = document.getElementById('shoot_type_reason').value;
        let reason;
        reason = is_good_shoot ? document.getElementById('good_shoot').value : document.getElementById('bad_shoot').value;
        reason = reason + ": " + shoot_type_reason;
        let url = "/vendors/job";
        params["comment_by_qc"] = reason;
        axios.put(url, params)
            .then(response => {
              window.location.reload();
            })
            .catch(() => {
              this.setState({});
            });
      }
        });
      }
    }
    else{
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: intl.formatMessage({
          id: "score_not_calculated_hit_cal_score_first_label",
          defaultMessage:"Score not calculated. Please hit Calculate Score first."
        }),
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.value) {
          // window.location.reload();
        }
      });
    }

  };

  rejectSoftLive = params => {
    const {intl} = this.props;
    let rejectionReason = null;
    let otherComment = null;

    const {value: formValues} = Swal.fire({
      title: intl.formatMessage({
        id: "rejection_reason_label",
        defaultMessage: "Rejection Reason"
      }),
      html:
          `<select
                id="rejectionReason"
                class="swal2-input"
                onChange="(value == 15) ? (document.getElementById('otherComment').removeAttribute('hidden')) : (document.getElementById('otherComment').setAttribute('hidden', true))"
           >
                <option value=0>
                       ${intl.formatMessage({ id: "select_a_reason_label", defaultMessage:"Select a reason" })}
                </option>
                <option value=1>
                       ${intl.formatMessage({ id: "mandate_carts_washroom_facade_reception_missing_label", defaultMessage: "Mandate Carts- Washroom/Facade/Reception Missingn"})}
                </option>
                <option value=2>
                       ${intl.formatMessage({ id: "mrc_images_missing_in_cart_label", defaultMessage: "MRC Images Missing in Cart" })}
                </option>
                <option value=3>
                       ${intl.formatMessage({ id: "mrc_cart_missing_tech_label", defaultMessage: "MRC cart missing- Tech" })}
                </option>
                <option value=4>
                       ${intl.formatMessage({ id: "mrc_pictures_incorrect_tagged_label", defaultMessage: "MRC Pictures Incorrect Tagged" })}
                </option>
                <option value=5>
                       ${intl.formatMessage({ id: "mrc_not_matching_with_transformation_label", defaultMessage: "MRC Not Matching With Transformation" })}
                </option>
                <option value=6>
                       ${intl.formatMessage({ id: "facade_name_mismatched_with_property_name_label", defaultMessage: "Facade Name Mismatched with Property Name" })}
                </option>
                <option value=7>
                       ${intl.formatMessage({ id: "duplicate_images_label", defaultMessage: "Duplicate Images" })}
                </option>
                <option value=15>
                       ${intl.formatMessage({ id: "others_label", defaultMessage: "Others" })}
                </option>
            </select >` +
          `<input id="otherComment" class="swal2-input" placeholder="${intl.formatMessage({
            id: "please_specify_the_rejection_reasons_label",
            defaultMessage: "Please specify the rejection reason"
          })}" hidden/>`,
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
        id: "cancel_buttoon_default_label",
        defaultMessage: "Cancel"
      }),
      confirmButtonText: intl.formatMessage({
        id: "send_back_button_label",
        defaultMessage: "Send Back"
      }),
      confirmButtonColor: "#d33",
      type: "question",
      preConfirm: () => {
        rejectionReason = document.getElementById("rejectionReason").value;
        otherComment = document.getElementById("otherComment").value;
        if (rejectionReason == 0) {
          Swal.showValidationMessage(intl.formatMessage({
            id: "alert_you_need_to_provide_reason",
            defaultMessage: "ALERT: You need to provide the reason!"
          }));
        } else if (rejectionReason == 15) {
          if (!otherComment) {
            Swal.showValidationMessage(
                intl.formatMessage({
                  id: "alert_please_provide_rejection_reason",
                  defaultMessage: "ALERT: Please specify the rejection reason!"
                })
            );
          }
        }
      }
    }).then(result => {
      if (result.value) {
        let message = otherComment
            ? QC_SEND_BACK_SOFT_LIVE_REASONS[rejectionReason] +
            " (" +
            otherComment +
            ")"
            : QC_SEND_BACK_SOFT_LIVE_REASONS[rejectionReason];
        let url = "/vendors/job";
        let params = {
          job_id: this.props.job_id,
          status: "Soft Live Created",
          job_rejection_by_qc: message
        };
        axios
            .put(url, params)
            .then(response => {
              if (response.data.code === 200) {
                Swal.fire({
                  position: "center",
                  type: "success",
                  title: intl.formatMessage({
                    id: "send_back_button_label",
                    defaultMessage: "Send Back"
                  }) + "!",
                  showConfirmButton: true,
                  confirmButtonText: intl.formatMessage({
                    id: "confirm_button_default_label",
                    defaultMessage: "OK"
                  }),
                  allowOutsideClick: () => !Swal.isLoading()
                }).then(result => {
                  if (result.value) {
                    window.location.reload();
                  }
                });
              } else {
                Swal.fire({
                  type: "error",
                  title: "Oops...",
                  text: intl.formatMessage({
                    id: "something_went_wrong_alone_label",
                    defaultMessage: "Something went wrong!"
                  }),
                  allowOutsideClick: () => !Swal.isLoading()
                }).then(result => {
                  if (result.value) {
                    window.location.reload();
                  }
                });
              }
            })
            .catch(() => {
              this.setState({});
            });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });

  };


  handleDSMDrawer = () =>{

    this.setState({dsmDrawerFlag:true});
  }

  toggleDSMDrawer =(open) =>{
    this.setState({dsmDrawerFlag:open});
    this.props.DrawerClose();
  }

  toggleDSMDrawerOnly =(open) =>{
    this.setState({dsmDrawerFlag:open});
  }
  changeStatus = function(action_type) {
    if(action_type !== "View Score")
      this.props.DrawerClose();
    if (action_type) {
      let params = {
        job_id: this.props.job_id
      };

      if (action_type == "Calculate Score") {
        this.calculateScore(this.props.job_id);
        return;
      } else if (action_type == "View Score") {
        this.handleDSMDrawer();
        return;
      } else if (action_type == "Accept") {
        this.acceptJob();
        return;
      } else if (action_type == "Reject") {
        this.rejectJob();
        return;
      } else if (action_type == "Cancel") {
        if (this.props.user_type == "Vendor") {
          this.vendorCancelJob();
          return;
        } else {
          this.cancelJob();
          return;
        }
      } else if (action_type == "Reject Partial Completion Request") {
        this.adminRejectJob();
        return;
      } else if (action_type == "Approve Partial Completion Request") {
        this.adminAcceptJob();
        return;
      } else if (action_type == "Submit") {
        this.sendForSubmission(params);
        return;
      } else if (action_type == "Send to Editor") {
        this.sendToEditor(params);
        return;
      } else if (action_type == "Reassign Job") {
        this.reassignJob(params);
        return;
      } else if (action_type == "Approve For Payment") {
        this.approveForPayment();
        return;
      } else if (action_type == "Mark Payment Done") {
        this.markPaymentDone();
        return;
      } else if (action_type == "Send back to Photographer") {
        this.sendToPhotographer(params);
        return;
      } else if (action_type == "Sync to CRS") {
        this.syncImagesToCRS(params);
        return;
      } else if (action_type == "Upload & Sync to CRS") {
        this.uploadAndSyncImagesToCRS(params);
        return;
      } else if (action_type === "Sync Requirements") {
        this.updateRequirements(this.props.job_id);
      } else if (action_type === "Mark as Completed") {
        this.markCompleted(this.props.job_id);
      } else if (action_type === "Accept Soft Live") {
        this.acceptSoftLive(this.props.job_id);
      } else if (action_type === "Reject Soft Live") {
        this.rejectSoftLive(this.props.job_id);
      }
    }
  };

  render() {
    const { classes ,intl} = this.props;
    let actionDom = null;
    const { selectedRejectionReason } = this.state;
    let buttonActionMapping = {
      Accept: {
        id:"accept_button_label",
        status: "In Progress",
        text: "Accept",
        buttonType: 0
      },
      Reject: {
        id:"reject_button_label",
        status: "Rejected",
        text: "Reject",
        buttonType: 3
      },
      Cancel: {
        id:"cancel_button_label",
        status: "Cancelled",
        text: "Cancel",
        buttonType: 3
      },
      Submit: {
        id:"submit_button_label",
        status: "Rejected",
        text: "Submit",
        buttonType: 0
      },
      Send_To_Editor: {
        id:"send_to_editor_button_label",
        status: "Rejected",
        text: "Send to Editor",
        buttonType: 1
      },
      Send_Back_To_Photographer: {
        id:"send_back_to_photographer_button_label",
        status: "Editor_Reject",
        text: "Send back to Photographer",
        buttonType: 1
      },
      Sync_Requirements: {
        id:"sync_requirements_button_label",
        status: "no_change",
        text: "Sync Requirements",
        buttonType: 1
      },
      Mark_Completed: {
        id:"mark_as_completed_button_label",
        status: "no_change",
        text: "Mark as Completed",
        buttonType: 0
      },
      Accept_Partial_Submit: {
        id:"approve_partial_completion_request_button_label",
        status: "Partially Completed",
        text: "Approve Partial Completion Request",
        buttonType: 0
      },
      Reject_Partial_Submit: {
        id:"reject_partical_completion_button_label",
        status: "Partial Submission Rejected",
        text: "Reject Partial Completion Request",
        buttonType: 3
      },
      Reassign_Job: {
        id:"reassign_job_button_label",
        status: "Reassigning Job",
        text: "Reassign Job",
        buttonType: 1
      },
      Approve_For_Payment: {
        id:"approve_for_payment_button_label",
        status: "Payment Approved",
        text: "Approve For Payment",
        buttonType: 0
      },
      Mark_Payment_Done: {
        id:"mark_payment_done_button_label",
        status: "Payment Done",
        text: "Mark Payment Done",
        buttonType: 0
      },
      Calculate_Score: {
        id:"calculate_score_button_label",
        status: "no_change",
        text: "Calculate Score",
        buttonType: 1
      },
      Score_details: {
        id:"view_Score_button_label",
        status: "no_change",
        text: "View Score",
        buttonType: 1
      },
      Sync: {
        id:"sync_to_crs_button_label",
        status: "no_change",
        text: "Sync to CRS",
        buttonType: 1
      },
      Upload_Sync: {
        id:"upload_sync_to_crs_button_label",
        status: "no_change",
        text: "Upload & Sync to CRS",
        buttonType: 1
      },
      Accept_Soft_Live: {
        id:"accept_button_label",
        status: "no_change",
        text: "Accept Soft Live",
        buttonType: 0
      },
      Reject_Soft_Live: {
        id:"reject_button_label",
        status: "no_change",
        text: "Reject Soft Live",
        buttonType: 3
      }
    };

    if (this.props.allowed_actions && this.props.allowed_actions.length) {
      let allowed_actions = this.props.allowed_actions;
      let action_buttons = allowed_actions.map(function(allowed_action) {
        return buttonActionMapping[allowed_action];
      });
      actionDom = action_buttons.map((button, index) => {
        return (
            <div className={classes.buttons} key={index}>
              <CButton
                  displayName={button.id}
                  buttonType={button.buttonType}
                  onClick={() => this.changeStatus(button.text)}
              />
            </div>
        );
      });
    }

    return <div className={classes.root}>
      {actionDom}
      {this.state.dsmDrawerFlag ? <I18DSM toggleDSMDrawer={this.toggleDSMDrawer} job_id={this.props.job_id} toggleDSMDrawerOnly={this.toggleDSMDrawerOnly}/> : <div/>}
    </div>;
  }
}

export default injectIntl(withStyles(styles)(ActionButtons));

import React, { Component } from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import CMenu from "./CMenu";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

const styles = {
  subMenuItem: {
    display: "flex",
    justifyContent: "space-between",
    color: "white"
  }
};


class SubMenuItem extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.menuOpen = false;
  }

  setAnchorElement = node => {
    this.anchorElement = node;
  };


  handleItemClick = (event) => {
    if (!this.anchorElement) {
      this.setAnchorElement(event.currentTarget);
    }
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }


  handleSubMenuClose = () => {
    this.setState({
      menuOpen : false
    })
    this.menuOpen = false;
  }

  render() {
    const { caption, menuItems, classes } = this.props;
    return (
      <>
        <MenuItem
          onClick={this.handleItemClick}
          className={classNames(classes.subMenuItem, this.props.menuitemclassname)}
        >
          {caption}
          <ArrowLeftIcon />
        </MenuItem>
        <CMenu
          open={this.state.menuOpen}
          menuItems={menuItems}
          anchorElement={this.anchorElement}
          onClose={this.handleSubMenuClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          classes={classes}
          menuitemclassname={this.props.menuitemclassname}
        />
      </>
    );
  }
}

SubMenuItem.propTypes = {
  caption: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired
};

export default withStyles(styles)(SubMenuItem);

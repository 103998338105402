import React, { Component, Fragment } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import HomePage from "../../Components/StaticPages/HomePage";
import AboutUs from "../../Components/StaticPages/AboutUs";
import ContactUs from "../../Components/StaticPages/ContactUs";
import FAQs from "../../Components/StaticPages/FAQs";
import ResetPassword from "../Auth/ResetPassword/ResetPassword";
import ComingSoon from "../../Components/StaticPages/ComingSoon";

import imageGrid from "../Custom/ImagesGrid";
import JobData from "../Photography/Jobs/JobData/JobData";
import AllJobs from "../Photography/Jobs/AllJobs/AllJobs";
import MisReport from "../MisReport/MisReport";
import ReactGA from "react-ga";

class Root extends Component {
  componentDidUpdate =(prevProps) =>{
    if(prevProps.pathName !== this.props.pathName || (this.props.pathName !== window.location.pathname))
       this.props.locationPath(window.location.pathname);
  }
  googleAnalytics = () => {
    ReactGA.initialize("UA-57573155-5");
    ReactGA.pageview(window.location.pathname + window.location.search); // To Report Page View
  };
  render() {
   
    this.googleAnalytics();

    const publicRoutes = (
      <Switch>
        <Route path="/about" exact component={AboutUs} />
        <Route path="/contact" exact component={ContactUs} />
        <Route path="/comingsoon" exact component={ComingSoon} />
        <Route path="/faqs" exact component={FAQs} />
        <Route path="/" exact component={HomePage} />
        <Redirect to={this.props.redirectionPath}/>
      </Switch>
    )
  
    const afterLoginRoutes = (
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/about" exact component={AboutUs} />
        <Route path="/contact" exact component={ContactUs} />
        <Route path="/comingsoon" exact component={ComingSoon} />
        <Route path="/faqs" exact component={FAQs} />
        <Route path="/ForgotPassword" exact component={ResetPassword} />
        <Route path="/ResetPassword" exact component={ResetPassword} />
        <Route path="/jobDetails/imageGrid" component={imageGrid} />
        <Route path="/jobDetails/" component={JobData} />
        <Route path="/jobs/search" exact component={AllJobs} />
        <Route path="/misPayment" exact component={MisReport} />
        <Route render={() => <Redirect to={"/jobs/search"}/>} />
      </Switch>
    );
    let routes = publicRoutes;
    if(this.props.isAuthenticated) {
      routes = afterLoginRoutes;
    }
    return <Fragment>{routes}</Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated : state.auth.token !== null,
    redirectionPath : state.auth.authRedirectPath
  }
}

export default withRouter(connect(mapStateToProps, null)(Root));

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import AsyncSelect from "react-select/lib/Async";
import {
  withStyles,
  Typography,
  TextField,
  Paper,
  Chip,
  MenuItem
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import _ from "lodash";

const styles = theme => ({
  rootWithoutOptions: {
    margin: "8px",
    width: 250,
    height: 50,
    border: "1px solid rgba(0,0,0,0.08)",
    borderRadius: "8px",
    backgroundColor: "#FAFAFA"
  },
  rootWithOptions: {
    margin: "8px",
    width: 250,
    height: 50,
    border: "1px solid rgba(0,0,0,0.08)",
    borderRadius: "24px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px"
  },
  input: {
    display: "flex",
    padding: 0,
    paddingTop: 2
  },
  valueContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    paddingLeft: "10px",
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    fontFamily: "Inter",
    position: "absolute",
    left: 2,
    fontSize: 16,
    paddingLeft: "12px",
    paddingTop: "2px"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  inputField: {}
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        disableUnderline: true,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        },
        style: { marginTop: "8px" }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        backgroundColor: props.isSelected ? "#e0e0e0" : ""
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer} data-name="Hari">
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class CAutoComplete extends React.Component {
  valueToOptionSingle = value =>
    this.props.options
      ? this.props.options.find(
          option => option[this.props.valueKey] === value
        ) || null
      : null;

  valueToOptionMulti = values =>
    values ? values.map(value => this.valueToOptionSingle(value)) : null;

  optionToValueSingle = option => (option ? option[this.props.valueKey] : null);

  optionToValueMulti = options =>
    options ? options.map(option => this.optionToValueSingle(option)) : null;

  state = {
    selectedOption: this.props.isMulti
      ? this.valueToOptionMulti(this.props.defaultSelectedValue)
      : this.valueToOptionSingle(this.props.defaultSelectedValue)
  };

  isOptionSelected = (option, selectedValues) => {
    if (selectedValues.length === 0) {
      return false;
    } else if (selectedValues.length === 1) {
      return option.label === selectedValues[0].label;
    } else {
      return false;
    }
  };

  getOptionValue = option => option[this.props.valueKey];
  getOptionLabel = option => option[this.props.labelKey];

  onChange = selectedOption => {
    this.setState({ selectedOption });
    if (this.props.onChange) {
      this.props.onChange(
        this.props.isMulti
          ? this.optionToValueMulti(selectedOption)
          : this.optionToValueSingle(selectedOption)
      );
    }
  };

  componentDidUpdate = function(prevProps) {
    let currentSelectedValue = this.props.isMulti
      ? this.optionToValueMulti(this.state.selectedOption)
      : this.optionToValueSingle(this.state.selectedOption);
    if (
      (!(this.props.defaultSelectedValue === currentSelectedValue) &&
        !_.isEqual(this.props.defaultSelectedValue, currentSelectedValue) &&
        this.props.options &&
        this.props.options.length > 0) ||
      !_.isEqual(this.props.options, prevProps.options)
    ) {
      let newSelectedOption = this.props.isMulti
        ? this.valueToOptionMulti(this.props.defaultSelectedValue)
        : this.valueToOptionSingle(this.props.defaultSelectedValue);
      this.setState({
        selectedOption: newSelectedOption
      });
    }
  };

  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      })
    };

    let creatableSelect = (
      <CreatableSelect
        classes={classes}
        styles={selectStyles}
        textFieldProps={
          this.state.selectedOption &&
          (this.props.isMulti ? this.state.selectedOption.length > 0 : true)
            ? {
                label: this.props.placeholder,
                className: classes.inputField,
                InputLabelProps: {
                  shrink: true,
                  style: {
                    paddingLeft: "24px",
                    color: "rgba(0,0,0,0.54)"
                  }
                }
              }
            : {}
        }
        escapeClearsValue={true}
        backspaceRemovesValue={true}
        options={this.props.options || []}
        components={components}
        value={this.state.selectedOption}
        onChange={this.onChange.bind(this)}
        hideSelectedOptions={false}
        placeholder={this.props.placeholder}
        isMulti={this.props.isMulti}
        isClearable={true}
        getOptionValue={this.getOptionValue}
        getOptionLabel={this.props.getOptionLabel || this.getOptionLabel}
      />
    );

    let select = (
      <Select
        classes={classes}
        styles={selectStyles}
        textFieldProps={
          this.state.selectedOption &&
          (this.props.isMulti ? this.state.selectedOption.length > 0 : true)
            ? {
                label: this.props.placeholder,
                className: classes.inputField,
                InputLabelProps: {
                  shrink: true,
                  style: {
                    paddingLeft: "24px",
                    color: "rgba(0,0,0,0.54)"
                  }
                }
              }
            : {}
        }
        escapeClearsValue={true}
        backspaceRemovesValue={true}
        options={this.props.options || []}
        components={components}
        value={this.state.selectedOption}
        onChange={this.onChange.bind(this)}
        hideSelectedOptions={false}
        placeholder={this.props.placeholder}
        isMulti={this.props.isMulti}
        isClearable={true}
        getOptionValue={this.getOptionValue}
        getOptionLabel={this.props.getOptionLabel || this.getOptionLabel}
      />
    );

    let asyncSelect = (
      <AsyncSelect
        classes={classes}
        styles={selectStyles}
        textFieldProps={
          this.state.selectedOption &&
          (this.props.isMulti ? this.state.selectedOption.length > 0 : true)
            ? {
                label: this.props.placeholder,
                className: classes.inputField,
                InputLabelProps: {
                  shrink: true,
                  style: {
                    paddingLeft: "24px",
                    color: "rgba(0,0,0,0.54)"
                  }
                }
              }
            : {}
        }
        escapeClearsValue={true}
        backspaceRemovesValue={true}
        loadOptions={this.props.loadOptions}
        defaultOptions
        onInputChange={this.props.handleInputChange}
        components={components}
        value={this.state.selectedOption}
        onChange={this.onChange.bind(this)}
        hideSelectedOptions={false}
        placeholder={this.props.placeholder}
        isMulti={this.props.isMulti}
        isClearable={true}
        getOptionValue={this.getOptionValue}
        getOptionLabel={this.props.getOptionLabel || this.getOptionLabel}
      />
    );
    return (
      <div
        className={
          this.props.isFocused
            ? classes.rootWithOptions
            : classes.rootWithoutOptions
        }
        style={this.props.style}
      >
        {this.props.isAsync
          ? asyncSelect
          : this.props.allowCreate
          ? creatableSelect
          : select}
      </div>
    );
  }
}

CAutoComplete.propTypes = {
  placeholder: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  allowCreate: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  defaultSelectedValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  isAsync: PropTypes.bool
};

CAutoComplete.defaultProps = {
  isMulti: false,
  allowCreate: false,
  valueKey: "value",
  labelKey: "label",
  isAsync: false
};

export default withStyles(styles, { withTheme: true })(CAutoComplete);

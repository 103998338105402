import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { withStyles, Button, Typography } from "@material-ui/core";
import { InputLabel, LinearProgress } from "@material-ui/core";
import CTextField from "../../../Components/Custom/CTextField";
import CSnackbarContent from "../../../Components/Custom/CSnackbarContent";
import { connect } from "react-redux";
import * as actionCreators from "../../../Store/actions/index";
import logo from "../../../Resources/Images/oyo_frames_design_logo.png";
import LogInImage from "../../../Resources/Images/loginPage.png";
import { FormattedMessage } from "react-intl";
import * as vectors from "../../../Resources/Vectors/index";
import "./login.css";
const styles = theme => ({
  root: {
    backgroundColor: "white",
    margin: "7rem 5rem",
    padding: "0px",
    boxShadow: "10px 10px 5px grey"
  },
  logo: {
    maxWidth: "60%",
    maxHeight: "60%",
    display: "block",
    marginTop: "3rem",
    marginLeft: "auto",
    marginRight: "auto"
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    display: "block",
    minHeight: "500px"
  },
  loginText: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "32px"
  },
  oyoStudioText: {
    color: "rgba(0,0,0,0.38)",
    fontWeight: "bold",
    marginTop: "8px",
    marginBottom: "64px"
  },
  loginRegion: {
    margin: "auto"
  },
  loginButton: {
    width: "100%",
    maxWidth: "300px",
    backgroundColor: "#2F80ED"
  },
  colorPrimary: {
    backgroundColor: "red"
  },
  barColorPrimary: {
    backgroundColor: "black"
  }
});

class Login extends Component {
  state = {
    email: null,
    password: null,
    showPassword: false,
    errorMessage: null
  };

  handleClickShowPassword = () => {
    this.setState({
      ...this.state,
      showPassword: !this.state.showPassword
    });
  };

  isValid = () => {
    let errorMessage = null;
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let email = this.state.email || null;
    let password = this.state.password || null;
    if (email === null || email.trim() === "") {
      errorMessage = "Empty Email, Please Enter Email";
    } else if (!this.state.email.match(mailFormat)) {
      errorMessage = "Invalid Email, Please enter a valid email";
    } else if (password === null || password.trim() === "") {
      errorMessage = "Empty Password, Please Enter Password";
    }
    this.setState({
      ...this.state,
      errorMessage: errorMessage
    });
    return errorMessage === null;
  };

  loginClickHandler = () => {
    if (this.isValid()) {
      this.props.authorizeUser(this.state.email, this.state.password);
      this.props.handleClose();
    }
  };

  render() {
    const { classes } = this.props;
    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={"/jobs/search"} />;
    }

    let loginProgress = null;
    if (this.props.loading) {
      loginProgress = (
        <LinearProgress
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary
          }}
        />
      );
    }
    let logInDom = (
      <div className="login-main-container">
        <div className="login-left-container">
          <div
            className="login-left-close-window-container"
            onClick={() => this.props.handleClose()}
          >
            <img
              className="login-window-close-image"
              src={vectors.CloseWindowImage}
            />
          </div>
          <h1 className="header-container">
            <FormattedMessage
              id={"login_to_your_account_label"}
              defaultMessage={"Login to your account"}
            />
          </h1>
          <p style={{ textAlign: "center", color: "red", fontSize: "13px" }}>
            {this.state.errorMessage}
          </p>
          <div className="email-container">
            <CTextField
              id={"email"}
              autoFocus={true}
              disabled={this.props.loading}
              disableUnderline={true}
              placeholder={"signup_enter_email_label"}
              onChange={event => {
                this.setState({
                  ...this.state,
                  email: event.target.value,
                  errorMessage: null
                });
                this.props.error && this.props.clearErrorMessage();
              }}
            />
          </div>
          <div className="password-container">
            <CTextField
              id={"password"}
              type={"password"}
              disabled={this.props.loading}
              placeholder={"login_password_label"}
              onChange={event => {
                this.setState({
                  ...this.state,
                  password: event.target.value,
                  errorMessage: null
                });
                this.props.error && this.props.clearErrorMessage();
              }}
              disableUnderline={true}
              showPassword={this.state.showPassword}
              handleClickShowPassword={this.handleClickShowPassword}
            />
          </div>
          <div className="login-button-container">
            <Button
              variant="outlined"
              color="primary"
              disabled={this.props.loading}
              onClick={this.loginClickHandler}
              type="submit"
            >
              <FormattedMessage
                id={"login_button_label"}
                defaultMessage={"Log in"}
              />
            </Button>
          </div>
          <br></br>
          <div className="employee-button-container">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                window.location.assign(process.env.REACT_APP_AZURE_SSO_LOGIN_URL);
              }}
              type="submit"
            >
              <FormattedMessage
                id={"login_as_employee_label_with_azure"}
                defaultMessage={"Login as employee with azure"}
              />
            </Button>
          </div>
          <br></br>
          <div className="login-horizontal-line-container"></div>
        </div>

        <div className="vertical-line-container"></div>

        <div className="login-right-container">
          <div
            className="login-close-window-container"
            onClick={() => this.props.handleClose()}
          >
            <img
              className="login-window-close-image"
              src={vectors.CloseWindowImage}
            />
          </div>
          <img className="login-image" src={LogInImage} />
        </div>
      </div>
    );

    return logInDom;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userType: state.auth.userType,
    error: state.auth.error,
    loading: state.auth.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authorizeUser: (email, password) =>
      dispatch(actionCreators.auth(email, password)),
    clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage())
  };
};

const LoginWithStyles = withStyles(styles)(Login);
export default connect(mapStateToProps, mapDispatchToProps)(LoginWithStyles);

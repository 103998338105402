import Swal from "sweetalert2";

export const showAlert = (status, displayMessgae, requestId) => {
    let message = "<p> <strong> Message :</strong> " + (displayMessgae || 'N/A') + "</p>";
    let request = requestId ?  "<p> <strong> Request Id : </strong>" + (requestId) + "</p>" : "";
    Swal.fire({
        title: `Error ${status}`,
        icon: "warning",
        html: message + request,
        showCloseButton: true
    })
}
import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core";
import ExpansionTable from "../../../../Custom/ExpansionTable";
import axios from "../../../../../Axios/OyoStudioWebServiceInstance";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "../../../../../Components/Custom/Pagination/Pagination";
import _ from "lodash";
import { FormattedMessage } from 'react-intl';
// import { monthNames} from '../../../../../Utils/DateUtils';
// import {OrderListContext} from '../../Context/Context'

const styles = theme => ({
  root: {},
  header: {
    display: "flex",
    columnCount: 7
  },
  summaryClassName: {
    "& div": {
      maxWidth: "100%"
    }
  },
  text: {
    height: "100%",
    overflowWrap: "break-word"
  },
  details: {
    display: "block"
  },
  table: {},
  property: {
    fontSize: "10em",
    fontFamily: "Apple Color Emoji",
    display: "inline-block",
    textAlign: "center"
  },
  propertyAssigned: {
    color: "rgba(0,0,0,0.54)"
  },
  supportEmail: {
    color: "rgba(0,0,0,0.87)",
    marginTop: "5em",
    padding: "2em"
  }
});

class JobsList extends Component {
  //   static contextType = OrderListContext;

  constructor(props) {
    super(props);
    this.headers = [
      {
        id: "job_id",
        label: "Job Id"
      },
      {
        id: "property_name",
        label: "Property"
      },
      {
        id: "address",
        label: "Address"
      },
      {
        id: "requested_by_id",
        label: "Requested By",
        
      },
      {
        id: "shoot_window",
        label: "Shoot Date"
      },
      {
        id: "job_status",
        label: "Status"
      }
    ];

    this.pageSize = 15;
    this.linksCount = 15;
    // this.pageSize = 20;
    // this.linksCount = 15;
    this.state = {
      jobs: [],
      jobsLoading: true,
      filters: props.filters,
      pagination: {
        //TODO : Correct this while integration
        totalPages: 1,
        currentPage: 1
      }
    };
  }

  componentDidMount = () => {
    this.getAllJobs();
  };

  componentDidUpdate = prevProps => {
    if((!_.isEqual(this.props.jobStatusValue, prevProps.jobStatusValue)) || (this.props.filters && !_.isEqual(this.props.filters, prevProps.filters)) || (!_.isEqual(prevProps.softLiveTag, this.props.softLiveTag)))
       this.getAllJobs();
  };

  onOrderModify = () => {
    //TODO: Find a better way of doing this
    setTimeout(() => {
      if (this.context.refresh) this.context.refresh();
      this.getAllJobs(false, true);
    }, 1500);
  };

  getAllJobs = (resetPage, hideLoader) => {
    
    if (!hideLoader) {
      this.setState({
        jobsLoading: true
      });
    }

 
    let allJobs = [];
    let offset = this.pageSize * (this.state.pagination.currentPage - 1);

    //TO DO . CHANGE EMAIL

    let url = "/vendors/jobs";
    let params = {
      email: localStorage.email,
      offset: offset,
      page_size: this.pageSize,
      soft_live_tag: this.props.softLiveTag
    }
    let paylod = {
      filters: this.props.filters
    };
    if(paylod.filters === null){
      paylod.filters= {jobStatusValue: this.props.jobStatusValue}
    }
    else{
      paylod.filters["jobStatusValue"]=this.props.jobStatusValue;
    }
    let self = this;
    axios
      .post(url, paylod, {params:params})
      .then(response => {
        let totalHits = response.data.data.totalHits;
        let filters = response.data.data.allowed_fields.filters;
        let user_type = response.data.data.user_type;
        self.props.setUserType(user_type);
        this.props.allowedFiltersUpdate(filters);
        response.data.data.jobs.forEach(row => {
          let eachJob = {};
          eachJob.property_name = row.property_data.property_name;
          eachJob.job_status = row.job_data.job_status;
          eachJob.job_id = row.job_data.job_id;
          eachJob.job_tag = row.job_data.job_tag;
          eachJob.job_created_at = row.job_data.job_created_at;
          eachJob.crs_id = row.property_data.crs_id;
          eachJob.requested_by_id = row.spoc_data ? row.spoc_data.email : null;
          eachJob.shoot_date = row.job_data.shoot_date;
          eachJob.shoot_window = row.job_data.shoot_window;
          eachJob.contact_number = row.spoc_data ? row.spoc_data.oyo_spoc_contact_number : null;

          eachJob.job_data = {
            expiration_time: row.job_data ? row.job_data.expiration_time : null
          };

          eachJob.location_data = {
            map_link: row.location_data ? row.location_data.map_link : null,
            street_address: row.location_data
              ? row.location_data.street_address
              : null,
            city_name: row.location_data ? row.location_data.city_name : null,
            state: row.location_data ? row.location_data.state : null,
            country: row.location_data ? row.location_data.country : null
          };

          eachJob.spoc_data = {
            name: row.spoc_data ? row.spoc_data.first_name + " " + row.spoc_data.last_name : null,
            email: row.spoc_data ? row.spoc_data.email : null,
            phone: row.spoc_data ? row.spoc_data.phone : null
          };
          allJobs.push(eachJob);
        });

        this.setState({
          jobs: allJobs,
          jobsLoading: false,
          filters: filters,
          pagination: {
            ...this.state.pagination,
            currentPage: resetPage ? 1 : this.state.pagination.currentPage,
            totalPages: Math.ceil(totalHits / this.pageSize)
          }
        });
      })
      .catch(() => {
        this.setState({
          jobs: [],
          jobsLoading: false,
          filters: null
        });
      });
  };

  fetchVendors = () => {};

  setPage = page => {
    let currentPage = this.state.pagination.currentPage;
    if (typeof page === "boolean") {
      if (page) {
        currentPage++;
      } else {
        currentPage--;
      }
    } else {
      currentPage = page;
    }
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          currentPage
        }
      },
      () => this.getAllJobs()
    );
  };

  render() {
    const { classes } = this.props;
    let jobsDOM = null;
    if (this.state.jobsLoading) {
      jobsDOM = <CircularProgress className={classes.progress} size={70} />;
    } else {
      if (this.state.jobs.length === 0) {
        jobsDOM = (
          <div className="row">
            <div className="col-sm-12">
              <span
                aria-label="No Jobs in your list"
                role="img"
                className={this.props.classes.property}
              >
                🏠
              </span>
              <p className={this.props.classes.propertyAssigned}>
               <FormattedMessage
                        id={"no_jobs_in_the_list_label"}
                        defaultMessage={"No Jobs in your list"}
                        /> 
              </p>
              <p className={this.props.classes.supportEmail}>
               <FormattedMessage
                        id={"mail_at_label"}
                        defaultMessage={"Mail at"}
                        />  <b>admin.frames@oyorooms.com</b>
              <FormattedMessage
              id={"if_you_are_facing_issue_continue_label"}
              defaultMessage={"if you’re facing an issue"}
              /> 
              </p>
            </div>
          </div>
        );
      } else {
        jobsDOM = (
          <div>
            <div className="row">
              <div className="col-sm-12">
                <ExpansionTable
                  headers={this.headers}
                  jobs={this.state.jobs}
                  filters={this.state.filters}
                  //   fetchVendors  = {this.fetchVendors}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <Pagination
                  data={this.state.pagination}
                  linksCount={this.linksCount}
                  onClick={this.setPage}
                />
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        );
      }
    }

    return (
      // <OrderListContext.Provider value={{refresh: this.onOrderModify}}>
      <Fragment>{jobsDOM}</Fragment>
      // </OrderListContext.Provider>
    );
  }
}

export default withStyles(styles)(JobsList);

import React, { Component } from "react";
import "./StaticPages.css";
import * as vectors from "../../Resources/Vectors/index";
import { FormattedMessage } from 'react-intl';
class ContactUs extends Component {
  render() {
    let content = {
      margin: "7rem 0rem"
    };

    let subjectLine = {
      textAlign: "left",
      fontWeight: "bold",
      color: "black"
    };

    let contactUsDom = (



       <div className="contactus-main-outer-container">
                      <div className="contactus-dom-container">
                  <div className="contactus-header-image-container">
                    <img className="desktop-contact-us-header-image"
                        src={vectors.staticBanner}
                      />
                    <img  className="mobile-contact-us-header-image"
                        src={vectors.staticBannerMobile}
                      />
                      <h3>
                          <FormattedMessage
                              id={"contact_us_name_label"}
                              defaultMessage={"Contact us"}
                          /> 
                      </h3>
                  </div>
                
                  <div className="contactus-content-container">
                    
                    <div className="contactus-left-content">

                      <p>
                        <FormattedMessage
                                id={"before_you_contact_us_and_continue_label"}
                                defaultMessage={"Before you contact us, we suggest you to check our FAQs section to see if your questions have been answered."}
                            /> 
                      </p>
                      <p>
                        <FormattedMessage
                                id={"in_case_any_query_label"}
                                defaultMessage={"In case you're stuck with a unique issue, feel free to reach out to us."}
                            /> 
                        <br />
                        <b>
                          <FormattedMessage
                                id={"contact_email_label"}
                                defaultMessage={"admin.frames@oyorooms.com"}
                            /> 
                          </b>
                      </p>  

                    </div>

                  </div>
                </div>
            </div>
    
    );

    return contactUsDom;
  }
}

export default ContactUs;

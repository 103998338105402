import React, { Component, Fragment } from "react";
import JobsInfo from "../Photography/Jobs/AllJobs/JobsInfo/JobsInfo";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  withStyles
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import StatusBar from "../../Components/Custom/StatusBar";
import { FormattedMessage, injectIntl } from "react-intl";
import { Tag } from "../../Components/Custom/Tag";

const tableColumnIdVsLanguageMapping = {
  job_id: { value: "job_id_Label" },
  property_name: { value: "property_label" },
  address: { value: "address_label" },
  requested_by_id: { value: "table_requested_by_label" },
  shoot_window: { value: "shoot_date_label" },
  job_status: { value: "status_label" }
};
const headerWidthObject = {
  job_id: {
    width: "8%"
  },
  property_name: {
    width: "14%"
  },
  address: {
    width: "22%"
  },
  requested_by_id: {
    width: "23%"
  },
  shoot_window: {
    width: "15%"
  },
  job_status: {
    width: "20%"
  }
};

const styles = theme => ({
  expansionDataSummary: {
    "& div": {
      maxWidth: "100%"
    },
    minHeight: "64px",
    textAlign: "left"
  },
  expansionHeaderSummary: {
    textAlign: "left"
  },
  text: {
    height: "100%",
    overflowWrap: "break-word"
  },
  details: {
    display: "block"
  },
  columnHeader: {
    fontFamily: "Inter",
    color: "rgba(0,0,0,0.54)",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "500",
    letterSpacing: "0.3px",
    textAlign: "left"
  },
  columnHeaderCenter: {
    fontFamily: "Inter",
    color: "rgba(0,0,0,0.54)",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "500",
    letterSpacing: "0.3px",
    textAlign: "center"
  },
  jobId: {
    fontFamily: "Inter",
    width: headerWidthObject["job_id"]["width"],
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550",
    textAlign: "left"
  },
  lightColorLabel: {
    fontFamily: "Inter",
    color: "#787878",
    fontSize: "14px"
  },
  propertyName: {
    fontFamily: "Inter",
    width: headerWidthObject["property_name"]["width"],
    textAlign: "left",
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  addressValue: {
    fontFamily: "Inter",
    width: headerWidthObject["address"]["width"],
    textAlign: "left",
    // display: "inline-block",
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  requestedById: {
    fontFamily: "Inter",
    width: headerWidthObject["requested_by_id"]["width"],
    textAlign: "center",
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  shootWindow: {
    fontFamily: "Inter",
    width: headerWidthObject["shoot_window"]["width"],
    textAlign: "center",
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  jobStatus: {
    fontFamily: "Inter",
    width: headerWidthObject["job_status"]["width"],
    textAlign: "center",
    fontSize: "16px",
    border: "5px transparent",
    fontWeight: "bold"
  },
  evenPanels: {
    minHeight: "64px"
  },
  oddPanels: {
    backgroundColor: "#FAFAFA",
    minHeight: "64px"
  },
  headerPanel: {
    fontFamily: "Inter",
    backgroundColor: "#FAFAFA",
    borderRadius: "8px 8px 0 0",
    "&::before": {
      display: "none"
    }
  }
});

class DataTable extends Component {
  state = {
    expandedIndex: null
  };

  changeExpandIndex = index => () => {
    this.setState({
      expandedIndex: index !== this.state.expandedIndex ? index : null
    });
  };

  componentWillReceiveProps = nextProps => {
    if (
      !_.isEqual(this.props.orders, nextProps.orders) &&
      this.state.expandedIndex != null
    ) {
      this.setState({
        expandedIndex: null
      });
    }
  };

  toggleDrawer = open => {
    if (!open) this.setState({ expandedIndex: null });
  };

  getRowValue = (headerInfo, jobInfo, jobIndex, headerIndex) => {
    const { classes, intl } = this.props;

    if (headerInfo.id === "address") {
      return (
        <Typography className={classes.addressValue} key={headerIndex}>
          <p className={classes.lightColorLabel}>
            {jobInfo.location_data.street_address}
          </p>
          <p>{`${
            jobInfo.location_data.state !== null
              ? jobInfo.location_data.state + ","
              : ""
          } ${
            jobInfo.location_data.city_name !== null
              ? jobInfo.location_data.city_name
              : ""
          }`}</p>
          <a href={jobInfo.location_data.map_link} target="_blank">
            Directions
          </a>
        </Typography>
      );
    } else if (headerInfo.id === "property_name") {
      return (
        <Typography className={classes.propertyName} key={headerIndex}>
          <p className={classes.lightColorLabel}>{jobInfo.crs_id}</p>
          <p>{jobInfo.property_name}</p>
        </Typography>
      );
    } else if (headerInfo.id === "requested_by_id") {
      return (
        <Typography className={classes.requestedById} key={headerIndex}>
          <p className={classes.lightColorLabel}>{jobInfo.requested_by_id}</p>
          <p>{jobInfo.spoc_data.name}</p>
        </Typography>
      );
    } else if (headerInfo.id === "shoot_window") {
      return (
        <Typography className={classes.shootWindow} key={headerIndex}>
          <p className={classes.lightColorLabel}>{jobInfo.shoot_window}</p>
          <p>{jobInfo.shoot_date}</p>
        </Typography>
      );
    } else if (headerInfo.id === "job_status") {
      return (
        <Typography className={classes.jobStatus} key={headerIndex}>
          <StatusBar status={jobInfo.job_status} />
        </Typography>
      );
    } else {
      let allTags =jobInfo["job_tag"];
      let tags = [];
      allTags.forEach(tag => {
        let text = tag;
        if(text === "sent_back_to_photographer" || text === "sent_back_to_editor") {
          text = "sent_back"
        }
        tags.push(text);
      });
      tags = [...new Set(tags)];
      let text = (
        <>
          {jobInfo[headerInfo.id]}
          <br />
          {tags.map(tag => <Tag text={tag}/>)}
        </>
      );
      return (
        <Typography className={classes.jobId} key={headerIndex}>
          {jobInfo[headerInfo.id] ? text : "-"}
        </Typography>
      );
    }
  };

  render() {
    const { classes } = this.props;
    const tableHeaders = this.props.headers;
    return (
      <Fragment>
        <ExpansionPanel className={classes.headerPanel}>
          <ExpansionPanelSummary className={classes.expansionHeaderSummary}>
            <div
              style={{
                columnCount: tableHeaders.length,
                width: "100%",
                display: "flex"
              }}
            >
              {tableHeaders.map((headerInfo, index) => {
                let className = index<3 ? classes.columnHeader : classes.columnHeaderCenter;
                return (
                  <Typography
                    className={className}
                    key={index}
                    style={{
                      width: `${headerWidthObject[headerInfo.id].width}`
                    }}
                  >
                    <FormattedMessage
                      id={tableColumnIdVsLanguageMapping[headerInfo.id].value}
                      defaultMessage={headerInfo.label}
                    />
                  </Typography>
                );
              })}
            </div>
          </ExpansionPanelSummary>
        </ExpansionPanel>
        {this.props.jobs.map((jobInfo, jobIndex) => {
          const className =
            jobIndex % 2 !== 0 ? classes.oddPanels : classes.evenPanels;
          return (
            <ExpansionPanel
              key={jobIndex}
              CollapseProps={{ unmountOnExit: true }}
              expanded={jobIndex === this.state.expandedIndex}
              className={className}
              onChange={this.changeExpandIndex(jobIndex)}
            >
              <ExpansionPanelSummary
                className={classes.expansionDataSummary}
              >
                <div
                  style={{
                    columnCount: tableHeaders.length,
                    width: "100%",
                    display: "flex"
                  }}
                >
                  {tableHeaders.map((headerInfo, index) => {
                    return this.getRowValue(
                      headerInfo,
                      jobInfo,
                      jobIndex,
                      index
                    );
                  })}
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
                <JobsInfo
                  jobId={jobInfo.job_id}
                  toggleDrawer={this.toggleDrawer}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(DataTable));

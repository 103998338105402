
let navBarDom = {
    navbar_login_label                                  :   "Log in",
    navbar_faqs_label                                   :   "FAQs",
    navbar_contact_us_label                             :   "Contact Us",
    navbar_blog_label                                   :   "Blog",
    navbar_about_us_label                               :   "About Us" ,
    appbar_join_now_button_label                        :   "Join now",
    language_label                                      :   "Language",
}

let userDom = {
    navbar_user_my_profile_label                        :   "My Profile",
    navbar_user_reset_password_label                    :   "Reset Password",
    navbar_user_logout_label                            :   "Sign Out",
    navbar_user_mis_report_label                        :   "MIS Reports"
}

let postLogInDom = {
    navbar_post_login_general_label                     :   "GENERAL",
    navbar_post_login_my_account_label                  :   "MY ACCOUNT",
    navbar_post_login_change_password_label             :   "Change Password",
    navbar_post_login_log_out_label                     :   "Log out"
}

let preLogInDom = {
    navbar_pre_login_join_link_label                    :   "Join",
    navbar_pre_login_home_link_label                    :   "Home",
}

let logoLabel = {
    world_leading_photography_parter_network_label      :   "World's leading photography partner network"
}

export default {
    ...navBarDom,
    ...userDom,
    ...postLogInDom,
    ...preLogInDom,
    ...logoLabel
}
let propertyDetailsIds = {
    property_details_header_label                                       :"RINCIAN PROPERTI",
    oyo_id_label                                                        :"ID OYO",
    crs_status_label                                                    :"Status CRS",
    property_address_label                                              :"Alamat Properti",
    property_type_label                                                 :"jenis properti",
    city_type_label                                                     :"Jenis Kota",

}

let jobDetailsIds = {
    job_details_header_label                                            :"RINCIAN PEKERJAAN",
    shoot_time_label                                                    :"Waktu Tembak",
    assigned_to_label                                                   :"Ditugaskan untuk",
    unique_images_label                                                 :"Gambar unik",
    google_drive_link_label                                             :"Google Drive Link",
    browser_images_label                                                :"Jelajahi Gambar",
    job_type_label                                                      :"Jenis pekerjaan"
}


let genericIds = {
    photographer_details_header_label                                   :"RINCIAN FOTOGRAFI",
    name_label                                                          :"Nama",
    email_label                                                         :"Surel",
    contact_number_label                                                :"Nomor kontak",
    requested_by_label                                                  :"DIMINTA OLEH",
    requested_on_label                                                  :"Diminta Aktif",
    oyo_spoc_details_label                                              :"RINCIAN OYO SPOC",
    admin_rejection_reasons_label                                       :"Alasan Penolakan Admin",
    admin_reassignment_reasons_label                                    :"Alasan Penugasan Kembali Admin",
    vendor_rejection_reasons_label                                      :"Alasan Penolakan Vendor",
    vendor_cancellation_reasons_label                                   :"Alasan Pembatalan Vendor",
    editor_rejection_reasons_label                                      :"Alasan Penolakan Editor",
    editinf_info_inst_for_editor_label                                  :"Mengedit Informasi / Instruksi untuk Editor",
    partial_submission_reasons_by_editor_label                          :"Alasan Pengiriman Sebagian oleh Editor",
    download_image_zip_label                                            :"Unduh Gambar Zip",
    uploaded_images_zip_download_link_label                             :"Gambar yang Diupload Zip Download Link",
    way_to_ota_label                                                    :"Jalan menuju OTA",
    ota_drive_link_label                                                :"OTA Drive Link" ,

    requirements_label                                                  :"PERSYARATAN",
    type_label                                                          :"Tipe",
    minimum_image_required_label                                        :"Diperlukan Gambar Minimum",
    property_level_requirement_label                                    :"Persyaratan Tingkat Properti",
    category_level_requirement_label                                    :"Persyaratan Tingkat Kategori",
    view_level_requirement_label                                        :"Lihat Persyaratan Level",
    room_level_requirement_label                                        :"Persyaratan Tingkat Kamar",
    no_requierment_in_your_list_label                                   :"Tidak Ada Persyaratan dalam daftar Anda",

    approved_label                                                      :"disetujui",
    rejected_label                                                      :"ditolak",
    total_images_required_label                                         :"Diperlukan Total Gambar",
    minimum_label                                                       :"Minimal",
    photos_label                                                        :"foto",

    no_photograph_in_bucket_label                                       :"Tidak ada foto di ember Anda!",
    how_about_uploading_photos_label                                    :"Bagaimana dengan mengunggah beberapa foto?",
    view_all_images_label                                               :"Lihat Semua Gambar",
    go_back_label                                                       :"Kembali",


    upload_image_button_label                                           :"Unggah Gambar",
    cancel_button_label                                                 :"Membatalkan",
    total_image_uploaded_label                                          :"Total Images Uploaded",
    total_requirement_label                                             :"Total Requirement",
    uploaded_label                                                      :"Uploaded",
    requirement_label                                                   :"Requirement",
    required_label                                                      :"Yg dibutuhkan",
    job_time_expired_label                                              :"Waktu Kerja telah kedaluwarsa. Pekerjaan telah dibatalkan secara otomatis!",
    days_label                                                          :"hari",
    hours_label                                                         :"jam",
    minutes_left_label                                                  :"menit lagi",


    


}
export default {
    ...propertyDetailsIds,
    ...jobDetailsIds,
    ...genericIds
}
import React, { Component, Fragment } from "react";
// import JobsInfo from '../Photography/Jobs/AllJobs/JobsInfo/JobsInfo'
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import Requirement from "../../../Photography/Jobs/JobData/Requirement/Requirement";
import ImageStudio from "../../../ImageStudio/ImageStudio";
import red from "@material-ui/core/colors/red";
import OneRequirement from "../OneRequirement/OneRequirement";
import ProgressBar from "react-bootstrap/ProgressBar";
import DataComponent from "../../../Photography/Jobs/AllJobs/JobsInfo/DataComponent";
import { FormattedMessage ,injectIntl } from 'react-intl';
const styles = theme => ({
  summary: {
    "& div": {
      maxWidth: "100%"
    },
    minHeight: "64px"
  },
  progressBar: {
    margin: "20px"
  },
  custom: {
    color: "black",
    fontWeight: "bold",
    fontSize: "12px",
    textAlign: "center"
  },
  cardsHeader: {
    margin: "10px",
    padding: "10px",
    color: "black",
    borderBottom: "solid",
    borderWidth: "2px",
    borderColor: "black",
    borderRadius: "10px",
    backgroundColor: "#8C8C8C",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle",
    textAlign: "left",
    overflow: "scroll"
  },
  text: {
    height: "100%",
    overflowWrap: "break-word"
  },
  details: {
    display: "block"
  },
  columnHeader: {
    color: "rgba(0,0,0,0.54)",
    fontSize: "14px"
  },
  columnValue: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  columnLightValue: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "13px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  columnLightValueRed: {
    color: "red",
    fontSize: "13px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550",
    paddingLeft: "20px"
  },
  columnLightValueGreen: {
    color: "green",
    fontSize: "13px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550",
    paddingLeft: "20px"
  },
  evenPanels: {
    minHeight: "64px"
  },
  oddPanels: {
    backgroundColor: "#FAFAFA",
    minHeight: "64px"
  },
  headerPanel: {
    backgroundColor: "#FAFAFA",
    borderRadius: "8px 8px 0 0",
    "&::before": {
      display: "none"
    }
  },
  legend: {
    display: "flex",
    marginTop: "20px"
  },
  imageStatusText: {
    marginLeft: "20px"
  }
});

class RequirementHeader extends Component {
  state = {
    requirements_left: this.props.job_details.other_requirements,
    requirements_completed: this.props.job_details.accepted_requirements,
    total_required_images: this.props.requirements_count.total_required_images,
    total_uploaded_images: this.props.requirements_count.total_uploaded_images,
    total_accepted_images: this.props.requirements_count.total_accepted_images,
    total_rejected_images: this.props.requirements_count.total_rejected_images
  };

  onUploadTotalRequirementsCountChange = (status, value) => {
    let upCount = this.state.total_uploaded_images;
    let accCount = this.state.total_accepted_images;
    let rejCount = this.state.total_rejected_images;
    if (status == "Accepted") {
      this.setState({
        total_uploaded_images: upCount + value,
        total_accepted_images: accCount + value
      });
    } else if (status == "Not Accepted") {
      this.setState({
        total_uploaded_images: upCount + value,
        total_rejected_images: rejCount + value
      });
    }
  };

  onUploadImagesRequirementChange = (
    requirements_left,
    requirements_completed
  ) => {
    if (requirements_left != this.state.requirements_left) {
      let old_requirements_left = this.state.requirements_left;
      this.setState({
        requirements_left: requirements_left,
        requirements_completed: requirements_completed
      });

      if (requirements_left == 0) {
        this.props.onUploadImagesJobComplete(true);
      } else if (old_requirements_left == 0) {
        this.props.onUploadImagesJobComplete(false); // old requirements left was 0 but changed due to deleting any good image
      }
    }
  };

  render() {
    const { classes , intl} = this.props;
    let progressBarDom = null;
    let headerDom = null;
    let statusDom = null;
    const status = Math.min(100, Math.floor(
      (this.state.total_accepted_images * 100) /
        (this.state.total_required_images)
    ));

    statusDom = <div className={classes.custom}>{status}%</div>;
    // const tableHeaders = this.props.headers;
    progressBarDom = (
      <>
        <div className={classes.progressBar}>
          <ProgressBar solid variant="success" now={status} label={statusDom} />
        </div>
      </>
    );
    headerDom = (
      <>
        <div className={classes.cardsHeader}>
          <div className="row">
            <div
              className="col-sm-4"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              <b>
                <FormattedMessage
                        id={"name_label"}
                        defaultMessage={"Name"}
                        /></b>
            </div>
            <div className="col-sm-4">
              <b>Status</b>
            </div>
          </div>
        </div>
      </>
    );

    let statusHeader = null;
    let statusContent = null;
    let requirementsCountDom = null;
    let requirementsCount = this.props.requirements_count;
    if (this.state.total_uploaded_images) {
      statusHeader = `${intl.formatMessage({
                        id: "total_image_uploaded_label",
                        defaultMessage: "Total Images Uploaded"
                      })}: ${this.state.total_uploaded_images} |  ${intl.formatMessage({
                        id: "total_requirement_label",
                        defaultMessage: "Total Requirement"
                      })}: ${requirementsCount.total_required_images}`;
      statusContent = (
        <div>
          <span style={{ color: "green" }}>
            <b>{this.state.total_accepted_images}{" "}
            <FormattedMessage
                        id={"approved_label"}
                        defaultMessage={"approved"}
                        /> </b>
          </span>{" "}
          |{" "}
          <span style={{ color: "red" }}>
            <b>{this.state.total_rejected_images}{" "}
            <FormattedMessage
                        id={"rejected_label"}
                        defaultMessage={"rejected"}
                        /></b>
          </span>
        </div>
      );
    } else {
      statusHeader = intl.formatMessage({
                        id: "total_images_required_label",
                        defaultMessage: "Total Images Required"
                      });
      statusContent = `${intl.formatMessage({
                        id: "minimum_label",
                        defaultMessage: "Minimum"
                      })} ${requirementsCount.total_required_images} ${intl.formatMessage({
                        id: "photos_label",
                        defaultMessage: "photos"
                      })}`;
      
    }

    requirementsCountDom = (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <DataComponent header={statusHeader} value={statusContent} />
          </div>
        </div>
      </div>
    );
    return (
      <div>
        {requirementsCountDom}
        {progressBarDom}
        {headerDom}
        {this.props.requirements.map((requirement, index) => {
          const className =
            index % 2 !== 0 ? classes.oddPanels : classes.evenPanels;
          return (
            <OneRequirement
              job_id={this.props.job_id}
              requirement={requirement}
              index={index}
              onUploadImagesRequirementChange={
                this.onUploadImagesRequirementChange
              }
              onUploadTotalRequirementsCountChange={
                this.onUploadTotalRequirementsCountChange
              }
              onUploadImagesJobComplete={this.props.onUploadImagesJobComplete}
              country={this.props.country}
            />
          );
        })}
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(RequirementHeader));

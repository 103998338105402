let swalFireIds = {
    new_password_does_not_match_label                                       :"New Password does not match with confirm password",
    password_has_successfully_changed_label                                 :"Password has been succesfully changed. Login with new password",
    something_went_wrong_please_mail_at_label                               :"Something went wrong. Please mail at oyoframes@gmail.com",
    enter_all_fields_to_proceed_label                                       :"Enter all the fields to proceed",
    cool_button_label                                                       :"Cool",

    thank_you_for_your_interest_in_collaborations_label                     :"Thank you for your interest in collaboration with us. We have received your request. We will get back to you as quickly as we can.",
    something_went_wrong_with_try_later_label                               :"Something went wrong! Please try again later.",
    confirm_button_bact_to_home_page_label                                  :"Back to Home Page",
    success_label                                                           :"Success",

    are_you_sure_label                                                      :"Are you sure?",
    how_was_the_shoot_label                                                 :"How was the shoot?",
    you_wont_be_able_to_revert_this_label                                   :"You won't be able to revert this!",
    yes_delete_it_button_label                                              :"Yes, delete it!",
    deleted_label                                                           :"Deleted!",
    your_file_has_been_deleted_label                                        :"Your file has been deleted.",

    your_score_is_label                                                     :"Your score is",
    job_reassigned_successfully_label                                       :"Job reassigned successfully!",
    something_went_wrong_alone_label                                        :"Something went wrong!",
    reassign_job_label                                                      :"Reassign Job",
    alert_you_need_to_provide_reason                                        :"ALERT: You need to provide the reason!",
    alert_you_need_to_provide_deadline                                      :"ALERT: You need to provide the deadline!",
    no_abort_label                                                          :"No, abort!",
    yes_approve_for_payment_label                                           :"Yes, approve for payment!",
    yes_approve_all_images                                                  :"Yes, approve all Images!",
    yes_mark_payment_done                                                   :"Yes, mark payment done!",

    photographer_label                                                      :"Photographer",
    reassign_to_label                                                       :"Reassign to:",
    provide_deadline_label                                                  :"Provide deadline",
    days_with_capital_label                                                 :"Days",
    reason_for_reassignment_label                                           :"Reason for reassignment",

    next_with_capital_label                                                 :"Next",
    congratulations_label                                                   :"Congratulations!",
    all_requirements_have_been_passed_label                                 :"All requirements have been passed.",
    kindly_proceed_to_submit_label                                          :"Kindly proceed to submit!",
    ota_google_drive_link_label                                             :"OTA Google Drive Link",
    alert_you_need_to_provide_link_label                                    :"ALERT: You need to provide the link!",
    alert_invalid_link_label                                                :"ALERT: Invalid Link",
    editor_declarations_label                                               :"Editor Declaration",
    checkbox_minimum_image_count_label                                      :"I have met the minimum image count in each category",
    checkbox_raw_images_received_label                                      :"Raw images received for this property has been edited and placed in correct MRC buckets",
    checkbox_no_objectionable_content_label                                 :"There is no objectionable content in the images uploaded",
    checkbox_no_duplicate_image_label                                       :"There are no duplicate images",
    checkbox_images_uploaded_in_correct_dim_label                           :"The images uploaded are in correct dimension and resolution",
    confirm_submit_button_label                                             :"Submit",
    alert_you_need_to_agree_to_terms_label                                  :"ALERT: You need to agree to the terms!",
    photographs_submitted_successfully_label                                :"Photographs have been submitted successfully!",

    oops_requirement_not_passed_label                                       :"Oops!! Requirements not passed!",
    all_requirement_must_proceed_label                                      :"All requirements must meet to proceed.",
    reason_for_partial_submission_label                                     :"Reason for partial submission",
    submit_for_partial_approval_button_label                                :"Submit for partial approval",
    kindly_submit_after_completing_task_label                               :"Kindly submit after completing task!",

    number_of_unique_images_label                                           :"Number of Unique Images:",
    additional_notes_label                                                  :"Additional notes",
    photographer_declaration_label                                          :"Photographer Declaration",
    phtoshot_conducted_exposure_property_label                              :"The photoshoot was conducted in 3 exposure and only raw images of this property have been submitted in the link",
    send_to_editor_button_label                                             :"Send to Editor",
    send_back_button_label                                                  :"Send Back",
    link_sent_to_editor_label                                               :"Link sent to Editor!",
    invalid_image_count_label                                               :"ALERT: Invalid Images count!",
    confirm_button_default_label                                            :"OK",
    cancel_buttoon_default_label                                            :"Cancel",
    send_for_editing_label                                                  :"Send for Editing",

    rejection_reason_label                                                  :"Rejection Reason",
    select_a_reason_label                                                   :"Select a reason",
    shoot_not_done_in_natural_light_label                                   :"Shoot not done in Natural Light(Curtains Closed)",
    shoot_done_using_flash_label                                            :"Shoot done using Flash",
    less_no_of_images_label                                                 :"Less number of images",
    image_not_in_exposures_label                                            :"Images not in 3 Exposures",
    mrc_folder_not_made_by_photographer_label                               :"MRC folders not made by photographer",
    inappropriate_images_light_blottig_label                                :"Inappropriate images - Light blotting, Night facade, Bracketing, Dark images",
    wrong_property_branding_runner_label                                    :"Wrong Property Branding/Runner Cushion Branding",
    issues_with_the_raw_drive_link_label                                    :"Issues with the raw Drive link",
    mandatory_folders_missing_label                                         :"Mandatory folders missing",
    empty_folders_label                                                     :"Empty folders",
    other_property_row_link_label                                           :"Other property raw link",
    paf_tr_filled_incorrectly_label                                         :"PAF/TR filled incorrectly",
    perspectice_issues_angles_captured_label                                :"Perspective issues/Wrong angles captured",
    others_label                                                            :"Others",
    please_specify_the_rejection_reasons_label                              :"Please specify the rejection reason",
    send_back_to_photographer_button_label                                  :"Send Back To Photographer",
    alert_please_provide_rejection_reason                                   :"ALERT: Please specify the rejection reason!",

    mandate_carts_washroom_facade_reception_missing_label                   :"Mandate Carts- Washroom/Facade/Reception Missing",
    mrc_images_missing_in_cart_label                                        :"MRC Images Missing in Cart",
    mrc_cart_missing_tech_label                                             :"MRC cart missing- Tech",
    mrc_pictures_incorrect_tagged_label                                     :"MRC Pictures Incorrect Tagged",
    mrc_not_matching_with_transformation_label                              :"MRC Not Matching With Transformation",
    facade_name_mismatched_with_property_name_label                         :"Facade Name Mismatched with Property Name",
    duplicate_images_label                                                  :"Duplicate Images",
    // images_not_uploaded_on_crs_label                                        :"Images Not Uploaded on CRS", FIXME: WHY?

    deadline_label                                                          :"Deadline",
    days_to_complete_the_job_label                                          :"Days to complete the job",
    approve_button_label                                                    :"Approve",
    yes_cancel_job_button_label                                             :"Yes, cancel job!",
    cancellation_reason_label                                               :"Cancellation Reason",

    property_not_ready_for_photoshot_label                                  :"Property not ready for photoshoot",
    photoshot_resheduled_by_tr_label                                        :"Photoshoot re-scheduled by TR",
    room_category_info_unavailable_label                                    :"Room category information unavailable",
    incorrect_categories_label                                              :"Incorrect categories",
    incorrect_amenities_label                                               :"Incorrect Amenities",
    incorrect_model_type_label                                              :"Incorrect Model Type/Property Type (SMART, OTH, Capital O, etc.)",
    property_readiness_updates_not_come_from_tr_label                       :"Property readiness updates not received from TR",
    google_drive_link_for_unready_properties_label                          :"Google Drive Link for unready properties\ images",
    alert_provide_link_for_images_unready_properties_label                  :"ALERT: You need to provide the link for the images of unready properties!",


    accept_the_job_label                                                    :"Accept the Job",
    you_need_to_provide_the_photographer_details_label                      :"You need to provide the photographer's details first!",
    name_of_the_photographer_label                                          :"Name of Photographer",
    email_of_the_photographer_label                                         :"Email of Photographer",
    contact_no_of_the_photographer_label                                    :"Contact No. of Photographer",
    alert_need_to_provide_details_label                                     :"ALERT: You need to provide the details!",
    alert_invalid_email_address_label                                       :"ALERT: Invalid Email Address!",
    alert_invalid_contact_no_label                                          :"ALERT: Invalid Contact Number!",

    photographs_have_been_synced_to_crs_label                               :"Photographs have been synced to CRS!",
    photographs_have_been_uploaded_and_synced_to_crs                        :"Photographs have been uploaded & synced to CRS!",


    photographer_not_available_on_allocated_shoot_date_lable                :"Photographer not available on allocated shoot date",
    specific_equipment_not_available_on_shoot_date_label                    :"Specific equipment not available on shoot date",
    natural_calamity_food_snow_earthquake_label                             :"Natural calamity i.e. Floods / Snow / Earthquake",
    human_calamity_riots_strike_label                                       :"Human Calamity i.e. Riots / Strike etc",
    multiple_requests_raised_label                                          :"Multiple requests raised",
    location_too_far_label                                                  :"Location too far",
    shoot_rescheduled_by_bd_label                                           :"Shoot Rescheduled by BD",
    property_occupied_or_not_vacant_for_shoots_label                        :"Property occupied or not vacant for shoots",
    obf_not_realized_label                                                  :"OBF NOT REALISED",

    image_property_score_less_then_85_label                                 :"Images Property Score less than 85",
    less_then_no_of_images_label                                            :"Less Number of Images",
    images_property_score_and_less_images_label                             :"Images Property Score less than 85 and Less Number of Images",

    this_job_is_not_assigned_to_you_go_back_job_list_label                  :"This Job is not assigned to you. Please go back to your job list",
    okay_button_label                                                       :"Okay",
    photograps_have_been_uploaded_successfully_label                        :"Photographs have been uploaded successfully!",
    score_not_calculated_hit_cal_score_first_label                          :"Score not calculated. Please hit Calculate Score first.",
    no_file_chosen_label                                                    :"No files chosen!!!",
    uploading_flag_label                                                    :"Uploading...",
    image_resolution_error                                                  :"{count} image rejected because of low resolution (minimum resolution of 3074 X 2304 required).",
    image_orientation_error                                                 :"Vertical images are not supported. Please upload horizontal (landscape) images only."























    





}

export default {
    ...swalFireIds
}
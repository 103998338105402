import React, { Component } from 'react';
import { withStyles,Tabs ,Tab } from "@material-ui/core";
import { FormattedMessage ,injectIntl} from 'react-intl';
const styles = _ => ({
  root: {
    display: "flex"
  },
  
  tabsBar: {
    width: "100%",
    height: "24px",
  },
  individualTabs: {
    fontFamily: "Inter",
    width: "auto",
    height: "24px",
    color: "#1E1E1E",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    fontSize: "16px",
    fontStyle: "normal",
    textTransform: "initial"
  }
});

class TabBar extends Component {
    constructor(props){
        super(props);
        this.state={
          tabValue:0
        }
    }

 
    handleChange = (event, newValue) => {
    this.setState({tabValue:newValue});
    this.props.setTabValue(newValue);
     };

    render() { 
        const { classes,intl } = this.props;
        return (
        <div className={classes.root}>
            <Tabs className={classes.tabsBar}
                value={this.state.tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handleChange}
                aria-label="disabled tabs example"
            >
                <Tab className={classes.individualTabs} label={intl.formatMessage({
                        id: "active_task_label",
                        defaultMessage: "Active task"
                      })}  />
                <Tab className={classes.individualTabs} label={intl.formatMessage({
                        id: "completed_task_label",
                        defaultMessage: "Completed"
                      })}   />
                <Tab className={classes.individualTabs} label={intl.formatMessage({
                        id: "rejected_task_label",
                        defaultMessage: "Rejected/Cancelled"
                      })} />
                <Tab className={classes.individualTabs} label={intl.formatMessage({
                        id: "payment_done_task_label",
                        defaultMessage: "Payment Approved/Done"
                      })} />
            </Tabs>
    
        </div>);
    }
}
 
export default injectIntl(withStyles(styles)(TabBar));

export const generateRandomId = () => {
  const date_time = new Date()
    .getTime()
    .toString()
    .substring(0, 10);
  const random_character = Math.random()
    .toString(36)
    .substring(5);
  return `${date_time}_${random_character}`;
};

import React from "react";
import PropTypes from "prop-types";
import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubMenuItem from "./CSubMenuItem";

class Menu extends React.Component {
  renderMenuItems = () => {
    const { menuItems } = this.props;
    return menuItems.map(menuItem => {
      if (menuItem.hasOwnProperty("children")) {
        return (
          <SubMenuItem
            key={menuItem.key}
            caption={menuItem.caption}
            menuItems={menuItem.children}
            anchorElement={this.props.anchorElement}
            classes={this.props.classes}
            menuitemclassname={this.props.menuitemclassname}
          />
        );
      }

      return (
        <MenuItem key={menuItem.key} onClick={menuItem.onClick} className={this.props.menuitemclassname}>
          {menuItem.caption}
        </MenuItem>
      );
    });
  };

  render() {
    const { anchorElement, open, onClose, ...others } = this.props;
    return (
      <MuiMenu
        anchorEl={anchorElement}
        open={!!open}
        onClose={onClose}
        {...others}
      >
        {this.renderMenuItems()}
      </MuiMenu>
    );
  }
}

Menu.propTypes = {
  open: PropTypes.bool,
  menuItems: PropTypes.array.isRequired,
  anchorElement: PropTypes.any,
  onClose: PropTypes.func.isRequired
};

Menu.defaultPropTypes = {
  open: false
};

export default Menu

import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import DataComponent from "./DataComponent";

const styles = theme => ({
  root: {
    marginBottom: "15px",
    marginLeft: "20px",
    marginRight: "20px"
  },
  spocDetails: {
    height: "96px",
    width: "100%",
    border: "1px solid rgba(0,0,0,0.08)",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  spocDetailsText: {
    textAlign: "left",
    color: "rgba(0,0,0,0.54)",
    fontSize: "16px",
    marginBottom: "12px"
  }
});

function SpocDetails(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.spocDetailsText}>
        Requester Details
      </Typography>

      <div className={classes.spocDetails}>
        <DataComponent header="Name" value={props.jobInfo.spoc_data.name} />

        <DataComponent header="Email" value={props.jobInfo.spoc_data.email} />

        <DataComponent header="Phone" value={props.jobInfo.spoc_data.phone} />
      </div>
    </div>
  );
}

export default withStyles(styles)(SpocDetails);

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import indonesian from 'react-intl/locale-data/id';
import englishMessages from './languages/en/en';
import indonesianMessages from './languages/id/id';
import { defaultLocale } from './Config';

addLocaleData([...en,...indonesian]);

export const languageMapping = {
  [defaultLocale]: {
     messages: englishMessages,
  },
  'id': {
     messages: indonesianMessages,
  }

}

import React, { Component } from 'react';
import { withStyles ,Drawer,SwipeableDrawer,Button,CircularProgress} from "@material-ui/core";
import * as vectors from "../../../../../Resources/Vectors/index";
import axios from "../../../../../Axios/OyoStudioWebServiceInstance";
import Swal from "sweetalert2";
import { FormattedMessage ,injectIntl } from 'react-intl';
import "./index.css";

const styles = theme => ({
  root: {
    display: "block"
  },
  drawerContainer: {

  },
  circularProgressContainer: {
    width: "700px"
  },
  progress: {
    marginLeft: "300px"
  },
  paper: {
    overflowY: "unset"
  }
});

class I18DSM extends Component {
  state = {
    rightDrawer:true,
    scoreData:{},
    jobLoading:false,
    jobInfo:null
  }

  componentDidMount= () =>{
    const { intl } = this.props;
    var url =`/vendors/job_preview`;
    var  params = {
      job_id: this.props.job_id,
      email: localStorage.email
    };
    axios
        .get(url, { params: params })
        .then(previewResponse => {
          this.setState({jobInfo:previewResponse.data.data});
          url =`/photoshoot_jobs/score_details`;
          params = {
            job_id: this.props.job_id
          };
          axios
              .get(url, { params: params })
              .then(response => {
                this.setState({
                  scoreData: response.data.data,
                  jobLoading: true,
                });

              })
              .catch(error => {
                this.props.toggleDSMDrawer(false);
                Swal.fire({
                  type: "info",
                  title: "Oops...",
                  text:  error,
                  allowOutsideClick: () => !Swal.isLoading()
                }).then(result => {
                  if (result.value) {
                    this.props.toggleDSMDrawer(false);
                  }
                });
              });
        })
        .catch(() => {
          this.setState({
            jobInfo: {},
            jobLoading: false
          });
        });

  }


  toggleDrawer = (side,open) => {
    if(!open)
      this.setState({ jobLoading: false });
    this.setState({ rightDrawer: open });
    this.props.toggleDSMDrawer(open);
  };

  render() {
    const {classes} = this.props;
    var scoreValue=0;
    var scoreCircleLeft=0;
    var scoreCircleTop=0;

    var propertyHeader=null;
    var scoreContainer=null;
    var recommendationsContainer=null;
    var photoDetailsContainer=null;
    var propertyName= this.state.jobInfo === null ? "" : this.state.jobInfo.property_data.property_name;


    if(this.state.jobLoading){
      scoreValue=this.state.scoreData.overallScore.value;
      scoreCircleLeft=0;
      scoreCircleTop=0;

      if(scoreValue>96)
      {

        scoreCircleLeft=85.5*Math.cos(((180-1.8*scoreValue)*22)/(7*180))+92.5+3;
        scoreCircleTop=80-85.5*Math.sin(((180-1.8*scoreValue)*22)/(7*180));
      }
      else if(scoreValue>50)
      {

        scoreCircleLeft=85.5*Math.cos(((180-1.8*scoreValue)*22)/(7*180))+92.5;
        scoreCircleTop=80-85.5*Math.sin(((180-1.8*scoreValue)*22)/(7*180));
      }
      else if(scoreValue>25)
      {

        scoreCircleLeft=85.5*Math.cos(((180-1.8*scoreValue)*22)/(7*180))+92.5-5;
        scoreCircleTop=80-85.5*Math.sin(((180-1.8*scoreValue)*22)/(7*180));
      }
      else if(scoreValue>=20)
      {

        scoreCircleLeft=85.5*Math.cos(((180-1.8*scoreValue)*22)/(7*180))+92.5-8;
        scoreCircleTop=80-85.5*Math.sin(((180-1.8*scoreValue)*22)/(7*180));
      }
      else if(scoreValue>10)
      {

        scoreCircleLeft=90.5*Math.cos(((180-1.8*scoreValue)*22)/(7*180))+92.5-4;
        scoreCircleTop=80-90.5*Math.sin(((180-1.8*scoreValue)*22)/(7*180));
      }
      else
      {

        scoreCircleLeft=90.5*Math.cos(((180-1.8*scoreValue)*22)/(7*180))+92.5-5;
        scoreCircleTop=80-90.5*Math.sin(((180-1.8*scoreValue)*22)/(7*180));
      }

      propertyHeader =(
          <div className="property-header-container">
            <h5 className="property-header-light-label">Property Name</h5>
            <h5 className="property-header-dark-label">{propertyName}</h5>
            <div className="property-header-horizontal-line"></div>
          </div>
      )

      scoreContainer =(
          <div className="score-main-container">
            <div className="score-overall-chart-container">
              <div className="score-inner-chart-container">
                <img className="score-boundry-image" src={vectors.ScoreChart}/>
                <div className="score-circle-image"  id ="scoreCircleImage" style={{left: `${scoreCircleLeft}px`,top: `${scoreCircleTop}px`, border:`${scoreValue >= 66 ? "4px solid #00B28A": (scoreValue <= 33 ? "4px solid #EF4123" : "4px solid #FDA177") }` }}/>
                <img className="score-crown-image" src={vectors.Crown}/>
                <h6 className="overall-score-header">{this.state.scoreData.overallScore.value}</h6>
                <p className="overall-score-p-label">{this.state.scoreData.overallScore.displayName}</p>
              </div>
            </div>
            <div className="score-verticle-line-container"></div>
            <div className="score-overall-score-container">
              {
                this.state.scoreData["scoreBreakup"].map(item=>{
                  return   (<div className="individual-score-container">
                    <h6>{item.value}</h6>
                    <p>{item.displayName}</p>
                  </div>);
                })
              }
            </div>
          </div>
      )



      recommendationsContainer =(
          <div className="recomm-main-container">
            <h6 className="recomm-header-label">Recommendations to improve score</h6>
            {

              this.state.scoreData["comments"].map((item ,index)=>{
                if(index % 2 === 0)
                  return (
                      <div className="individual-comments-container-even-row">
                        <img className="check-circle-image" src={vectors.CheckCircle}/>
                        <p className="recomm-comments-label">{item.message}</p>
                      </div>
                  )
                else
                  return (
                      <div className="individual-comments-container-odd-row">
                        <img className="check-circle-image" src={vectors.CheckCircle}/>
                        <p className="recomm-comments-label">{item.message}</p>
                      </div>
                  )
              })
            }
          </div>
      )


      photoDetailsContainer =(
          <div className="photo-details-container">
            <Button  variant="outlined" color="primary">
              Show photography guidelines
            </Button>
          </div>
      )
    }

    let jobsDOM = null;
    if (!this.state.jobLoading) {
      jobsDOM = (<div className={classes.circularProgressContainer}><CircularProgress className={classes.progress} size={70} /></div>)
    } else {
      jobsDOM = (
          <div className="dsm-main-container">
            {propertyHeader}
            {scoreContainer}
            {recommendationsContainer}
            {/* {photoDetailsContainer} */}
          </div>
      );
    }

    return (
        <div className="i18dsm-container">
          <SwipeableDrawer
              classes={{ paper: classes.paper }}
              anchor="right"
              open={this.state.rightDrawer}
              onClose={() => this.toggleDrawer('right', false)}
              onOpen={() => this.toggleDrawer('right', true)}
              className="i18dsm-drawer-container"
          >
            <img className="dsm-window-close-button" src={vectors.WindowCloseWhite} onClick={() => this.props.toggleDSMDrawerOnly(false)}/>
            {jobsDOM}
          </SwipeableDrawer>
        </div>);
  }
}


export default injectIntl(withStyles(styles)(I18DSM));
export const COUNTRY_NAMES = {
  USA       : "USA",
  INDIA     : "INDIA",
  UK        : "UK",
  SAUDI     : "SAUDI",
  INDONESIA : "INDONESIA",
  MALAYSIA  : "MALAYSIA",
  AUSTRALIA : "AUSTRALIA",
  BRAZIL    : "BRAZIL",
  CHINA     : "CHINA",
  FRANCE    : "FRANCE",
  GERMANY   : "GERMANY",
  ITALY     : "ITALY",
  JAPAN     : "JAPAN",
  MEXICO    : "MEXICO",
  NEPAL     : "NEPAL",
  REPUBLIC_OF_IRELAND   : "REPUBLIC OF IRELAND",
  THAILAND              : "THAILAND",
  BAHRAIN               : "BAHRAIN",
  OMAN                  : "OMAN",
  PHILIPPINES           : "PHILIPPINES",
  SINGAPORE             : "SINGAPORE",
  SPAIN                 : "SPAIN",
  SRI_LANKA             : "SRI LANKA",
  UNITED_ARAB_EMIRATES  : "UNITED ARAB EMIRATES",
  VIETNAM               : "VIETNAM"
}
import React, { Component } from "react";
import Header from "./Header/Header";
import { withStyles } from "@material-ui/core";
import _ from "lodash";
import Swal from "sweetalert2";
import JobInformation from "./JobInformation/JobInformation";
import ActionButtons from "./ActionButtons/ActionButtons";
import SpocDetails from "./SpocDetails/SpocDetails";
import Requirement from "./Requirement/Requirement";
import axios from "../../../../Axios/OyoStudioWebServiceInstance";
import CircularProgress from "@material-ui/core/CircularProgress";
import Countdown from "react-countdown-now";
import RequirementList from "../../../Requirement/RequirementData/RequirementList/RequirementList";
import { FormattedMessage, injectIntl } from "react-intl";
const styles = _ => ({
  root: {
    marginTop: "7rem",
    marginBottom: "30px"
  },
  progress: {
    marginTop: "20%",
    marginBottom: "20%"
  }
});

class JobData extends Component {
  state = {
    job_id: this.props.job_id,
    user_type: null,
    hotelData: null,
    jobData: null,
    photographerData: null,
    oyoSpocData: null,
    jobDataLoading: false,
    requirements: null,
    allowed_actions: null,
    job_details: null,
    job_completed: null,
    property_name: null,
    crs_id: null,
    active_zip_url: null,
    requirements_count: null,
    is_score_calculated: false
  };

  toggleDrawerRandomFun = () => {};
  onUploadImagesJobComplete = job_completed => {
    this.setState({
      job_completed: job_completed
    });
  };

  getJobData = (job_id, hideLoader) => {
    const { intl } = this.props;
    if (!hideLoader) {
      this.setState({
        jobDataLoading: true
      });
    }

    let url = "/vendors/job";
    let params = {
      email: localStorage.email,
      job_id: job_id
    };
    axios
      .get(url, { params: params })
      .then(response => {
        let user_type = response.data.data.user_type;
        let job_data = response.data.data.job_data;
        let hotel_data = response.data.data.hotel_data;
        let job_details = response.data.data.job_details;
        let photographer_data = response.data.data.photographer_data;
        let property_name = hotel_data.property_name;
        let crs_id = hotel_data.crs_id;
        let requirements = response.data.data.requirements;
        let oyo_spoc_data = response.data.data.oyo_spoc_data;
        let allowed_actions = response.data.data.permissions.allowed_actions;
        let requirements_count = response.data.data.requirements_count;
        this.setState({
          user_type: user_type,
          job_id: job_id,
          jobData: job_data,
          hotelData: hotel_data,
          photographerData: photographer_data,
          oyoSpocData: oyo_spoc_data,
          requirements: requirements,
          jobDataLoading: false,
          allowed_actions: allowed_actions,
          job_details: job_details,
          job_completed: job_details.is_completed,
          property_name: property_name,
          crs_id: crs_id,
          active_zip_url: job_data.active_zip_url,
          expiration_time: job_data.expiration_time,
          requirements_count: requirements_count,
          is_score_calculated: job_details.is_score_calculated
        });
      })
      .catch(error => {
        Swal.fire({
          text: intl.formatMessage({
            id: "this_job_is_not_assigned_to_you_go_back_job_list_label",
            defaultMessage:
              "This Job is not assigned to you. Please go back to your job list"
          }),
          type: "warning",
          showCancelButton: true,
          cancelButtonText: intl.formatMessage({
            id: "cancel_buttoon_default_label",
            defaultMessage: "Cancel"
          }),
          confirmButtonColor: "#3085d6",
          confirmButtonText: intl.formatMessage({
            id: "okay_button_label",
            defaultMessage: "Okay"
          })
        }).then(result => {
          if (result.value) {
            window.location.assign("/jobs/search");
            this.setState({
              job_id: job_id,
              user_type: null,
              jobData: null,
              photographerData: null,
              jobDataLoading: false,
              oyoSpocData: null,
              requirements: null,
              allowed_actions: null,
              job_details: null,
              job_completed: null,
              property_name: null,
              crs_id: null,
              active_zip_url: null,
              expiration_time: null,
              requirements_count: null,
              is_score_calculated: false
            });
          }
        });
      });
  };

  componentDidMount = () => {
    let url_array = this.props.location.pathname.split("/");
    let job_id = url_array[url_array.length - 1];
    this.getJobData(job_id, false);
  };

  render() {
    const { classes, intl } = this.props;
    let jobsDom = null;
    let stateWiseBottomDom = null;
    let property_score = null;
    let job_status = null;

    // Renderer callback with condition
    const Completionist = () => (
      <span>
        <FormattedMessage
          id={"job_time_expired_label"}
          defaultMessage={
            "Job Time has been expired. The job has been cancelled automatically!"
          }
        />
      </span>
    );
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        return <Completionist />;
      } else {
        return (
          <span>
            <b>
              {days}{" "}
              <FormattedMessage id={"days_label"} defaultMessage={"days"} />
              {" "}{hours}{" "}
              <FormattedMessage id={"hours_label"} defaultMessage={"hours"} />
              {" "}{minutes}{" "}
              <FormattedMessage id={"minutes_left_label"} defaultMessage={"minutes left"} />
            </b>
          </span>
        );
      }
    };

    const clockRenderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        return null;
      } else {
        return (
          <span>
            <b>
              {days}{" "}
              <FormattedMessage id={"days_label"} defaultMessage={"days"} />
              {" "}{hours}{" "}
              <FormattedMessage id={"hours_label"} defaultMessage={"hours"} />
              {" "}{minutes}{" "}
              <FormattedMessage
                id={"minutes_left_label"}
                defaultMessage={"minutes left"}
              />
            </b>
          </span>
        );
      }
    };

    let clockDOM = null;
    if (this.state.jobData && this.state.jobData.expiration_time) {
      clockDOM = (
        <Countdown
          date={this.state.jobData.expiration_time}
          renderer={renderer}
        />
      );
    }

    if (this.state.jobDataLoading) {
      jobsDom = <CircularProgress className={classes.progress} size={100} />;
    } else {
      let headerDom = null;
      let jobInformationDom = null;
      let requirementsDom = null;
      let requirementListDom = null;
      let actionButtonsDom = null;
      let extraSubmissionTimeDom = null;

      if (this.state.requirements && this.state.user_type !== "Vendor") {
        requirementListDom = (
          <RequirementList
            requirements={this.state.requirements}
            requirements_count={this.state.requirements_count}
            job_details={this.state.job_details}
            job_id={this.state.job_id}
            onUploadImagesJobComplete={this.onUploadImagesJobComplete}
            country={this.state.jobData.country}
          />
        );
      }

      if (this.state.jobData) {
        let job_status = this.state.jobData.job_status || null;
        jobInformationDom = (
          <JobInformation
            jobInformation={this.state.jobData}
            hotelInformation={this.state.hotelData}
            photographerInformation={this.state.photographerData}
            oyoSpocInformation={this.state.oyoSpocData}
            property_name={this.state.property_name}
            crs_id={this.state.crs_id}
            user_type={this.state.user_type}
          />
        );
        if (job_status == "Requested") {
          stateWiseBottomDom = clockDOM;
        } else if (
          job_status == "In Progress" ||
          job_status == "Editing In Progress"
        ) {
          stateWiseBottomDom = requirementListDom;
          extraSubmissionTimeDom = (
            <Countdown
              daysInhours={true}
              date={this.state.jobData.expiration_time}
              renderer={clockRenderer}
            />
          );
        } else if (["Partial Submission Requested", "Partial Submission Rejected", "Completed","Payment Approved"].includes(job_status)){
          stateWiseBottomDom = requirementListDom;
        } else if (job_status == "Partially Completed") {
          stateWiseBottomDom = requirementListDom;
          extraSubmissionTimeDom = (
            <Countdown
              daysInhours={true}
              date={this.state.jobData.expiration_time}
              renderer={renderer}
            />
          );
        } else if (["Soft Live Requested", "Soft Live Completed"].includes(job_status)) {
          stateWiseBottomDom = requirementListDom;
        }
      }

      if (this.state.requirements) {
        requirementsDom = (
          <Requirement requirements={this.state.requirements} />
        );
      }

      if (this.state.allowed_actions) {
        let score_calculation_allowed = true //["Live", "Active"].includes(this.state.hotelData.crs_status);
        actionButtonsDom = (
          <ActionButtons
            DrawerClose={() => this.toggleDrawerRandomFun()}
            job_id={this.state.job_id}
            allowed_actions={this.state.allowed_actions}
            getJobData={this.getJobData}
            job_completed={this.state.job_completed}
            user_type={this.state.user_type}
            is_score_calculated={this.state.is_score_calculated}
            threshold_validation_message={this.state.job_details.validation_error_message}
            score_calculation_allowed={score_calculation_allowed}
          />
        );
      }
      property_score =
        this.state.jobData && this.state.jobData.property_score
          ? this.state.jobData.property_score
          : null;
      job_status =
        this.state.jobData && this.state.jobData.job_status
          ? this.state.jobData.job_status
          : null;

      headerDom = (
        <Header
          property_name={this.state.property_name}
          property_score={property_score}
          job_status={job_status}
        />
      );
      jobsDom = (
        <div className={classes.root}>
          {headerDom}
          <br />
          {jobInformationDom}
          <br />
          {requirementsDom}
          <br />
          {stateWiseBottomDom}

          <br />
          <br />
          {extraSubmissionTimeDom}
          {actionButtonsDom}
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">{jobsDom}</div>
        <div className="col-sm-1"></div>
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(JobData));

import isNumber from 'lodash/isNumber';
import { loadImage } from './loadImage';
import Swal from "sweetalert2";

const imageValidate = async payload => {
  const { files, minWidth, minHeight, intl } = payload;
  const selectedImageFiles = [];
  const rejectedFiles = [];
  const checkFile = async res => {
    try {
      const image = await loadImage(URL.createObjectURL(res));
      const { naturalHeight, naturalWidth } = image;
      if (naturalHeight > naturalWidth) {
        Swal.fire({
          position: "center",
          type: "error",
          title: intl.formatMessage({
            id: "image_orientation_error",
            defaultMessage: "image orientation error"
          }),
          showConfirmButton: true,
          confirmButtonText: intl.formatMessage({
            id: "confirm_button_default_label",
            defaultMessage: "OK"
          }),
          allowOutsideClick: true
        })
      }
      else if (
        (isNumber(naturalHeight) && isNumber(minHeight) && naturalHeight < minHeight) ||
        (isNumber(naturalWidth) && isNumber(minWidth) && naturalWidth < minWidth)
      ) {
        rejectedFiles.push(res);
      } else {
        selectedImageFiles.push(res);
      }
    } catch (error) {
      rejectedFiles.push(res);
    }
  };

  for (const res of files) {
    await checkFile(res);
  }

  return {
    rejectedImageFiles: rejectedFiles,
    imageFiles: selectedImageFiles
  };
};

export default imageValidate;
import React, { Component } from 'react';
import {IntlProvider} from "react-intl"
import { languageMapping } from './index';
import { connect } from 'react-redux';
import * as actionCreators from '../Store/actions/index';
import { validLocales } from './locales';

class IntlProviderWrapper extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  getPersistedLocale = () => {
    let userLocale = localStorage.getItem('locale') || "";
    if(validLocales.indexOf(userLocale) >= 0) {
      return userLocale;
    }
    return "en";
  }

  componentDidMount() {
    let userLocale = this.getPersistedLocale();
    if(this.props.locale !== userLocale) {
      this.props.changeLocale(userLocale);
    }
  }

  render() {
    return (
      <IntlProvider
        {...this.props}
        locale={this.props.locale}
        messages={languageMapping[this.props.locale].messages}
      >
        {this.props.children}
      </IntlProvider>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    locale: state.i18n.locale
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeLocale: (locale) => dispatch( actionCreators.changeLocale(locale) )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntlProviderWrapper);




import React, { Component, Fragment } from 'react';
import { withStyles, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actionCreators from '../../../Store/actions';
import CMenu from '../../../Containers/Custom/CMenu/CMenu';
import locales from '../../../I18N/locales';
import LanguageLogo from '@material-ui/icons/Language';
import * as vectors from "../../../Resources/Vectors/index";
import "../Appbar.css"
const buttonWidth = "100px"
const styles = theme => ({

});

class I18NInfo extends Component{

  constructor(props) {
    super(props);
    this.state = {
      anchorEl : null
    }
  }

  menuClickHandler = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  menuCloseHandler = () => {
    this.setState({ anchorEl: null });
  }

  menuOptionClickListener = (locale) => {
    let newState = {...this.state};

    newState.anchorEl = null;

    this.setState(newState);
    this.props.changeLocale(locale.key);
  }

  getLocaleObject(locale) {
    return {
      key: locale.key,
      caption: locale.caption,
      onClick: () => {
        this.menuOptionClickListener(locale);
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const languages = locales;

    const menuItems = [];
    languages.forEach(locale => {
      let language = this.getLocaleObject(locale);
      menuItems.push(language);
    })
    let menuOptions = null;
    if(true) {
      menuOptions = (
        <div className="i18n-main-container">
          <Button
            color="inherit"
            className={classes.i18NButtonContainer}
            onClick={this.menuClickHandler}
            aria-owns={anchorEl ? 'simple-menu' : null}>

            {/* <LanguageLogo/> */}
            <img src={vectors.Globe}/>
            &nbsp;
            <div className={classes.localeName}>
              {this.props.locale}
            </div>

          </Button>
          <CMenu
            open={!!this.state.anchorEl}
            menuItems={menuItems}
            anchorElement={anchorEl}
            onClose={this.menuCloseHandler}
            classes={{
              paper : classes.menu,
            }}
            menuitemclassname={classes.menuItem}
            style={{marginTop: "45px"}}
          />

        </div>
      );
    }

    return (
      <Fragment>
        {menuOptions}
      </Fragment>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    locale : state.i18n.locale
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeLocale: (locale) => dispatch( actionCreators.changeLocale(locale) )
  };
};

const I18NInfoWithStyles = withStyles(styles)(I18NInfo);
export default connect(mapStateToProps, mapDispatchToProps)(I18NInfoWithStyles);

import React, { Component } from "react";
import Header from "./Header/Header";
import Filter from "./Filter/Filter";
import JobsList from "./JobsList/JobsList";
import Switch from "@material-ui/core/Switch";
import { withStyles, Divider } from "@material-ui/core";
import _ from "lodash";
import TabBar from "./TabBar/TabBar";

const styles = _ => ({
  root: {
    marginLeft: "125px",
    marginRight: "125px",
    marginTop: "82px",
    marginBottom: "100px"
  },
  dividerTabs: {
    margin: "-1px 0 0 0 "
  }
});

class AllJobs extends Component {
  state = {
    filters: null,
    allowed_filters: null,
    softLiveTag: false,
    user_type: null,
    jobStatusValue: "active_task"
  };

  allowedFiltersUpdate = filters => {
    if (filters) {
      this.setState({
        allowed_filters: filters
      });
    }
  };

  onFiltersChange = filters => {
    if (!_.isEqual(this.state.filters, filters)) {
      this.setState({
        filters: filters
      });
    }
  };

  setTabValue = value => {
    if (value === 0) this.setState({ jobStatusValue: "active_task" });
    else if (value === 1) this.setState({ jobStatusValue: "completed_task" });
    else if (value === 2) this.setState({ jobStatusValue: "rejected_task" });
    else this.setState({ jobStatusValue: "payment_task" });
  };

  toggleSoftLive = event => {
    this.setState(prevState => {
      return { softLiveTag: !prevState.softLiveTag };
    });
  };

  setUserType = user_type => {
    this.setState({ user_type: user_type });
  };

  render() {
    const { classes } = this.props;
    let softLiveTagVisible = ["Super Admin", "Admin", "QC"].includes(this.state.user_type);
    return (
      <div className={classes.root}>
        <Header softLiveTagVisible={softLiveTagVisible} softLiveTag={this.state.softLiveTag} toggleSoftLive={this.toggleSoftLive}/>
        <TabBar setTabValue={this.setTabValue}/>
        <Divider className={classes.dividerTabs} />
        <Divider className={classes.dividerTabs} />
        <br />
        <Filter
          onChange={this.onFiltersChange}
          allowed_filters={this.state.allowed_filters}
          isSoftLive={this.state.softLiveTag}
        />

        <br />
        <Divider />
        <Divider />
        <br />

        <JobsList
          jobStatusValue={this.state.jobStatusValue}
          filters={this.state.filters}
          softLiveTag={this.state.softLiveTag}
          allowedFiltersUpdate={this.allowedFiltersUpdate}
          setUserType={this.setUserType}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AllJobs);

import React, { useState } from 'react';
import { withStyles ,Typography } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';


const styles = theme => ({
  root: {
    display: "block",
    marginRight: "46px"
  },
  header: {
    fontFamily:"Inter",
    marginTop: "20px",
    position: "relative",
    marginLeft: "48px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0,0,0,0.54)",
  },
  propertiesContainer: {
    marginTop: "10px",
    width: "627px",
    height: "auto",
    marginLeft: "47px",
    border: "1px solid rgba(0, 0, 0, 0.08)",
    boxSizing: "border-box",
    borderRadius: "8px"
  },
  row: {
    display: "flex",
    justifyContent: "initial",
    padding: "24px 0 0 0"
  },
  secondRow: {
    display: "flex",
    justifyContent: "initial",
    padding: "20px 0 37px 0"
  },
  firstColumn: {
    marginLeft: "78px",
    width: "200px"

  },
  secondColumn: {
    marginLeft: "100px"
  },
  labelTypeOne: {
    fontFamily:"Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  labelTypeTwo: {
    fontFamily:"Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)"
  }
});

function PropertyDetails(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
     <Typography className={classes.header}>
       <FormattedMessage
          id={"property_details_label"}
          defaultMessage={"Property Details"}
          /></Typography>
     <div className={classes.propertiesContainer}>
         <div className={classes.row}>
            <div className={classes.firstColumn}>
                <Typography className={classes.labelTypeOne}>
                  <FormattedMessage
                        id={"oyo_id_label"}
                        defaultMessage={"OYO ID"}
                        /></Typography>
                <Typography className={classes.labelTypeTwo}>{props.jobInfo.property_data.crs_id}</Typography>
            </div>
            <div className={classes.secondColumn}>
                <Typography className={classes.labelTypeOne}>
                  <FormattedMessage
                        id={"oyo_type_lable"}
                        defaultMessage={"Type"}
                        /></Typography>
                <Typography className={classes.labelTypeTwo}>{props.jobInfo.job_data.model_type}</Typography>
            </div>
         </div>
         <div className={classes.secondRow}>
            <div className={classes.firstColumn}>
                <Typography className={classes.labelTypeOne}>
                  <FormattedMessage
                        id={"property_address_label"}
                        defaultMessage={"Property Address"}
                        /></Typography>
                <Typography className={classes.labelTypeTwo}>{`${( props.jobInfo.location_data !== (null || undefined) &&  props.jobInfo.location_data.street_address!== null) ? props.jobInfo.location_data.street_address : ""}, ${(props.jobInfo.location_data !== (null || undefined) && props.jobInfo.location_data.state !== null ) ? props.jobInfo.location_data.state+"," : ""} ${(props.jobInfo.location_data !== (null || undefined) && props.jobInfo.location_data.city_name !== null ) ? props.jobInfo.location_data.city_name : ""}`} </Typography>
            </div>
            <div className={classes.secondColumn}>
                <Typography className={classes.labelTypeOne}>
                  <FormattedMessage
                        id={"city_type_label"}
                        defaultMessage={"City Type"}
                        /></Typography>
                <Typography className={classes.labelTypeTwo}>{props.jobInfo.job_data.city_type}</Typography>
            </div>
         </div>
     </div>
    </div>
  );
}


export default withStyles(styles)(PropertyDetails);



import React, { useState } from 'react';
import { withStyles ,Typography } from "@material-ui/core";
import { CButton } from '../../../../../Components/Custom';


const styles = theme => ({
  root: {
    fontFamily: "Inter",
    display: "block",
    textAlign: "center"
    // marginRight: "46px"
  },
  button: {

  }
});

function ViewDetails(props) {
  const { classes } = props;
  let job_url = "/jobDetails/" + props.jobInfo.job_data.job_id;
  return (
    <div className={classes.root}>
    <CButton 
              className={classes.button}
              toURL={job_url}
              displayName="view_details_button_label"
              buttonType={0}
    />
    </div>
  );
}


export default withStyles(styles)(ViewDetails);



import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';
const styles = _ => ({
  root: {
    marginLeft: "10px",
    marginRight: "10px",
    display: "block"
  },
  header: {
    color: "rgba(0,0,0,0.54)",
    fontSize: "14px",
    marginBottom: "5px",
    overflow: "scroll"
  },
  value: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    overflow: "scroll"
  }
});

function DataComponent(props) {
  const { classes } = props;
  let propDom = props.value;
  if (Array.isArray(propDom)) {
    propDom = propDom.join(", ");
  }
if(props.messageId === undefined ){
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>{props.header}</Typography>
      <Typography className={classes.value}>{propDom || "-"}</Typography>
    </div>
  );
}
  
else {
return (
    <div className={classes.root}>
      <Typography className={classes.header}>
              <FormattedMessage
                        id={props.messageId}
                        defaultMessage={props.header}
                    /></Typography>
      <Typography className={classes.value}>{propDom || "-"}</Typography>
    </div>
    //  <div className={classes.root}>
    //   <Typography className={classes.header}>{props.header}</Typography>
    //   <Typography className={classes.value}>{propDom || "-"}</Typography>
    // </div>
  );
}
}

export default withStyles(styles)(DataComponent);

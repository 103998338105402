import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import {CAutoComplete} from "../../../../../Components/Custom/index";
import {connect} from "react-redux";
import axios from "../../../../../Axios/OyoStudioWebServiceInstance";
import _ from "lodash";
import {injectIntl} from "react-intl";

const styles = _ => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  setDefaultBtnNotSet: {
    border: "1px solid rgba(0,0,0,0.08)",
    borderRadius: "24px",
    color: "red",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "10px",
    fontWeight: "bold"
  },
  setDefaultBtnSet: {
    border: "1px solid rgba(0,0,0,0.08)",
    borderRadius: "24px",
    color: "green",
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "10px",
    fontWeight: "bold"
  },
  refreshFilters: {
    border: "1px solid rgba(0,0,0,0.08)",
    borderRadius: "50px",
    color: "red",
    alignSelf: "center"
  }
});

const saveFiltersSuccess = "success";
const saveFiltersMessageSuccess = "Filters saved successfully!";

const saveFiltersFailure = "error";
const saveFiltersMessageFailure = "Failed to save filters";

class Filter extends Component {
  state = {
    zoneSuggestions: [],
    hubSuggestions: [],
    citySuggestions: [],

    zoneList: null, //it contains the list of all zones to be shown under zones select menu when the zones tab is cleared
    hubList: null, //it contains the list of all hubs ...
    cityList: null, //it contains the list of all cities ...

    setFilters: false,
    disableDefaultTick: false,

    categoriesSuggestions: [],
    subCategorySuggestions: [],
    categoriesList: [],
    subCategoriesList: [],
    categorySelected: null,
    subCategorySelected: null,
    snackbarOpen: false,
    saveDefaultButtonStatus: true,
    saveFilterstatus: saveFiltersSuccess,
    saveFiltersMessage: saveFiltersMessageSuccess,

    modelTypeSuggestions: [],
    legalEntityTypeSuggestions: [],
    orderStatusSuggestionsHardLive: [
      { id: "Requested", status: "Requested" },
      { id: "In Progress", status: "Pending with Photographer" },
      { id: "Editing In Progress", status: "Pending with Editor" },
      { id: "Rejected", status: "Rejected" },
      { id: "Cancelled", status: "Cancelled" },
      { id: "Partial Submission Requested", status: "Partial Submission Requested" },
      { id: "Partial Submission Rejected", status: "Partial Submission Rejected" },
      { id: "Partially Completed", status: "Partially Completed" },
      { id: "Completed", status: "Completed" },
      { id: "Payment Approved", status: "Payment Approved" },
      { id: "Payment Done", status: "Payment Done" }
    ],
    orderStatusSuggestionsSoftLive: [
      { id: "Soft Live Created", status: "Soft Live Created" },
      { id: "Soft Live Requested", status: "Soft Live Requested" },
      { id: "Soft Live Completed", status: "Soft Live Completed" }
    ],
    selections: {
      zoneIds: null,
      hubIds: null,
      cityIds: null,
      categoryIds: null,
      subCategoryIds: null,
      modelTypeIds: null,
      legalEntityTypeIds: null,
      fromOrderDate: null,
      toOrderDate: null,
      crs_ids: null,
      statuses: null,
      cities: null,
      vendor_codes: null,
      hub: null,
      country: null,
      ...this.props.prefilter
    }
  };

  componentDidUpdate = () => {
    let selections = {
      ...this.state.selections,
      ...this.props.prefilter
    };
    if (!_.isEqual(selections, this.state.selections)) {
      this.setState({
        selections: this.state.selections
      });
    }
  };

  changeOrderStatus = selectedOrderStatus => {
    // this.props.clearOrderParams();
    let selections = {
      ...this.state.selections,
      statuses: selectedOrderStatus
    };
    this.setState(
      {
        selections: selections,
        saveDefaultButtonStatus: false
      },
      () => this.props.onChange(this.state.selections)
    );
  };

  //   }
  componentDidMount() {
    new Promise((resolve, reject) => {}).then(() => {});
    //TODO: sort everything
  }

  loadCrsIds = _.debounce((inputValue, callback) => {
    if (inputValue.length < 4) {
      return;
    } else {
      let url = "/autocomplete/values";
      let params = {
        item_type: "crs_id",
        value: inputValue
      };

      axios
        .get(url, { params: params })
        .then(response => {
          callback(response.data.data);
        })
        .catch(() => {
          callback([]);
        });
    }
  }, 300);

  loadCities = _.debounce((inputValue, callback) => {
    if (inputValue.length < 3) {
      return;
    } else {
      let url = "/autocomplete/values";
      let params = {
        item_type: "city_name",
        value: inputValue
      };

      axios
        .get(url, { params: params })
        .then(response => {
          callback(response.data.data);
        })
        .catch(() => {
          callback([]);
        });
    }
  }, 300);

  loadVendors = _.debounce((inputValue, callback) => {
    if (inputValue.length < 3) {
      return;
    } else {
      let url = "/autocomplete/values";
      let params = {
        item_type: "vendor_code",
        value: inputValue
      };

      axios
        .get(url, { params: params })
        .then(response => {
          callback(response.data.data);
        })
        .catch(() => {
          callback([]);
        });
    }
  }, 300);

  loadHubs = _.debounce((inputValue, callback) => {
    if (inputValue.length < 3) {
      return;
    } else {
      let url = "/autocomplete/values";
      let params = {
        item_type: "hub",
        value: inputValue
      };
      axios
        .get(url, { params: params })
        .then(response => {
          callback(response.data.data);
        })
        .catch(() => {
          callback([]);
        });
    }
  }, 300);

  loadCountries = _.debounce((inputValue, callback) => {
    if (inputValue.length < 3) {
      return;
    } else {
      let url = "/autocomplete/values";
      let params = {
        item_type: "country",
        value: inputValue
      };
      axios
        .get(url, { params: params })
        .then(response => {
          callback(response.data.data);
        })
        .catch(() => {
          callback([]);
        });
    }
  }, 300);

  changeCrsid = selectedCrsid => {
    let selections = {
      ...this.state.selections,
      crs_ids: selectedCrsid
    };
    this.setState(
      {
        selections: selections
      },
      () => this.props.onChange(this.state.selections)
    );
  };

  changeCity = selectedCities => {
    let selections = {
      ...this.state.selections,
      cities: selectedCities
    };
    this.setState(
      {
        selections: selections
      },
      () => this.props.onChange(this.state.selections)
    );
  };

  changeVendor = selectedVendors => {
    let selections = {
      ...this.state.selections,
      vendor_codes: selectedVendors
    };
    this.setState(
      {
        selections: selections
      },
      () => this.props.onChange(this.state.selections)
    );
  };

  changeCountry = selectedCountry => {
    let selections = {
      ...this.state.selections,
      country: selectedCountry
    };
    this.setState(
      {
        selections: selections
      },
      () => this.props.onChange(this.state.selections)
    );
  };

  changeHub = selectedHub => {
    let selections = {
      ...this.state.selections,
      hub: selectedHub
    };
    this.setState(
      {
        selections: selections
      },
      () => this.props.onChange(this.state.selections)
    );
  };

  getOyoIdLabel = option => {
    return option.crs_id;
  };

  getCityLabel = option => {
    return option.city_name;
  };

  getHubLabel = option => {
    return option.hub;
  };

  getCountryLabel = option => {
    return option.country;
  };

  getVendorLabel = option => {
    return option.vendor_code + " - " + option.spoc_email;
  };

  render() {
    const { classes, intl } = this.props;
    let crs_id_dom = null;
    let city_dom = null;
    let vendor_dom = null;
    let hub_dom = null;
    let country_dom = null;
    if (this.props.allowed_filters && this.props.allowed_filters.length) {
      this.props.allowed_filters.forEach(filter => {
        if (filter == "crs_id_filter") {
          crs_id_dom = (
            <CAutoComplete
              placeholder={intl.formatMessage({
                id: "oyo_id_label",
                defaultMessage: "OYO ID"
              })}
              isAsync={true}
              onChange={this.changeCrsid}
              loadOptions={this.loadCrsIds}
              allowCreate={false}
              isMulti={true}
              valueKey="crs_id"
              labelKey="crs_id"
              getOptionLabel={this.getOyoIdLabel}
              style={{
                width: "280px"
              }}
            />
          );
        } else if (filter == "city_filter") {
          city_dom = (
            <CAutoComplete
              placeholder={intl.formatMessage({
                id: "city_type_label",
                defaultMessage: "City"
              })}
              isAsync={true}
              onChange={this.changeCity}
              loadOptions={this.loadCities}
              allowCreate={false}
              isMulti={true}
              valueKey="city_name"
              labelKey="city_name"
              getOptionLabel={this.getCityLabel}
              style={{
                width: "280px"
              }}
            />
          );
        } else if (filter == "vendor_filter") {
          vendor_dom = (
            <CAutoComplete
              placeholder={intl.formatMessage({
                id: "vendor_code_label",
                defaultMessage: "Vendor Code"
              })}
              isAsync={true}
              onChange={this.changeVendor}
              loadOptions={this.loadVendors}
              allowCreate={false}
              isMulti={true}
              valueKey="vendor_code"
              labelKey="vendor_code"
              getOptionLabel={this.getVendorLabel}
              style={{
                width: "280px"
              }}
            />
          );
        } else if (filter == "hub_filter") {
          hub_dom = (
            <CAutoComplete
              placeholder={intl.formatMessage({
                id: "hub_select_label",
                defaultMessage: "Hub"
              })}
              isAsync={true}
              onChange={this.changeHub}
              loadOptions={this.loadHubs}
              allowCreate={false}
              isMulti={true}
              valueKey="hub"
              labelKey="hub"
              getOptionLabel={this.getHubLabel}
              style={{
                width: "280px"
              }}
            />
          );
        } else if (filter == "country_filter") {
          country_dom = (
            <CAutoComplete
              placeholder={intl.formatMessage({
                id: "country_select_label",
                defaultMessage: "Country"
              })}
              isAsync={true}
              onChange={this.changeCountry}
              loadOptions={this.loadCountries}
              allowCreate={false}
              isMulti={true}
              valueKey="country"
              labelKey="country"
              getOptionLabel={this.getCountryLabel}
              style={{
                width: "280px"
              }}
            />
          );
        }
      });
    }

    let orderStatusSuggestions = this.props.isSoftLive ? this.state.orderStatusSuggestionsSoftLive : this.state.orderStatusSuggestionsHardLive;
    let filtersDom = (
      <>
        <div className={classes.root}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <CAutoComplete
              onChange={this.changeOrderStatus}
              placeholder={intl.formatMessage({
                id: "job_status_label",
                defaultMessage: "Job Status"
              })}
              options={orderStatusSuggestions}
              isMulti={true}
              allowCreate={true}
              valueKey="id"
              labelKey="status"
              defaultSelectedValue={this.state.selections.statuses}
              style={{
                width: "280px"
              }}
            />
            {crs_id_dom}
            {city_dom}
            {vendor_dom}
            {hub_dom}
            {country_dom}
          </div>
        </div>
      </>
    );

    return filtersDom;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};
const filterWithStyles = withStyles(styles)(Filter);
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(filterWithStyles)
);

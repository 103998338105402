import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import "./Pagination.css";

const styles = {
  button: {
    width: 45,
    height: 45,
    padding: 0,
    fontSize: 12,
    fontWeight: "bold",
    borderRadius: 10,
    backgroundColor: "white"
  }
};

const Pagination = props => {
  if (
    props.data === null ||
    Number(props.data.totalPages) === 1 ||
    Number(props.data.totalPages) === 0
  )
    return null;

  let isStartDisabled = props.data.currentPage === "1";
  let isEndDisabled = props.data.currentPage === props.data.totalPages;

  return (
    <nav aria-label="navigation">
      <div className="row">
        <ul className="pagination center">
          <div>
            <li>
              <Tooltip id="tooltip-icon" title="First Page" placement="bottom">
                <div>
                  <IconButton
                    style={styles.button}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isStartDisabled}
                    onClick={() => props.onClick(1)}
                  >
                    <i
                      className="fa fa-angle-double-left"
                      aria-hidden="true"
                    ></i>
                  </IconButton>
                </div>
              </Tooltip>
            </li>
          </div>
          <div>
            <li>
              <Tooltip
                id="tooltip-icon"
                title="Previous Page"
                placement="bottom"
              >
                <div>
                  <IconButton
                    style={styles.button}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={isStartDisabled}
                    onClick={() => props.onClick(false)}
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </IconButton>
                </div>
              </Tooltip>
            </li>
          </div>

          <div style={{ overflowX: "scroll", overflowY: "hidden" }}>
            {getPages(props.linksCount, props.data, props.onClick)}
          </div>

          <div>
            <li>
              <Tooltip id="tooltip-bottom" title="Next Page" placement="bottom">
                <div>
                  <IconButton
                    style={styles.button}
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isEndDisabled}
                    onClick={() => props.onClick(true)}
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </IconButton>
                </div>
              </Tooltip>
            </li>
          </div>
          <div>
            <li>
              <Tooltip id="tooltip-bottom" title="Last Page" placement="bottom">
                <div>
                  <IconButton
                    style={styles.button}
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isEndDisabled}
                    onClick={() => props.onClick(props.data.totalPages)}
                  >
                    <i
                      className="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </IconButton>
                </div>
              </Tooltip>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  );
};

const getPages = (linksCount, paginationData, onClick) => {
  let start, end;
  linksCount = Number(linksCount);
  const totalPages = Number(paginationData.totalPages);
  const currentPage = Number(paginationData.currentPage);
  
  // logic to get first page and last page
  if (totalPages > linksCount) {
    start = currentPage - Math.floor(linksCount / 2);
    end = start + linksCount - 1;
    if (end > totalPages) {
      end = totalPages;
      start = end - linksCount + 1;
    }
    if (start < 1) {
      start = 1;
      end = start + linksCount - 1;
    }
  } else {
    start = 1;
    end = totalPages;
  }

  let pages = [];
  while (start <= end) {
    let classes = start === currentPage ? "page-item active" : "page-item";
    pages.push(
      (start => {
        return (
          <li key={start}>
            <IconButton
              style={styles.button}
              variant="contained"
              size="small"
              color="primary"
              className={classes}
              onClick={() => onClick(start)}
            >
              {start}
            </IconButton>
          </li>
        );
      })(start++)
    );
  }
  return pages;
};

export default Pagination;

import { COUNTRY_NAMES } from '../Constants/GenericConstants';

//Used to switch locale from settings(User Info)
export default [{
  key: "en",
  caption: "English"
}, {
  key: "id",
  caption: "Indonesian"
}];

export const validLocales = ["en", "id"];

//TODO: Correct local values
export const LOCALE_CODES = {
  [COUNTRY_NAMES.INDIA]     : "en",
  [COUNTRY_NAMES.INDONESIA] : "id"
};

import React, { Component } from "react";
import "./captions.css";
class Caption1 extends Component {
  render() {
    return (
      <div className="caption-main-container">
        <h6>Ashendra Gour</h6>
        <span >Photography Partner for 3 years</span>
        <p>
          <i>
            "OYO Frames has really simplified my life. I no longer need to
            follow up over calls and WhatsApp and the photoshoots happen
            seamlessly."
          </i>
        </p>
      </div>
    );
  }
}

class Caption2 extends Component {
  render() {
    return (
      <div className="caption-main-container">
        <h6>Aman Seth</h6>
        <span>OYO partner for 2 years</span>
        <p>
          <i>
            "Frames has brought about a lot of transparency in the photography
            process and has empowered us to manage the process independently.
            This has helped us close photoshoot requests much faster and has led
            to simplified payment processes as well."
          </i>
        </p>
      </div>
    );
  }
}
class Caption3 extends Component {
  render() {
    return (
      <div className="caption-main-container">
        <h6>Sandeep Dharmani</h6>
        <span>Photography partner for 4 years</span>
        <p>
          <i>
            "Frames have completely transformed how we do photoshoots at OYO. It
            has simplified communications across multiple teams and brought
            transparency in the process. Congratulations to OYO on such a great
            initiative."
          </i>
        </p>
      </div>
    );
  }
}
class Caption4 extends Component {
  render() {
    return (
      <div className="caption-main-container">
        <h6>Atif Hussain</h6>
        <span>Photography partner for 5 years</span>
        <p>
          <i>
            "Frames has revolutionized how photography was being done at OYO.
            The whole process is now very simple and the communication across
            the chain happens seamlessly."
          </i>
        </p>
      </div>
    );
  }
}

class Caption5 extends Component {
  render() {
    return (
      <div className="caption-main-container">
        <h6>Ravi Sahi</h6>
        <span>Photography partner for 2 years</span>
        <p>
          <i>
            "The OYO team has done great work in building OYO Frames. I’m
            excited to be part of the Frames partner network and look forward to
            further developments in this space."
          </i>
        </p>
      </div>
    );
  }
}
class Caption6 extends Component {
  render() {
    return (
      <div className="caption-main-container">
        <h6>Abhishek Kirti</h6>
        <span>OYO partner since 1 year</span>
        <p>
          <i>
            "Frames is awesome! It has improved our efficiency in a surprising
            way and its AI engine has helped improve the quality of our
            deliverables. We are thrilled and excited to be part of the OYO
            Frames network!"
          </i>
        </p>
      </div>
    );
  }
}

export { Caption1, Caption2, Caption3, Caption4, Caption5, Caption6 };


export { default as GalaryGroupImage } from "./galaryGroupImages.svg";

export { default as Img01 } from "./01.jpg";
export { default as Img02 } from "./02.jpg";
export { default as Img03 } from "./03.jpg";
export { default as Img04 } from "./04.jpg";
export { default as Img05 } from "./05.jpg";
export { default as Img06 } from "./06.jpg";
export { default as Img07 } from "./07.jpg";
export { default as Img08 } from "./08.jpg";
export { default as Img09 } from "./09.jpg";
export { default as Img10 } from "./10.jpg";
export { default as Img11 } from "./11.jpg";
export { default as Img12 } from "./12.jpg";
export { default as Img13 } from "./13.jpg";
export { default as Img14 } from "./14.jpg";
export { default as Img15 } from "./15.jpg";
export { default as Img16 } from "./16.jpg";
export { default as Img17 } from "./17.jpg";
export { default as Img18 } from "./18.jpg";
export { default as Img19 } from "./19.jpg";
export { default as Img20 } from "./20.jpg";
export { default as Img21 } from "./21.jpg";
export { default as Img22 } from "./22.jpg";
export { default as Img23 } from "./23.jpg";
export { default as Img24 } from "./24.jpg";
export { default as Img25 } from "./25.jpg";

export { default as Req1 } from "./Req1.png";
export { default as Req2 } from "./Req2.png";
export { default as Req3 } from "./Req3.png";
export { default as Req4 } from "./Req4.png";

export { default as Vendor1 } from "./Vendor1.jpg";
export { default as Vendor2 } from "./Vendor2.jpg";
export { default as Vendor3 } from "./Vendor3.jpg";
export { default as Vendor4 } from "./Vendor4.jpg";
export { default as Vendor5 } from "./Vendor5.jpg";
export { default as Vendor6 } from "./Vendor6.jpg";

export { default as Vector1 } from "./Vector1.svg";
export { default as Vector10 } from "./Vector10.svg";

export { default as FooterDom } from "./FooterDom.svg";

export { default as GalaryLPortionImage } from "./GalaryLPortionImage.svg";
export { default as GalaryRPortionImage } from "./GalaryRPortionImage.svg";

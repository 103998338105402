import React, { Component } from "react";
import axios from "./../../Axios/OyoStudioWebServiceInstance";
import CircularProgress from "@material-ui/core/CircularProgress";
import BMF from "browser-md5-file";
import { withStyles } from "@material-ui/core";
import DataComponent from "../Photography/Jobs/AllJobs/JobsInfo/DataComponent";
import { FaTimes } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import { Carousel } from 'react-responsive-carousel';
import Carousel from "react-bootstrap/Carousel";
import Swal from "sweetalert2";
import { FormattedMessage, injectIntl } from "react-intl";
import { generateRandomId } from "../../Utils/generateRandomId";
import imageValidate from "./imageValidate";
import { IMAGE_CONSTANTS } from '../../Constants/index'
var CryptoJS = require("crypto-js");
const styles = theme => ({
  summary: {
    "& div": {
      maxWidth: "100%"
    },
    minHeight: "64px"
  },
  text: {
    height: "100%",
    overflowWrap: "break-word"
  },
  details: {
    display: "block"
  },
  columnHeader: {
    color: "rgba(0,0,0,0.54)",
    fontSize: "14px"
  },
  columnValue: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  columnLightValue: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "15px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  columnLightValueMaroon: {
    color: "#a70909",
    fontSize: "16px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550",
    paddingLeft: "20px"
  },
  columnLightValueGreen: {
    color: "green",
    fontSize: "13px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550",
    paddingLeft: "20px"
  },
  evenPanels: {
    minHeight: "64px"
  },
  oddPanels: {
    backgroundColor: "#FAFAFA",
    minHeight: "64px"
  },
  headerPanel: {
    backgroundColor: "#FAFAFA",
    borderRadius: "8px 8px 0 0",
    "&::before": {
      display: "none"
    }
  },
  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    backgroundColor: "rgba(0,0,0, 0.5)",
    zIndex: 1,
    textAlign: "center",
    overflowX: "scroll",
    overflowY: "scroll"
  },
  formField: {
    width: "50%",
    backgroundColor: "white",
    border: "2px solid black",
    marginTop: "10%",
    padding: "25px",
    textAlign: "center",
    borderRadius: "10px",
    overflowX: "scroll",
    overflowY: "scroll",
    display: "inline-block"
  },
  cards: {
    margin: "2px",
    padding: "10px",
    color: "black",
    borderRadius: "10px",
    backgroundColor: "#A1C3D1",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle",
    fontWeight: "bold",
    textAlign: "left",
    overflow: "scroll"
  },
  uploadedImage: {
    maxWidth: "500px",
    maxHeight: "500px"
  },
  removeImage: {
    color: "black",
    position: "absolute",
    top: "15px",
    left: "25px",
    cursor: "pointer"
  }
});

class Uploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: 0,
      requirement_id: this.props.requirement.requirement_id,
      requirement_name: this.props.requirement.name,
      job_id: this.props.job_id,
      files: [],
      imagePreviewUrl: [],
      imageIndexMapping: [],
      imageLoader: false,
      uploaderFlag: false
    };
    this._handleImageChange = this._handleImageChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
    this.processImageFiles = this.processImageFiles.bind(this);
  }

  getFileName = fileName => {
    const randomKey = generateRandomId();
    const fileSplit = fileName.split(".");
    if (fileSplit.length === 1) {
      return randomKey;
    }
    const fileFormat = fileSplit[fileSplit.length - 1];
    return `oyo_frame_job_${randomKey}.${fileFormat}`;
  };

  async processImageFiles({ files, imageIndexMapping }) {
    const { intl } = this.props;
    let rejectedImageFiles, imageFiles;
    if (this.props.country && IMAGE_CONSTANTS.countryListForImageValidation.includes(this.props.country)) {
      const result = await imageValidate({
        files: files,
        minWidth: IMAGE_CONSTANTS.minWidth,
        minHeight: IMAGE_CONSTANTS.minHeight,
        intl:intl
      });
      rejectedImageFiles = result.rejectedImageFiles;
      imageFiles = result.imageFiles;
    } else {
      rejectedImageFiles = [];
      imageFiles = files
    }

    const filesLength = imageFiles.length;
    if (rejectedImageFiles.length) {
      Swal.fire({
        position: "center",
        type: "error",
        title: intl.formatMessage({
          id: "image_resolution_error",
          defaultMessage: "image resolution is low"
        }, { count: rejectedImageFiles.length }),
        showConfirmButton: true,
        confirmButtonText: intl.formatMessage({
          id: "confirm_button_default_label",
          defaultMessage: "OK"
        }),
        allowOutsideClick: true

      })
    }

    if (!filesLength) return;
    Swal.fire({
      position: "center",
      title: intl.formatMessage({
        id: "uploading_flag_label",
        defaultMessage: "Uploading..."
      }),
      onOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
      allowOutsideClick: false
    }).then(result => {
      if (result.value) {
        // window.location.reload();
      }
    });
    let successAPICount = 0;
    let jobId = CryptoJS.AES.encrypt(
      JSON.stringify(this.state.job_id),
      "FRAME_UPLOADER_SEC_KEY"
    ).toString();
    let url = "/images/image_upload";
    for (let i = 0; i < imageFiles.length; i++) {
      let file = imageFiles[i];
      const bmf = new BMF();
      const md5Callback = (err, md5) => {
        let file = imageFiles[i];
        let formData = new FormData();
        formData.append("image_file", file);
        let params = {
          job_id: this.state.job_id,
          requirement_id: this.state.requirement_id,
          image_hash: md5
        };
        let self = this;
        axios
          .post(url, formData, { params: params })
          .then(response => {
            let image_url = imageIndexMapping[i];
            if (response.status === 200 && response.data.data) {
              let result = response.data.data;
              let image_status = result.image_status;
              let job_status = result.job_status;
              let requirement_satisfied = job_status.requirement_satisfied;
              let other_requirements = result.other_requirements;
              let accepted_requirements = result.accepted_requirements;
              this.props.onUploadRequirementsCountChange(image_status, 1);
              if (requirement_satisfied) {
                this.props.onUploadImagesRequirementStatusChange(
                  image_status,
                  other_requirements,
                  accepted_requirements
                );
                this.props.onUploadImagesJobComplete(job_status.is_completed);
              }
              self.handleRemoveImage(image_url);
              successAPICount = successAPICount + 1;
              if (filesLength === successAPICount) {
                this.setState({ uploaderFlag: true });
              }
            }
          })
          .catch(() => {
            Swal.fire({
              position: "center",
              type: "error",
              title: intl.formatMessage({
                id: "something_went_wrong_alone_label",
                defaultMessage: "Something went wrong!"
              }),
              allowOutsideClick: () => !Swal.isLoading()
            }).then(() => {
              // window.location.reload()
            });
          });
      };
      bmf.md5(
        file,
        (err, md5) => { md5Callback(err, md5) },
        () => { }
      );
    }


  }

  uploadFile() {
    const { intl } = this.props;
    let files = this.state.files || [];
    let imageIndexMapping = this.state.imageIndexMapping || [];
    if (files.length === 0) {
      Swal.fire({
        position: "center",
        type: "warning",
        title: intl.formatMessage({
          id: "no_file_chosen_label",
          defaultMessage: "No files chosen!!!"
        }),
        showConfirmButton: true,
        confirmButtonText: intl.formatMessage({
          id: "confirm_button_default_label",
          defaultMessage: "OK"
        }),
        allowOutsideClick: () => !Swal.isLoading()
      });
      return;
    }
    
    this.processImageFiles({ files, imageIndexMapping });

  }

  _handleSubmit(e) {
    e.preventDefault();
    this.uploadFile();
  }

  isValidFile(file) {
    if (file.type.match("image/*") == null) {
      return false;
    }
    if (file.name.split(".").length > 2) {
      return false;
    }
    return true;
  }

  _handleImageChange(e) {
    e.preventDefault();
    let files = Array.from(e.target.files);
    for (let index = 0; index < files.length; index++) {
      let reader = new FileReader();
      let file = files[index];
      let file_type = file.type;
      if (!this.isValidFile(file)) {
        files.splice(index, 1);
        index--;
        continue;
      }
      var blob = file.slice(0, file.size, "image/png");
      var newFileObject = new File([blob], file.name, { type: "image/png" });
      let imagePreviewUrl = this.state.imagePreviewUrl || [];
      let imageIndexMapping = this.state.imageIndexMapping || [];
      let new_files = this.state.files || [];
      new_files.push(newFileObject);
      imageIndexMapping.push(newFileObject.name);
      reader.onloadend = () => {
        imagePreviewUrl.push(reader.result);
        this.setState({
          files: new_files,
          imagePreviewUrl: imagePreviewUrl,
          imageIndexMapping: imageIndexMapping
        });
      };
      reader.readAsDataURL(newFileObject);
    }
  }

  handleRemoveImage(image_url) {
    let imagePreviewUrl = this.state.imagePreviewUrl || [];
    let imageIndexMapping = this.state.imageIndexMapping || [];
    let new_files = this.state.files || [];
    let index = imagePreviewUrl.indexOf(image_url);
    new_files.splice(index, 1);
    imageIndexMapping.splice(index, 1);
    imagePreviewUrl.splice(index, 1);
    this.setState({
      files: new_files,
      imagePreviewUrl: imagePreviewUrl,
      imageIndexMapping: imageIndexMapping
    });
  }

  render() {
    const { classes, intl } = this.props;
    if (this.state.uploaderFlag) {
      this.props.onClosePopUp();
      Swal.fire({
        position: "center",
        type: "success",
        title: intl.formatMessage({
          id: "photograps_have_been_uploaded_successfully_label",
          defaultMessage: "Photographs have been uploaded successfully!"
        }),
        showConfirmButton: true,
        confirmButtonText: intl.formatMessage({
          id: "confirm_button_default_label",
          defaultMessage: "OK"
        }),
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.value) {
          window.location.reload();
        }
      });
    }

    let { imagePreviewUrl } = this.state;
    let imageCarousel = null;
    let $imagePreview = null;
    let imagePreview = null;

    if (imagePreviewUrl && imagePreviewUrl.length) {
      // $imagePreview = imagePreviewUrl.map((image_url, index) => (
      //   <div style = {this.imageDiv} key={`key-${index}`} >
      //     <img style={this.imgPreview} src={image_url}  /><br/>{imageLoaderDom}</div>
      // ))
      // imagePreview = imagePreviewUrl.map((image_url, index) => (
      //   <div key={`key-${index}`}>
      //     <img className={classes.uploadedImage} src={image_url} /><br/>
      //     <span className={classes.removeImage} onClick={()=> {this.handleRemoveImage(image_url)} }><FaTimesCircle /></span>
      //   </div>
      // ));
      // imageCarousel = <Carousel showThumbs={false} dynamicHeight={true}>{imagePreview}</Carousel>

      imagePreview = imagePreviewUrl.map((image_url, index) => (
        <Carousel.Item key={`key-${index}`}>
          <img className={classes.uploadedImage} src={image_url} />
          <br />
          <Carousel.Caption>
            <div className="row">
              <div className="col-sm-4"></div>
              <div className="col-sm-4">
                <span
                  className="btn btn-danger"
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={() => {
                    this.handleRemoveImage(image_url);
                  }}
                >
                  <FaTimes />
                </span>
                <br />
              </div>
              <div className="col-sm-4"></div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ));
      imageCarousel = <Carousel>{imagePreview}</Carousel>;
    }

    let headerDom = null;
    let requirementName = null;
    let statusHeader = null;
    let statusContent = null;
    let requirement = this.props.requirement;
    let requirement_counts = this.props.requirement_counts;
    requirementName = (
      <div>
        {requirement.name}
        <span style={{ color: "red", fontSize: "20px" }}>
          <b>{requirement.is_optional ? "" : " *"}</b>
        </span>
      </div>
    );
    if (this.props.requirement.uploaded_count) {
      statusHeader = `${intl.formatMessage({
        id: "uploaded_label",
        defaultMessage: "Uploaded"
      })}: ${requirement_counts.uploaded} | ${intl.formatMessage({
        id: "requirement_label",
        defaultMessage: "Requirement"
      })}: ${requirement.accepeted_min_count}`;
      statusContent = (
        <div>
          <span style={{ color: "green" }}>
            <b>
              {requirement_counts.accepted}{" "}
              <FormattedMessage
                id={"approved_label"}
                defaultMessage={"approved"}
              />
            </b>
          </span>{" "}
          |{" "}
          <span style={{ color: "red" }}>
            <b>
              {requirement_counts.rejected}{" "}
              <FormattedMessage
                id={"rejected_label"}
                defaultMessage={"rejected"}
              />
            </b>
          </span>
        </div>
      );
    } else {
      statusHeader = `${intl.formatMessage({
        id: "required_label",
        defaultMessage: "Required"
      })}`;
      statusContent = `${intl.formatMessage({
        id: "minimum_label",
        defaultMessage: "Minimum"
      })}  ${requirement.accepeted_min_count} ${intl.formatMessage({
        id: "photos_label",
        defaultMessage: "photos"
      })}`;
    }
    headerDom = (
      <div className={classes.cards}>
        <div className="row">
          <div className="col-sm-6">
            <DataComponent
              header={requirement.requirement_type}
              value={requirementName}
            />
          </div>
          <div className="col-sm-6">
            <DataComponent header={statusHeader} value={statusContent} />
          </div>
        </div>
      </div>
    );

    return (
      <div className={classes.root}>
        <div className={classes.formField}>
          <div className="row">
            <div className="col-sm-12">
              <h1>{headerDom}</h1>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">{imageCarousel}</div>
            <div className="col-sm-3"></div>
          </div>

          <br />
          <form onSubmit={this._handleSubmit}>
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6">
                <div className="form-control">
                  <input
                    id="image_upload_file"
                    style={{ textAlign: "left" }}
                    className={"input_upload_file"}
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={this._handleImageChange}
                  />
                </div>
              </div>
              <div className="col-sm-3"></div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this._handleSubmit}
                >
                  <FormattedMessage
                    id={"upload_image_button_label"}
                    defaultMessage={"Upload Image"}
                  />
                </button>
              </div>
              <div className="col-sm-3">
                <button
                  type="cancel"
                  className="btn btn-danger"
                  onClick={this.props.onClosePopUp}
                >
                  <FormattedMessage
                    id={"cancel_button_label"}
                    defaultMessage={"Cancel"}
                  />
                </button>
              </div>
              <div className="col-sm-3"></div>
            </div>
          </form>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default injectIntl(withStyles(styles)(Uploader));

import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import * as Reducers from "./reducers";
import fetchMiddleware from "../Middleware/fetchMiddleware";
import ReduxThunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    auth: Reducers.AuthReducer,
    i18n: Reducers.I18NReducer
});

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(fetchMiddleware),
        applyMiddleware(ReduxThunk)
    )
);

export default store;
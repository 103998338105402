let firstDom = {
    welcome_to_oyo_frame_label                      : "Selamat datang di OYO Frames",
    be_a_part_and_continue_label                    : "Jadilah bagian dari jaringan fotografi terbesar di dunia",
    first_dom_join_the_network_label                : "Bergabunglah dengan jaringan"
}

let secondDom ={
    second_dom_speech_label                         :   "OYO adalah jaringan hotel dengan pertumbuhan tercepat dan terbesar kedua di dunia. Dengan OYO Frames, kami menyambut semua fotografer dan videografer untuk menjadi bagian integral dari perjalanan OYO saat kami skala global. Ini masih"+ "Hari 0" + "di OYO",
    second_dom_name_heading                         :   "RITESH AGARWAL,",
    second_dom_name_post                            :   "CEO OYO"
}


let thirdDom ={
    photoshop_happened_label                        :   "Pemotretan yang terjadi dalam 30 hari terakhir di seluruh jaringan OYO secara global",
    image_clicked_label                             :   "Gambar diklik per hari di OYO",
    new_cities_added_label                          :   "Kota-kota baru ditambahkan ke jejak global OYO bulan ini"
}

let testimonialDom = {
    from_oyo_frame_community_label                  :   "Dari Komunitas OYO Frames",
    number_of_photograph_onboard_label              :   "500+ Fotografer sudah bergabung secara global di OYO Frames"

}

let fifthDomContent = {
    reach_an_audience_of_approximately_label        :   "Jangkau sekitar audiensi",
    million_label                                   :   "1,14 juta",
    viewer_on_daily_basis                           :   " pemirsa setiap hari",
    grow_with_a_company_thats_growing               :   "Tumbuh bersama perusahaan yang sedang tumbuh ",
    annually_label                                  :   " setiap tahun",
    join_frame_and_gain_access_label                :   "Bergabunglah dengan Frames & dapatkan akses ke jejak global OYO dari ",
    cities_label                                    :   " kota",
    work_alongside_photographer_label               :   "Bekerja bersama fotografer terbaik di seluruh dunia"
}

let fifthDom ={
    the_power_of_being_an_label                     :   "Kekuatan menjadi seorang ",
    oyo_framer_label                                :   "\"OYO Framer\"",
    turning_photography_dreams_label                :   "Mengubah mimpi Fotografi menjadi kenyataan",

}

let sixthDom  ={
    we_are_looking_for_partners_label               :   "Kami mencari mitra dengan",
    passion_for_photography_label                   :   "Gemar Fotografi",
    passion_will_always_and_continue_label          :   "Gairah akan selalu membuat Anda mendorong sedikit lebih jauh, dan berusaha untuk menjadi lebih baik daripada Anda sehari sebelumnya",
    intuitive_imaginative_mind_label                :   "Pikiran Intuitif & Imajinatif",
    photography_is_afterall_and_continue_label      :   "Fotografi adalah bentuk seni dan fotografer, seniman",
    proven_experience_label                         :   "Pengalaman Terbukti",
    budget_hotel_make_and_continue_label            :   "Hotel Melati membuat fotografi yang rumit. Menjadi bagian dari OYO Frames jika Anda memahami seluk-beluknya",
    eye_for_details_label                           :   "Eye for Detail",
    even_the_smallest_and_continue_label            :   "Bahkan hal-hal terkecil dapat membuat atau merusak foto",

}


let carouselDom = {
        our_gallery_label   :"Galeri Kami"
}

let mapDom = {
    southasia_largest_hotel_label                               :  "OYO adalah hotel terbesar di Asia Selatan",
    total_cities_label                                          :   "Kota",
    exclusive_hotel_label                                       :   "Hotel Eksklusif",
    exclusive_rooms_label                                       :   "Kamar Eksklusif"
}

export default {
    ...firstDom,
    ...secondDom,
    ...testimonialDom,
    ...thirdDom,
    ...fifthDom,
    ...fifthDomContent,
    ...sixthDom,
    ...carouselDom,
    ...mapDom
}

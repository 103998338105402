let swalFireIds = {
    new_password_does_not_match_label                                       :"Kata sandi baru tidak cocok dengan kata sandi konfirmasi",
    password_has_successfully_changed_label                                 :"Kata sandi telah berhasil diubah. Login dengan kata sandi baru",
    something_went_wrong_please_mail_at_label                               :"Ada yang salah. Silakan kirim email ke oyoframes@gmail.com",
    enter_all_fields_to_proceed_label                                       :"Masukkan semua bidang untuk melanjutkan",
    cool_button_label                                                       :"Keren",

    thank_you_for_your_interest_in_collaborations_label                     :"Terima kasih atas minat Anda untuk bekerja sama dengan kami. Kami telah menerima permintaan Anda. Kami akan menghubungi Anda secepat mungkin.",
    something_went_wrong_with_try_later_label                               :"Ada yang salah! Silakan coba lagi nanti.",
    confirm_button_bact_to_home_page_label                                  :"Kembali ke Halaman Utama",
    success_label                                                           :"Keberhasilan",
    
    are_you_sure_label                                                      :"Apakah kamu yakin?",
    how_was_the_shoot_label                                                 :"Bagaimana syutingnya?",
    you_wont_be_able_to_revert_this_label                                   :"Anda tidak akan dapat mengembalikan ini!",
    yes_delete_it_button_label                                              :"Ya, hapus!",
    deleted_label                                                           :"Dihapus!",
    your_file_has_been_deleted_label                                        :"File Anda telah dihapus.",


    your_score_is_label                                                     :"nilaimu adalah",
    job_reassigned_successfully_label                                       :"Pekerjaan dipindahkan dengan sukses!",
    something_went_wrong_alone_label                                        :"Ada yang salah!",
    reassign_job_label                                                      :"Tugaskan kembali Pekerjaan",
    alert_you_need_to_provide_reason                                        :"PERINGATAN: Anda harus memberikan alasannya!",
    alert_you_need_to_provide_deadline                                      :"PERINGATAN: Anda harus memberikan batas waktu!",
    no_abort_label                                                          :"Tidak, batalkan!",
    yes_approve_for_payment_label                                           :"Ya, setujui pembayaran!",
    yes_approve_all_images                                                  :"Ya, menyetujui semua Images!",
    yes_mark_payment_done                                                   :"Ya, tandai pembayaran telah selesai!",

    photographer_label                                                      :"Juru potret",
    reassign_to_label                                                       :"Tugaskan kembali ke:",
    provide_deadline_label                                                  :"Berikan batas waktu",
    days_with_capital_label                                                 :"Berhari-hari",
    reason_for_reassignment_label                                           :"Alasan penugasan kembali",

    next_with_capital_label                                                 :"Lanjut",
    congratulations_label                                                   :"Selamat!",
    all_requirements_have_been_passed_label                                 :"Semua persyaratan telah berlalu.",
    kindly_proceed_to_submit_label                                          :"Silakan lanjutkan untuk mengirimkan!",
    ota_google_drive_link_label                                             :"OTA Google Drive Link",
    alert_you_need_to_provide_link_label                                    :"PERINGATAN: Anda harus memberikan tautannya!",
    alert_invalid_link_label                                                :"PERINGATAN: Tautan Tidak Valid",
    editor_declarations_label                                               :"Deklarasi Editor",
    checkbox_minimum_image_count_label                                      :"Saya telah memenuhi jumlah gambar minimum di setiap kategori",
    checkbox_raw_images_received_label                                      :"Gambar mentah yang diterima untuk properti ini telah diedit dan ditempatkan di ember MRC yang benar",
    checkbox_no_objectionable_content_label                                 :"Tidak ada konten yang tidak pantas dalam gambar yang diunggah",
    checkbox_no_duplicate_image_label                                       :"Tidak ada gambar rangkap",
    checkbox_images_uploaded_in_correct_dim_label                           :"Gambar yang diunggah berada dalam dimensi dan resolusi yang benar",
    confirm_submit_button_label                                             :"Kirimkan",
    alert_you_need_to_agree_to_terms_label                                  :"PERINGATAN: Anda harus menyetujui ketentuan!",
    photographs_submitted_successfully_label                                :"Foto-foto telah berhasil dikirim!",

    oops_requirement_not_passed_label                                       :"Ups !! Persyaratan tidak lulus!",
    all_requirement_must_proceed_label                                      :"Semua persyaratan harus dipenuhi untuk melanjutkan.",
    reason_for_partial_submission_label                                     :"Alasan pengajuan sebagian",
    submit_for_partial_approval_button_label                                :"Kirim untuk persetujuan sebagian",
    kindly_submit_after_completing_task_label                               :"Mohon kirimkan setelah menyelesaikan tugas!",

    number_of_unique_images_label                                           :"Jumlah Gambar Unik:",
    additional_notes_label                                                  :"Catatan tambahan",
    photographer_declaration_label                                          :"Deklarasi Fotografer",
    phtoshot_conducted_exposure_property_label                              :"Pemotretan dilakukan dalam 3 paparan dan hanya gambar mentah dari properti ini yang telah dikirimkan dalam tautan",
    send_to_editor_button_label                                             :"Kirim ke Editor",
    send_back_button_label                                                  :"Kirim Kembali",
    link_sent_to_editor_label                                               :"Tautan dikirim ke Editor!",
    invalid_image_count_label                                               :"PERINGATAN: Hitungan Gambar Tidak Valid!",
    confirm_button_default_label                                            :"baik",
    cancel_buttoon_default_label                                            :"Membatalkan",
    send_for_editing_label                                                  :"Kirim untuk Editing",

    rejection_reason_label                                                  :"Alasan Penolakan",
    select_a_reason_label                                                   :"Pilih alasannya",
    shoot_not_done_in_natural_light_label                                   :"Pemotretan tidak dilakukan dalam Cahaya Alami (Tirai Ditutup)",
    shoot_done_using_flash_label                                            :"Pemotretan dilakukan menggunakan Flash",
    less_no_of_images_label                                                 :"Jumlah gambar lebih sedikit",
    image_not_in_exposures_label                                            :"Gambar tidak dalam 3 Eksposur",
    mrc_folder_not_made_by_photographer_label                               :"Folder MRC tidak dibuat oleh fotografer",
    inappropriate_images_light_blottig_label                                :"Gambar yang tidak pantas - Blotting cahaya, Fasad malam, Bracketing, Gambar gelap",
    wrong_property_branding_runner_label                                    :"Branding Properti Salah / Pelari Bantal Branding",
    issues_with_the_raw_drive_link_label                                    :"Masalah dengan tautan Drive mentah",
    mandatory_folders_missing_label                                         :"Folder wajib hilang",
    empty_folders_label                                                     :"Folder kosong",
    other_property_row_link_label                                           :"Tautan mentah properti lainnya",
    paf_tr_filled_incorrectly_label                                         :"PAF / TR salah diisi",
    perspectice_issues_angles_captured_label                                :"Perspektif masalah / Sudut yang salah ditangkap",
    others_label                                                            :"Lainnya",
    please_specify_the_rejection_reasons_label                              :"Silakan tentukan alasan penolakan",
    send_back_to_photographer_button_label                                  :"Kirim Kembali Ke Fotografer",
    alert_please_provide_rejection_reason                                   :"PERINGATAN: Silakan tentukan alasan penolakan!",

    mandate_carts__washroom_facade_reception_missing_label                  :"Gerobak Mandat- Kamar Kecil / Fasad / Penerimaan Hilang",
    mrc_images_missing_in_cart_label                                        :"Gambar MRC Hilang di Keranjang",
    mrc_cart_missing_tech_label                                             :"Keranjang MRC hilang- Tech",
    mrc_pictures_incorrect_tagged_label                                     :"Gambar MRC Salah Ditandai",
    mrc_not_matching_with_transformation_label                              :"MRC Tidak Sesuai Dengan Transformasi",
    facade_name_mismatched_with_property_name_label                         :"Nama Fasad Tidak Cocok dengan Nama Properti",
    duplicate_images_label                                                  :"Gambar Duplikat",
    // images_not_uploaded_on_crs_label                                        :"Gambar Tidak Diunggah di CRS", FIXME: Why?

    deadline_label                                                          :"Batas waktu",
    days_to_complete_the_job_label                                          :"Hari untuk menyelesaikan pekerjaan",
    approve_button_label                                                    :"Menyetujui",
    yes_cancel_job_button_label                                             :"Ya, batalkan pekerjaan!",
    cancellation_reason_label                                               :"Alasan Pembatalan",

    property_not_ready_for_photoshot_label                                  :"Properti tidak siap untuk pemotretan",
    photoshot_resheduled_by_tr_label                                        :"Pemotretan dijadwalkan ulang oleh TR",
    room_category_info_unavailable_label                                    :"Informasi kategori kamar tidak tersedia",
    incorrect_categories_label                                              :"Kategori salah",
    incorrect_amenities_label                                               :"Fasilitas yang Salah",
    incorrect_model_type_label                                              :"Salah Model Tipe / Tipe Properti (SMART, OTH, Capital O, dll)",
    property_readiness_updates_not_come_from_tr_label                       :"Pembaruan kesiapan properti tidak diterima dari TR",
    google_drive_link_for_unready_properties_label                          :"Google Drive Link untuk properti yang belum siap \ gambar",
    alert_provide_link_for_images_unready_properties_label                  :"PERINGATAN: Anda perlu memberikan tautan untuk gambar-gambar properti yang belum siap!",


    accept_the_job_label                                                    :"Terima Pekerjaan itu",
    you_need_to_provide_the_photographer_details_label                      :"Anda harus memberikan rincian fotografer terlebih dahulu!",
    name_of_the_photographer_label                                          :"Nama Fotografer",
    email_of_the_photographer_label                                         :"Surel Fotografer",
    contact_no_of_the_photographer_label                                    :"Nomor Kontak Fotografer",
    alert_need_to_provide_details_label                                     :"PERINGATAN: Anda harus memberikan detailnya!",
    alert_invalid_email_address_label                                       :"PERINGATAN: Alamat Email Tidak Valid!",
    alert_invalid_contact_no_label                                          :"PERINGATAN: Nomor Kontak Tidak Valid!",

    photographs_have_been_synced_to_crs_label                               :"Foto-foto telah disinkronkan ke CRS!",
    photographs_have_been_uploaded_and_synced_to_crs                        :"Foto-foto telah diunggah & disinkronkan ke CRS!",

     photographer_not_available_on_allocated_shoot_date_lable               :"Fotografer tidak tersedia pada tanggal pemotretan yang dialokasikan",
    specific_equipment_not_available_on_shoot_date_label                    :"Peralatan khusus tidak tersedia pada tanggal pemotretan",
    natural_calamity_food_snow_earthquake_label                             :"Bencana alam yaitu Banjir / Salju / Gempa Bumi",
    human_calamity_riots_strike_label                                       :"Bencana Manusia yaitu Kerusuhan / Mogok dll",
    multiple_requests_raised_label                                          :"Beberapa permintaan dimunculkan",
    location_too_far_label                                                  :"Lokasi terlalu jauh",
    shoot_rescheduled_by_bd_label                                           :"Tembak Dijadwalkan Ulang oleh BD",
    property_occupied_or_not_vacant_for_shoots_label                        :"Properti ditempati atau tidak kosong untuk tunas",
    obf_not_realized_label                                                  :"OBF TIDAK TERNYATA",

    image_property_score_less_then_85_label                                 :"Gambar Skor Properti kurang dari 85",
    less_then_no_of_images_label                                            :"Lebih Sedikit Jumlah Gambar",
    images_property_score_and_less_images_label                             :"Gambar Skor Properti kurang dari 85 dan Lebih Sedikit Jumlah Gambar",

    this_job_is_not_assigned_to_you_go_back_job_list_label                  :"Pekerjaan ini tidak ditugaskan untuk Anda. Silakan kembali ke daftar pekerjaan Anda",
    okay_button_label                                                       :"baik",
    photograps_have_been_uploaded_successfully_label                        :"Foto-foto telah berhasil diunggah!",
    score_not_calculated_hit_cal_score_first_label                          :"Skor tidak dihitung. Silakan tekan Calculate Score terlebih dahulu.",
    no_file_chosen_label                                                    :"Tidak ada file yang dipilih !!!",
    uploading_flag_label                                                    :"Mengunggah ...",
















    
}

export default {
    ...swalFireIds
}
import React, {Component} from "react";
import {Typography, withStyles} from "@material-ui/core";
import {FormattedMessage} from 'react-intl';
import Switch from "@material-ui/core/Switch";
import {lightboxStyles} from "react-modal-image/lib/styles";

const styles = _ => ({
  root: {
    display: "flex"
  },
  textField: {
    fontFamily: "Inter",
    color: "#000000",
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "30px",
    marginTop: "22px"
  },
  searchField: {}
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {classes} = this.props;
    let softLiveToggleDom = null;
    if (this.props.softLiveTagVisible) {
      let text = null;
      if (this.props.softLiveTag) {
        text = "Soft Live";
      } else {
        text = "Hard Live";
      }
      softLiveToggleDom = (
          <span style={{fontSize: "15px", fontWeight: "lighter"}}>
          <Switch
              checked={this.props.softLiveTag}
              onChange={this.props.toggleSoftLive}
              name="softLiveTag"
          />{" "}
            {text}
        </span>
      );
    }

    return (
        <div className={classes.root}>
          <div className={classes.headingLabel}>
            <Typography className={classes.textField}>
              <FormattedMessage
                  id={"header_list_of_job_label"}
                  defaultMessage={"List of Jobs"}
              />
              {softLiveToggleDom}
            </Typography>
          </div>
        </div>
    );
  }
}

export default withStyles(styles)(Header);

import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./StaticPages.css";
import * as vectors from "../../Resources/Vectors/index";
import { FormattedMessage } from 'react-intl';
class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaire: [
        {
          id: 0,
          status: false,
          q: <h6>
              <FormattedMessage
                        id={"how_to_join_frame_labes"}
                        defaultMessage={"How to join OYO Frames?"}
                        />
             </h6>,
          a: (
            <p>
              <FormattedMessage
                        id={"click_on_the_label"}
                        defaultMessage={"Click on the"}
                        />
               <b>{" "}
                  <FormattedMessage
                        id={"join_now_label"}
                        defaultMessage={"JOIN NOW"}
                        />
              </b>{" "}
              <FormattedMessage
                        id={"join_frame_with_details_label"}
                        defaultMessage={"button on the homepage. Fill the application with correct information and in as much detail as possible before submitting it. You will be required to submit a google drive link of your portfolio. You will be able to start photographing for OYO only after you have received an invitation from us and have an approved OYO Frames account."}
                        />
            </p>
          )
        },
        {
          id: 1,
          status: false,
          q: <h6>
              <FormattedMessage
                          id={"where_you_can_shoot_question_label"}
                          defaultMessage={"Which all geographies can I shoot in?"}
                          />
            </h6>,
          a: (
            <p>
               <FormattedMessage
                          id={"where_you_can_shoot_question_answer_label"}
                          defaultMessage={"OYO Frames is currently active in India. We’re launching in US and UK very soon."}
                          />
            </p>
          )
        },
        {
          id: 2,
          status: false,
          q: (
            <h6>
              <FormattedMessage
                          id={"guidline_or_rule_question_label"}
                          defaultMessage={"Are there any guidelines or rules to follow while conducting a photoshoot?"}
                          />
            </h6>
          ),
          a: (
            <p>
              <FormattedMessage
                          id={"guidline_or_rule_question_answer_label"}
                          defaultMessage={"Yes, OYO has photography guidelines which one can access post login on the Frames portal."}
                          />
            </p>
          )
        },
        {
          id: 3,
          status: false,
          q: <h6>
            <FormattedMessage
                          id={"selection_criteria_question_label"}
                          defaultMessage={"What is the selection criteria?"}
                          />
              </h6>,
          a: (
            <p>
              <FormattedMessage
                          id={"selection_criteria_question_answer_label_p1"}
                          defaultMessage={"After you’ve submitted your application, our team will check the images and videos submitted and cross-check with references provided, to validate ownership and ensure they meet our partner expectations. Quality will take precedence over quantity, previous experience in the hospitality industry, specially "}
                          />
              <b>
                <FormattedMessage
                          id={"selection_criteria_question_answer_label_p2"}
                          defaultMessage={"budget hotels"}
                          />
              </b>
              <FormattedMessage
                          id={"selection_criteria_question_answer_label_p3"}
                          defaultMessage={" will be a definite advantage."}
                          />
            </p>
          )
        },
        {
          id: 4,
          status: false,
          q: (
            <h6>
              <FormattedMessage
                          id={"specific_equipment_question_label"}
                          defaultMessage={" Are there any specific equipment needed to become a photography partner at OYO?"}
                          />
            </h6>
          ),
          a: (
            <p>
              <FormattedMessage
                          id={"oyo_require_photographer_have_label"}
                          defaultMessage={"OYO requires photographer to have:"}
                          />
              <ul>
                <li>
                  <FormattedMessage
                          id={"camera_details_label"}
                          defaultMessage={"A minimum 20 megapixels camera."}
                          />
                  </li>
                <li>
                  <FormattedMessage
                          id={"kind_of_lenses_label"}
                          defaultMessage={"Two kinds of lenses:"}
                          />
                  </li>
                <ul>
                  <li>
                    <FormattedMessage
                          id={"first_kind_of_lense"}
                          defaultMessage={"16mm to 40mm"}
                          />
                    </li>
                  <li>
                    <FormattedMessage
                          id={"second_kind_of_lense"}
                          defaultMessage={"24mm to 105mm"}
                          />
                    </li>
                </ul>
                <li>
                  <FormattedMessage
                          id={"flashlight_label"}
                          defaultMessage={"Flashlights"}
                          />
                  </li>
                <li>
                  <FormattedMessage
                          id={"tripod_label"}
                          defaultMessage={"Tripod"}
                          />
                  </li>
              </ul>
            </p>
          )
        }
      ]
    };
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(identifier) {
    let temp = this.state.questionnaire;
    temp[identifier].status = !temp[identifier].status;
    this.setState({ temp });
  }

  render() {
    let plus = <span className="plus">+</span>;
    let minus = <span className="minus">-</span>;

    let faqs = this.state.questionnaire.map(item => {
      return (
        <div className="faqs-questions-container">
          <div onClick={() => this.onToggle(item.id)}>
            {item.status ? minus : plus}
          </div>
          <div className="faqs-questions-answer-box">
            {item.q}
            {item.status && item.a}
          </div>
        </div>
      );
    });

    let faqDom = (
          <div className="faqs-main-outer-container">
                      <div className="faqs-dom-container">
                  <div className="faqs-header-image-container">
                    <img className="desktop-faqs-header-image"
                        src={vectors.staticBanner}
                      />
                    <img  className="mobile-faqs-header-image"
                        src={vectors.staticBannerMobile}
                      />
                      <h3>
                          <FormattedMessage
                                id={"frequently_asked_questions_label"}
                                defaultMessage={"Frequently Asked Questions"}
                                />
                      </h3>
                  </div>
                
                  <div className="faqs-content-container">
                    
                    <div className="faqs-left-content">
                          <h5>
                            <FormattedMessage
                                id={"any_questions_label"}
                                defaultMessage={"Any Questions?"}
                                />
                            <br className="mweb-faqs-header-line-broker"/>
                            <FormattedMessage
                                id={"we_are_here_to_help_label"}
                                defaultMessage={"We are here to help"}
                                />
                          </h5>
                          <Link
                          to={`/contact`}
                          activeClassName="active"
                          style={{ textDecoration: "none" , display:"contents" }}
                          >
                            <div className="faqs-contact-us-button-container">
                                <span>
                                  <FormattedMessage
                                    id={"contact_us_label"}
                                    defaultMessage={"Contact us"}
                                    />
                                </span>
                            </div>
                          </Link>
                    </div>
                    
                    <div className="faqs-right-content">
                      {faqs}
                    </div>

                  </div>
                </div>
            </div>
   
    );
    return faqDom;
  }
}

export default FAQs;

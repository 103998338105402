import React, { Component, Fragment } from "react";
// import JobsInfo from '../Photography/Jobs/AllJobs/JobsInfo/JobsInfo'
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import Requirement from "../../../Photography/Jobs/JobData/Requirement/Requirement";
import ImageStudio from "../../../ImageStudio/ImageStudio";
import DataComponent from "../../../Photography/Jobs/AllJobs/JobsInfo/DataComponent";
import {
  FaEye,
  FaPlusCircle,
  FaCheckCircle,
  FaExclamationTriangle
} from "react-icons/fa";
import red from "@material-ui/core/colors/red";
import Uploader from "../../../S3/Uploader";
import ImageViewer from "../../../S3/ImageViewer";
import Swal from "sweetalert2";
import { FormattedMessage ,injectIntl } from 'react-intl';

const styles = theme => ({
  summary: {
    "& div": {
      maxWidth: "100%"
    },
    minHeight: "64px"
  },
  text: {
    height: "100%",
    overflowWrap: "break-word"
  },
  details: {
    display: "block"
  },
  columnHeader: {
    color: "rgba(0,0,0,0.54)",
    fontSize: "14px"
  },
  columnValue: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  columnLightValue: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "13px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550"
  },
  columnLightValueRed: {
    color: "red",
    fontSize: "13px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550",
    paddingLeft: "20px"
  },
  columnLightValueGreen: {
    color: "green",
    fontSize: "13px",
    height: "100%",
    overflowWrap: "break-word",
    fontWeight: "550",
    paddingLeft: "20px"
  },
  evenPanels: {
    minHeight: "64px"
  },
  oddPanels: {
    backgroundColor: "#FAFAFA",
    minHeight: "64px"
  },
  headerPanel: {
    backgroundColor: "#FAFAFA",
    borderRadius: "8px 8px 0 0",
    "&::before": {
      display: "none"
    }
  },
  cards: {
    margin: "10px",
    padding: "10px",
    color: "black",
    borderRadius: "10px",
    backgroundColor: "#DEF2F1",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle",
    fontWeight: "bold",
    textAlign: "left",
    overflow: "scroll"
  },
  viewIcon: {
    fontSize: "25px",
    fontWeight: "bold",
    color: "#326fa8",
    textAlign: "center",
    verticalAlign: "middle",
    marginTop: "2px",
    cursor: "pointer"
  },
  addIcon: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#32a83c",
    textAlign: "center",
    verticalAlign: "middle",
    marginTop: "7px",
    cursor: "pointer"
  },
  statusIcon: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "center",
    verticalAlign: "middle",
    marginTop: "2px"
  }
});

class OneRequirement extends Component {
  state = {
    job_id: this.props.job_id,
    expandedIndex: null,
    requirement_status: this.props.requirement.requirement_status,
    showAddImagesPopUp: false,
    showViewImagesPopUp: false,
    uploadedImagesCount: this.props.requirement.uploaded_count,
    acceptedImagesCount: this.props.requirement.accepted_images_count,
    rejectedImagesCount: this.props.requirement.rejected_images_count
  };

  onUploadRequirementsCountChange = (status, value) => {
    let upCount = this.state.uploadedImagesCount;
    let accCount = this.state.acceptedImagesCount;
    let rejCount = this.state.rejectedImagesCount;
    if (status === "Accepted") {
      this.setState({
        uploadedImagesCount: upCount + value,
        acceptedImagesCount: accCount + value
      });
    } else if (status === "Not Accepted") {
      this.setState({
        uploadedImagesCount: upCount + value,
        rejectedImagesCount: rejCount + value
      });
    }
    this.props.onUploadTotalRequirementsCountChange(status, value);
  };

  onUploadImagesRequirementStatusChange = (
    requirement_status,
    requirements_left,
    requirements_completed
  ) => {
    if (
      requirement_status &&
      requirement_status != this.state.requirement_status
    ) {
      this.setState({
        requirement_status: requirement_status
      });
      this.props.onUploadImagesRequirementChange(
        requirements_left,
        requirements_completed
      );
    }
  };

  changeExpandIndex = index => () => {
    this.setState({
      expandedIndex: index !== this.state.expandedIndex ? index : null
    });
  };

  componentWillReceiveProps = nextProps => {
    if (
      !_.isEqual(this.props.orders, nextProps.orders) &&
      this.state.expandedIndex != null
    ) {
      this.setState({
        expandedIndex: null
      });
    }
  };

  openImagePage = image_grid_url => {
    window.open(image_grid_url, "_blank");
  };

  onAddImage = () => {
    this.setState({ showAddImagesPopUp: true });
  };
  onViewImage = () => {
    this.setState({ showViewImagesPopUp: true });
  };

  onClosePopUp = () => {
    this.setState({ showAddImagesPopUp: false });
  };

  onCloseImageViewerPopUp = () => {
    this.setState({ showViewImagesPopUp: false });
  };

  render() {
    const { classes ,intl} = this.props;
    // const tableHeaders = this.props.headers;
    let index = this.props.index;
    let className = index % 2 !== 0 ? classes.oddPanels : classes.evenPanels;
    let requirement = this.props.requirement;
    let image_grid_url =
      "/jobDetails" +
      "/imageGrid/" +
      this.state.job_id +
      "/" +
      requirement.requirement_id +
      "/" +
      requirement.name;
    let contentDom = null;
    let cardStatusDom = null;
    let requirementName = null;
    let statusHeader = null;
    let statusContent = null;
    requirementName = (
      <div>
        {requirement.name}
        <span style={{ color: "red", fontSize: "20px" }}>
          <b>{requirement.is_optional ? "" : " *"}</b>
        </span>
      </div>
    );
    if (this.state.uploadedImagesCount) {
      statusHeader = `${intl.formatMessage({
        id: "uploaded_label",
        defaultMessage: "Uploaded"
      })}: ${this.state.uploadedImagesCount} | ${intl.formatMessage({
        id: "requirement_label",
        defaultMessage: "Requirement"
      })}: ${requirement.accepeted_min_count}`;
      statusContent = (
        <div>
          <span style={{ color: "green" }}>
            <b>
              {this.state.acceptedImagesCount}{" "}
              <FormattedMessage
                id={"approved_label"}
                defaultMessage={"approved"}
              />
            </b>
          </span>{" "}
          |{" "}
          <span style={{ color: "red" }}>
            <b>
              {this.state.rejectedImagesCount}{" "}
              <FormattedMessage
                id={"rejected_label"}
                defaultMessage={"rejected"}
              />
            </b>
          </span>
        </div>
      );
    } else {
      statusHeader = `${intl.formatMessage({
        id: "required_label",
        defaultMessage: "Required"
      })}`;
      statusContent = `${intl.formatMessage({
        id: "minimum_label",
        defaultMessage: "Minimum"
      })}  ${requirement.accepeted_min_count} ${intl.formatMessage({
        id: "photos_label",
        defaultMessage: "photos"
      })}`;
    }
    if (this.state.requirement_status === "Accepted") {
      cardStatusDom = (
        <span style={{ color: "green" }} className={classes.statusIcon}>
          <b>
            <FaCheckCircle />
          </b>
        </span>
      );
    } else {
      cardStatusDom = (
        <span style={{ color: "red" }} className={classes.statusIcon}>
          <b>
            <FaExclamationTriangle />
          </b>
        </span>
      );
    }

    contentDom = (
      <div className={classes.cards}>
        <div className="row">
          <div className="col-sm-4">
            <DataComponent
              header={requirement.requirement_type}
              value={requirementName}
            />
          </div>
          <div className="col-sm-5">
            <DataComponent header={statusHeader} value={statusContent} />
          </div>
          <div className="col-sm-1">{cardStatusDom}</div>
          <div className="col-sm-1">
            <div className={classes.viewIcon}>
              <FaEye onClick={() => this.onViewImage()} />
            </div>
          </div>
          <div className="col-sm-1">
            <div className={classes.addIcon}>
              <FaPlusCircle onClick={() => this.onAddImage()} />
            </div>
          </div>
        </div>
      </div>
    );
    let requirement_counts = {
      uploaded: this.state.uploadedImagesCount,
      accepted: this.state.acceptedImagesCount,
      rejected: this.state.rejectedImagesCount
    };

    return (
      <div>
        {contentDom}
        {this.state.showAddImagesPopUp && (
          <Uploader
            requirement={requirement}
            job_id={this.props.job_id}
            onClosePopUp={this.onClosePopUp}
            onUploadImagesRequirementStatusChange={
              this.onUploadImagesRequirementStatusChange
            }
            onUploadRequirementsCountChange={
              this.onUploadRequirementsCountChange
            }
            requirement_counts={requirement_counts}
            onUploadImagesJobComplete={this.props.onUploadImagesJobComplete}
            country={this.props.country}
          />
        )}
        {this.state.showViewImagesPopUp && (
          <ImageViewer
            requirement={requirement}
            job_id={this.props.job_id}
            onClosePopUp={this.onCloseImageViewerPopUp}
            onDeleteImageRequirementStatusChange={
              this.onUploadImagesRequirementStatusChange
            }
            onUploadRequirementsCountChange={
              this.onUploadRequirementsCountChange
            }
            requirement_counts={requirement_counts}
          />
        )}
      </div>
      // <Fragment>
      //   <ExpansionPanel
      //     key={index}
      //     CollapseProps={{ unmountOnExit: true }}
      //     // expanded={index===this.state.expandedIndex}
      //     className={className}
      //     onChange={this.changeExpandIndex(index)}
      //   >
      //     <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
      //       <div style={{display: 'flex', width : "100%"}}>
      //           <div style={{width : "30%"}}>
      //              <Typography className={classes.columnValue} key={'name'- index}>
      //                  {requirement.name}
      //                  {requirement.is_optional ? '(Optional)' : null}
      //              </Typography>
      //           </div>

      //           <div style={{display: 'flex', width : "30%"}}>
      //               <Typography className={classes.columnLightValue} key={'requirement'- index}>
      //                   Minimum Requirement :-
      //               </Typography>
      //               <Typography className={classes.columnLightValueRed} key={index}>
      //                  {requirement.accepeted_min_count}
      //              </Typography>
      //           </div>
      //           <div style={{width : "30%"}}>
      //               <Typography className={this.state.requirement_status == 'Accepted' ? classes.columnLightValueGreen: classes.columnLightValueRed} key={index}>
      //                  {this.state.requirement_status}
      //              </Typography>
      //           </div>
      //       </div>
      //     </ExpansionPanelSummary>
      //     <ExpansionPanelDetails className={classes.details}>
      //       <ImageStudio requirement_id = {requirement.requirement_id} requirement_name = {requirement.name} job_id = {this.props.job_id} onUploadImagesRequirementStatusChange ={this.onUploadImagesRequirementStatusChange}/>
      //       {/* <JobsInfo jobInfo={jobInfo} /> */}
      //     </ExpansionPanelDetails>
      //   </ExpansionPanel>
      // </Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(OneRequirement));

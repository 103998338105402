import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import "./Requirement.css";
import {
  FaAngleDown,
  FaAngleRight,
  FaHandPointRight,
  FaBars,
  FaTimes
} from "react-icons/fa";
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  root: {
    margin: "10px",
    padding: "10px",
    borderWidth: "2px",
    borderRadius: "10px",
    backgroundColor: "white"
  },
  cards: {
    margin: "10px",
    padding: "10px",
    color: "black",
    borderWidth: "2px",
    borderRadius: "10px",
    backgroundColor: "#DEF2F1",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle",
    overflow: "scroll"
  },
  cardsHeader: {
    margin: "10px",
    padding: "10px",
    color: "black",
    borderBottom: "solid",
    borderWidth: "2px",
    borderColor: "black",
    borderRadius: "10px",
    backgroundColor: "#8C8C8C",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle",
    overflow: "scroll"
  },
  requirementsHeader: {
    margin: "10px",
    padding: "10px",
    color: "white",
    borderRadius: "10px",
    backgroundColor: "black",
    fontSize: "25px",
    position: "relative",
    verticalAlign: "middle",
    overflow: "scroll"
  },
  labelHeader: {
    margin: "10px",
    padding: "10px",
    color: "black",
    borderRadius: "10px",
    backgroundColor: "#A1C3D1",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle",
    fontWeight: "bold",
    overflow: "scroll"
  },
  noRequirements: {
    fontSize: "10em",
    fontFamily: "Apple Color Emoji",
    display: "inline-block",
    textAlign: "center",
    overflow: "scroll"
  }
});

class requirement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPropertyAmenitiesRequirements: false,
      showViewAmenitiesRequirements: false,
      showRoomAmenitiesRequirements: false,
      showCategoryAmenitiesRequirements: false
    };
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(identifier) {
    switch (identifier) {
      case "PropertyAmenities":
        this.setState({
          showPropertyAmenitiesRequirements: !this.state
            .showPropertyAmenitiesRequirements
        });
        break;
      case "ViewAmenities":
        this.setState({
          showViewAmenitiesRequirements: !this.state
            .showViewAmenitiesRequirements
        });
        break;
      case "CategoryAmenities":
        this.setState({
          showCategoryAmenitiesRequirements: !this.state
            .showCategoryAmenitiesRequirements
        });
        break;
      case "RoomAmenities":
        this.setState({
          showRoomAmenitiesRequirements: !this.state
            .showRoomAmenitiesRequirements
        });
        break;
      case "CollapseAll":
        if (
          this.state.showCategoryAmenitiesRequirements ||
          this.state.showPropertyAmenitiesRequirements ||
          this.state.showRoomAmenitiesRequirements ||
          this.state.showViewAmenitiesRequirements
        ) {
          this.setState({
            showCategoryAmenitiesRequirements: false,
            showPropertyAmenitiesRequirements: false,
            showViewAmenitiesRequirements: false,
            showRoomAmenitiesRequirements: false
          });
        } else {
          this.setState({
            showCategoryAmenitiesRequirements: true,
            showPropertyAmenitiesRequirements: true,
            showViewAmenitiesRequirements: true,
            showRoomAmenitiesRequirements: true
          });
        }
        break;
    }
  }

  render() {
    const { classes } = this.props;
    let titleDom = null;
    let requirementsHeaderDom = null;
    let propertyAmenitiesDom = null;
    let categoryAmenitiesDom = null;
    let viewAmenitiesDom = null;
    let roomAmenitiesDom = null;
    let requirementsDom = null;
    let BarsOrCross =
      this.state.showCategoryAmenitiesRequirements ||
      this.state.showPropertyAmenitiesRequirements ||
      this.state.showRoomAmenitiesRequirements ||
      this.state.showViewAmenitiesRequirements ? (
        <FaTimes />
      ) : (
        <FaBars />
      );

    let requirements = this.props.requirements;
    let property_level_requirements = [];
    let room_level_requirements = [];
    let category_level_requirements = [];
    let view_level_requirements = [];
    if (requirements && requirements.length) {
      requirements.forEach(requirement => {
        if (requirement.requirement_type == "Property Amenity") {
          property_level_requirements.push(requirement);
        } else if (requirement.requirement_type == "Room Amenity") {
          room_level_requirements.push(requirement);
        } else if (requirement.requirement_type == "Room Category") {
          category_level_requirements.push(requirement);
        } else if (requirement.requirement_type == "View Category") {
          view_level_requirements.push(requirement);
        }
      });
    }
    titleDom = (
      <>
        <div className={classes.requirementsHeader}>
          <div className="row">
            <div
              className="col-sm-1"
              onClick={() => {
                this.onToggle("CollapseAll");
              }}
            >
              {BarsOrCross}
            </div>
            <div className="col-sm-10">
              <b>
                 <FormattedMessage
                        id={"requirements_label"}
                        defaultMessage={"REQUIREMENTS"}
                        /></b>
            </div>
            <div className="col-sm-1"></div>
          </div>
        </div>
      </>
    );

    requirementsHeaderDom = (
      <>
        <div className={classes.labelHeader}>
          <div className="row">
            <div className="col-sm-6" style={{ textAlign: "left" }}>
              <FormattedMessage
                        id={"name_label"}
                        defaultMessage={"Name"}
                        /> 
            </div>
            <div className="col-sm-2">
               <FormattedMessage
                        id={"type_label"}
                        defaultMessage={"Type"}
                        /></div>
            <div className="col-sm-4">
               <FormattedMessage
                        id={"minimum_image_required_label"}
                        defaultMessage={"Minimum Images Required"}
                        /></div>
          </div>
        </div>
      </>
    );

    if (property_level_requirements && property_level_requirements.length) {
      let property_level_list = property_level_requirements.map(requirement => (
        <div className={classes.cards}>
          <div className="row">
            <div className="col-sm-6" style={{ textAlign: "left" }}>
              <b>{requirement.name}</b>
            </div>
            <div className="col-sm-2">
              {requirement.is_optional ? "Optional" : "Mandatory"}
            </div>
            <div className="col-sm-4">{requirement.accepeted_min_count}</div>
          </div>
        </div>
      ));
      propertyAmenitiesDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("PropertyAmenities");
                }}
              >
                <span>
                  {this.state.showPropertyAmenitiesRequirements ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b> 
                   <FormattedMessage
                        id={"property_level_requirement_label"}
                        defaultMessage={"Property Level Requirements"}
                        /></b>
              </div>
            </div>
          </div>
          {this.state.showPropertyAmenitiesRequirements &&
            requirementsHeaderDom}
          {this.state.showPropertyAmenitiesRequirements && property_level_list}
        </>
      );
    }

    if (category_level_requirements && category_level_requirements.length) {
      let category_level_list = category_level_requirements.map(requirement => (
        <div className={classes.cards}>
          <div className="row">
            <div className="col-sm-6" style={{ textAlign: "left" }}>
              <b>{requirement.name}</b>
            </div>
            <div className="col-sm-2">
              {requirement.is_optional ? "Optional" : "Mandatory"}
            </div>
            <div className="col-sm-4">{requirement.accepeted_min_count}</div>
          </div>
        </div>
      ));
      categoryAmenitiesDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("CategoryAmenities");
                }}
              >
                <span>
                  {this.state.showCategoryAmenitiesRequirements ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                   <FormattedMessage
                        id={"category_level_requirement_label"}
                        defaultMessage={"Category Level Requirements"}
                        />
                        </b>
              </div>
            </div>
          </div>
          {this.state.showCategoryAmenitiesRequirements &&
            requirementsHeaderDom}
          {this.state.showCategoryAmenitiesRequirements && category_level_list}
        </>
      );
    }

    if (view_level_requirements && view_level_requirements.length) {
      let view_level_list = view_level_requirements.map(requirement => (
        <div className={classes.cards}>
          <div className="row">
            <div className="col-sm-6" style={{ textAlign: "left" }}>
              <b>{requirement.name}</b>
            </div>
            <div className="col-sm-2">
              {requirement.is_optional ? "Optional" : "Mandatory"}
            </div>
            <div className="col-sm-4">{requirement.accepeted_min_count}</div>
          </div>
        </div>
      ));
      viewAmenitiesDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("ViewAmenities");
                }}
              >
                <span>
                  {this.state.showViewAmenitiesRequirements ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b> 
                   <FormattedMessage
                        id={"view_level_requirement_label"}
                        defaultMessage={"View Level Requirements"}
                        /></b>
              </div>
            </div>
          </div>
          {this.state.showViewAmenitiesRequirements && requirementsHeaderDom}
          {this.state.showViewAmenitiesRequirements && view_level_list}
        </>
      );
    }

    if (room_level_requirements && room_level_requirements.length) {
      let room_level_list = room_level_requirements.map(requirement => (
        <div className={classes.cards}>
          <div className="row">
            <div className="col-sm-6" style={{ textAlign: "left" }}>
              <b>{requirement.name}</b>
            </div>
            <div className="col-sm-2">
              {requirement.is_optional ? "Optional" : "Mandatory"}
            </div>
            <div className="col-sm-4">{requirement.accepeted_min_count}</div>
          </div>
        </div>
      ));
      roomAmenitiesDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("RoomAmenities");
                }}
              >
                <span>
                  {this.state.showRoomAmenitiesRequirements ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b> 
                   <FormattedMessage
                        id={"room_level_requirement_label"}
                        defaultMessage={"Room Level Requirements"}
                        /></b>
              </div>
            </div>
          </div>
          {this.state.showRoomAmenitiesRequirements && requirementsHeaderDom}
          {this.state.showRoomAmenitiesRequirements && room_level_list}
        </>
      );
    }

    if (this.props.requirements && this.props.requirements.length === 0) {
      requirementsDom = (
        <>
          <span
            aria-label="No requirements in your list"
            role="img"
            className={classes.noRequirements}
          >
            🏠
          </span>
          <p className={this.props.classes.propertyAssigned}>
            <FormattedMessage
                        id={"no_requierment_in_your_list_label"}
                        defaultMessage={"No Requirements in your list"}
                        />
            
          </p>
          <p className={this.props.classes.supportEmail}>
            <FormattedMessage
              id={"mail_at_label"}
              defaultMessage={"Mail at"}
              />
               {" "} <b>admin.frames@oyorooms.com</b> {" "}
                <FormattedMessage
              id={"if_you_are_facing_issue_continue_label"}
              defaultMessage={"if you’re facing an issue"}
              />
          </p>
        </>
      );
    } else {
      requirementsDom = (
        <div>
          {titleDom}
          {propertyAmenitiesDom}
          {categoryAmenitiesDom}
          {viewAmenitiesDom}
          {roomAmenitiesDom}
        </div>
      );
    }

    return <div className={classes.root}>{requirementsDom}</div>;
  }
}

export default withStyles(styles)(requirement);

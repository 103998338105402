import React, { useState } from 'react';
import { withStyles ,Typography } from "@material-ui/core";
import { FaCrown, FaTrophy } from "react-icons/fa";
import StatusBar from '../../../../../Components/Custom/StatusBar';
import { FormattedMessage } from 'react-intl';
const styles = theme => ({
  root: {
    display: "flex",
    marginRight: "50px"
  },
  firstColumn: {
    width: "auto",
    marginTop: "48px",
    marginLeft: "46px"
  },
  secondColumn: {
   marginTop: "70px",
   width: "auto",
   marginLeft: "5px"
  },
  thirdColumn: {
    marginTop: "48px",
    width: "auto",
    marginLeft: "auto"
  },
  jobDetails: {
    fontFamily:"Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "30px",
    letterSpacing: "-0.18px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  image: {
    
  },
  status: {
    background: "rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.6)",
    textAlign: "center"
  },
  date: {
    fontFamily:"Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.54)",
    textAlign: "center",
    marginTop: "12px"
  },
  crown: {
    lineHeight: "30px",
    color: "#F5A623",
    fontSize: "28px",
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: "0.15px"
  },
  score: {
    fontFamily:"Inter",
    lineHeight: "24px",
    color: "#F5A623",
    fontSize: "16px",
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: "0.15px"
  }
});

function JobDetails(props) {
  const { classes } = props;
  let scoreDom = null;
  if (props.jobInfo.job_data.property_score !== null) {
    scoreDom = (
        <div className={classes.secondColumn}>
              <span className={classes.crown}>
                <FaCrown />
              </span>
          <span className={classes.score}>
                {`${props.jobInfo.job_data.property_score}` + "/100"}
              </span>
        </div>
    )
  }
  return (
    <div className={classes.root}>
            <div className={classes.firstColumn}>
                <Typography className={classes.jobDetails}>
                  <FormattedMessage
                        id={"job_details_label"}
                        defaultMessage={"Job Details"}
                        />:</Typography>
                <Typography className={classes.jobDetails}>{props.jobInfo.property_data.property_name}</Typography>
            </div>
            {scoreDom}
            <div className={classes.thirdColumn}>
               <StatusBar status={props.jobInfo.job_data.job_status}/>
               <Typography className={classes.date}>{props.jobInfo.job_data.date}</Typography>
            </div>
      
    </div>
  );
}


export default withStyles(styles)(JobDetails);



import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';
const styles = _ => ({
  root: {
    display: "flex"
  },
  textField: {
    fontFamily: "Inter",
    color: "#000000",
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "30px",
    marginTop: "22px"
  },
  searchField: {}
});

class Header extends Component {
  constructor(props){
    super(props);
    this.state={

    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.headingLabel}>
          <Typography className={classes.textField}>
            <FormattedMessage
              id={"header_mis_reports_payment"}
              defaultMessage={"MIS Reports -  Payment"}
            /></Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Header);

export const AUTH = {
  START: "AUTH_START",
  SUCCESS: "AUTH_SUCCESS",
  FAIL: "AUTH_FAIL",
  LOGOUT: "AUTH_LOGOUT",
  CLEARERROR: "CLEAR_ERROR",
  SET_AUTH_REDIRECT_PATH: "SET_AUTH_REDIRECT_PATH",
  SET_SSO_LOGIN_FLAG     : "SET_SSO_LOGIN_FLAG",
};


export const I18N = {
  CHANGE_LOCALE : "CHANGE_LOCALE"
}

let jobGeneralIds = {
    header_list_of_job_label                    :"Daftar Pekerjaan",
    city_type_label                             :"Kota",
    vendor_code_label                           :"Kode vendor",
    job_status_label                            :"Status pekerjaan"
}

let tabBarIds = { 
   active_task_label                            :"Tugas aktif",
   completed_task_label                         :"Lengkap",
   rejected_task_label                          :"Ditolak/Dibatalkan",
   payment_done_task_label                      :"Pembayaran Disetujui/Dilakukan"
}

let noDataAvailableInTableIds = {
    no_jobs_in_the_list_label                   :"Tidak ada pekerjaan di daftar Anda",
    mail_at_label                               :"Kirim email ke",
    if_you_are_facing_issue_continue_label      :"jika Anda menghadapi masalah"

}

let expansionTableIds = {
    job_id_Label                                :"ID Pekerjaan",
    property_label                              :"Properti",
    address_label                               :"Alamat",
    table_requested_by_label                    :"Diminta oleh",
    shoot_date_label                            :"Tanggal Tembak",
    status_label                                :"Status",
    


}

let jobInfoIds = {
    job_details_label                           :"Rincian pekerjaan",
    property_details_label                      :"Detail Properti",
    oyo_id_label                                :"ID OYO",
    oyo_type_lable                              :"Tipe",
    property_address_label                      :"Alamat Propertis",
    city_type_label                             :"Jenis Kota",
    requirements_label                          :"Persyaratan",
    category_level_label                        :"Tingkat Kategori",
    property_level_label                        :"Tingkat Properti",
    view_lavel_lebel                            :"Lihat Level"

}

let actionsButtonsId ={
    accept_button_label                                 :"Menerima",
    reject_button_label                                 :"Menolak",
    cancel_button_label                                 :"Membatalkan",
    submit_button_label                                 :"Kirimkan",
    send_to_editor_button_label                         :"Kirim ke Editor",
    send_back_to_photographer_button_label              :"Kirim kembali ke Fotografer",
    sync_requirements_button_label                      :"Persyaratan Sinkronisasi",
    mark_as_completed_button_label                      :"Tandai sebagai Selesai",
    approve_partial_completion_request_button_label     :"Menyetujui Permintaan Penyelesaian Sebagian",
    reject_partical_completion_button_label             :"Tolak Permintaan Penyelesaian Sebagian",
    reassign_job_button_label                           :"Tugaskan kembali Pekerjaan",
    approve_for_payment_button_label                    :"Setujui untuk Pembayaran",
    mark_payment_done_button_label                      :"Tandai Pembayaran Selesai",
    calculate_score_button_label                        :"Hitung Skor",
    view_Score_button_label                             :"Lihat Skor",
    sync_to_crs_button_label                            :"Sinkronkan ke CRS",
    upload_sync_to_crs_button_label                     :"Unggah & Sinkronkan ke CRS",
    view_details_button_label                           :"Melihat rincian"

}
export default {
    ...jobGeneralIds,
    ...tabBarIds,
    ...noDataAvailableInTableIds,
    ...expansionTableIds,
    ...jobInfoIds,
    ...actionsButtonsId
}
import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { withStyles, Button } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';

const styles = _ => ({
  greenButton: {
    borderRadius: "16px",
    backgroundColor: "#1AB64F",
    color: "",
    "&:hover": {
      backgroundColor: "#1ba54a"
    },
    "& span": {
      textTransform: "none"
    }
  },
  blueButton: {
    borderRadius: "16px",
    backgroundColor: "#337ab7",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#286090"
    },
    "& span": {
      textTransform: "none"
    }
  },
  redButton: {
    borderRadius: "16px",
    backgroundColor: "red",
    color: "",
    border: "1px solid rgba(0,0,0,0.08)",
    "&:hover": {
      backgroundColor: "red"
    },
    "& span": {
      textTransform: "none"
    }
  },
  greyButton: {
    borderRadius: "22px",
    backgroundColor: "white",
    color: "grey",
    border: "1px solid rgba(0,0,0,0.08)",
    "&:hover": {
      backgroundColor: "grey"
    },
    "& span": {
      textTransform: "none"
    }
  }
});

function CButton(props) {
  const { classes } = props;

  let cssClassName = "";

  if (props.buttonType === 0) {
    cssClassName = classes.greenButton;
  } else if (props.buttonType === 1) {
    cssClassName = classes.blueButton;
  } else if (props.buttonType === 2) {
    cssClassName = classes.greyButton;
  } else {
    cssClassName = classes.redButton;
  }

  let buttonDOM = null;

  if (props.toURL) {
    buttonDOM = (
      <Button
        component={NavLink}
        to={props.toURL}
        onClick={props.onClick}
        className={cssClassName}
        color="inherit"
        style={props.style}
      >
        <FormattedMessage
            id={props.displayName}
            />
      </Button>
    );
  } else {
    buttonDOM = (
      <Button
        onClick={props.onClick}
        className={cssClassName}
        color="inherit"
        style={props.style}
      >
        <FormattedMessage
            id={props.displayName}
            />
      </Button>
    );
  }

  if (props.buttonType === 2) {
    buttonDOM = (
      <Button
        disabled={true}
        onClick={props.onClick}
        className={cssClassName}
        color="inherit"
        style={props.style}
      >
        <FormattedMessage
            id={props.displayName}
            />
      </Button>
    );
  }

  return <div className={props.className}>{buttonDOM}</div>;
}

CButton.propTypes = {
  toURL: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  /*
    0 - Green Button
    1 - Red Button
    2 - Grey Button
  */
  buttonType: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};

const ButtonWithStyles = withStyles(styles)(CButton);
export default withRouter(ButtonWithStyles);

let propertyDetailsIds = {
    property_details_header_label                                       :"PROPERTY DETAILS",
    oyo_id_label                                                        :"OYO Id",
    crs_status_label                                                    :"CRS Status",
    property_address_label                                              :"Property Address",
    property_type_label                                                 :"Property Type",
    city_type_label                                                     :"City Type",

}

let jobDetailsIds = {
    job_details_header_label                                            :"JOB DETAILS",
    shoot_time_label                                                    :"Shoot Time",
    assigned_to_label                                                   :"Assigned To",
    unique_images_label                                                 :"Unique Images",
    google_drive_link_label                                             :"Google Drive Link",
    browser_images_label                                                :"Browse Images",
    job_type_label                                                      :"Job Type"
}


let genericIds = {
    photographer_details_header_label                                   :"PHOTOGRAPHER DETAILS",
    name_label                                                          :"Name",
    email_label                                                         :"E-Mail",
    contact_number_label                                                :"Contact Number",
    requested_by_label                                                  :"REQUESTED BY",
    requested_on_label                                                  :"Requested On",
    oyo_spoc_details_label                                              :"OYO SPOC DETAILS",
    admin_rejection_reasons_label                                       :"Admin Rejection Reasons",
    admin_reassignment_reasons_label                                    :"Admin Reassignment Reasons",
    vendor_rejection_reasons_label                                      :"Vendor Rejection Reasons",
    vendor_cancellation_reasons_label                                   :"Vendor Cancellation Reasons",
    editor_rejection_reasons_label                                      :"Editor Rejection Reasons",
    editinf_info_inst_for_editor_label                                  :"Editing Information/Instructions for Editor",
    partial_submission_reasons_by_editor_label                          :"Partial Submission Reasons by Editor",
    download_image_zip_label                                            :"Download Images Zip",
    uploaded_images_zip_download_link_label                             :"Uploaded Images Zip Download Link",
    way_to_ota_label                                                    :"Way to OTA",
    ota_drive_link_label                                                :"OTA Drive Link" ,

    requirements_label                                                  :"REQUIREMENTS",
    type_label                                                          :"Type",
    minimum_image_required_label                                        :"Minimum Images Required",
    property_level_requirement_label                                    :"Property Level Requirements",
    category_level_requirement_label                                    :"Category Level Requirements",
    view_level_requirement_label                                        :"View Level Requirements",
    room_level_requirement_label                                        :"Room Level Requirements",
    no_requierment_in_your_list_label                                   :"No Requirements in your list",

    approved_label                                                      :"approved",
    rejected_label                                                      :"rejected",
    total_images_required_label                                         :"Total Images Required",
    minimum_label                                                       :"Minimum",
    photos_label                                                        :"photos",
    
    no_photograph_in_bucket_label                                       :"No Photographs in your bucket!",
    how_about_uploading_photos_label                                    :"How about uploading some photographs?",
    view_all_images_label                                               :"View All Images",
    go_back_label                                                       :"Go Back",
    
    upload_image_button_label                                           :"Upload Image",
    cancel_button_label                                                 :"Cancel",
    total_image_uploaded_label                                          :"Total Images Uploaded",
    total_requirement_label                                             :"Total Requirement",
    uploaded_label                                                      :"Uploaded",
    requirement_label                                                   :"Requirement",
    required_label                                                      :"Required",

    job_time_expired_label                                              :"Job Time has been expired. The job has been cancelled automatically!",
    days_label                                                          :"days",
    hours_label                                                         :"hours",
    minutes_left_label                                                  :"minutes left",



}


export default {
    ...propertyDetailsIds,
    ...jobDetailsIds,
    ...genericIds
}
import axios from "axios";
import * as actionCreators from '../Store/actions/index';
import store from '../Store';
import {showAlert} from "../Utils/Alert";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

//Timeout
instance.defaults.timeout = 300000;

// //Request Middleware
instance.interceptors.request.use(config => {
  config.headers.common["access-token"] = localStorage.token;
  config.headers.common["email"] = localStorage.email;
  return config;
});

//Response Middleware
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    let errorMessage = error.message;

    if(error && error.response && error.response.data && error.response.data.error) {
      errorMessage = error.response.data.error.displayMessage || errorMessage;
      showAlert(error.response.status, errorMessage, error.response.data.request_id);
    }

    if(error && error.response && error.response.data && error.response.data.errorCode === 103) {
     return dispatch => { dispatch(actionCreators.logout());}
    } else if(error.code === "ECONNABORTED") {
      return Promise.reject("Timed out.Please try again!");
    }else if(error && error.response && error.response.status === 401) {
      store.dispatch(actionCreators.authLogout());
      return Promise.reject(errorMessage);
    }
    else {
      return Promise.reject(errorMessage);
    }
  }
);


export default instance;



import { AUTH } from "../actionTypes";
import axios from "../../Axios/OyoStudioWebServiceInstance";
import {getCookie} from "../../Utils/cookies";
import {removeLocalStorage} from "../../Utils/localStorage";

export const authStart = () => {
  return {
    type: AUTH.START
  };
};

export const authSuccess = (token, userId, userName, userType, email) => {
  return {
    type: AUTH.SUCCESS,
    token: token,
    userName: userName,
    userId: userId,
    userType: userType,
    email: email
  };
};

export const authFail = error => {
  removeLocalStorage();
  return {
    type: AUTH.FAIL,
    error: error
  };
};

export const clearErrorMessage = () => {
  return {
    type: AUTH.CLEARERROR
  };
};

export const auth = (userName, password) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      email: userName,
      password: password
    };
    axios
      .post("users/sign_in", authData)
      .then(
        response => {
          if (response.data.code !== 200) {
            dispatch(authFail(response.data.error.message));
          } else {
            const userId = response.data.data.user_id;
            const userType = response.data.data.user_type;
            const userName = response.data.data.data.attributes.user_name;
            const email = response.data.data.data.attributes.email;
            const token =
              response.data.data.data.attributes.current_authentication_token;

            localStorage.setItem("userId", userId);
            localStorage.setItem("userType", userType);
            localStorage.setItem("userName", userName);
            localStorage.setItem("email", email);
            localStorage.setItem("token", token);

            dispatch(authSuccess(token, userId, userName, userType, email));
          }
        }
      )
      .catch(error => {
          dispatch(authFail(error));
      });
  };
};

export const authLogout = () => {
  removeLocalStorage();
  return {
    type: AUTH.LOGOUT
  };
}


export const logout = () => {
  return dispatch => {
    axios.get("users/sign_out")
      .then(response => {
        if(response.data.code === 200) {
            // removeLocalStorage();
            // window.location.reload();
            // return {
            //   type: AUTH.LOGOUT
            // };
            dispatch(authLogout());
          }
          else {
            // console.log("error ", response.code)
          }
        }
      )
      .catch(err => {
        //  FIXME: temporary fix
        dispatch(authLogout());
      })
  }
};

export const setAuthRedirectPath = path => {
  return {
    type: AUTH.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};


export const setSsoLoginFlag = (ssoLoginFlag) => {
  return {
      type: AUTH.SET_SSO_LOGIN_FLAG,
      ssoLoginFlag: ssoLoginFlag
  };
};

export const authCheckState = () => {
  return dispatch => {
          dispatch(authStart());
          if(window.location.pathname === "/ssoLogin"){
              dispatch(setSsoLoginFlag(true));
              removeLocalStorage();
              dispatch(authStart());
          let ssoToken;
          if(window.location.href.indexOf("?") > 0) {
              let ssourl = window.location.href;
              let ssoUrlArray = ssourl.split("?");
              var  ssoTokenArray=ssoUrlArray[1].split("=");
              ssoToken = ssoTokenArray[1];
          }else {
              ssoToken = getCookie("ssoToken");
          }
          var params = {
               ssoToken
            };
          axios.get('users/sso_sign_in',{ params: params })
            .then(response => {
                let responseData=response.data.data;

                if(response.status === 200){
                    const accessToken = ssoToken;
                    const userId = responseData.user_id;
                    const userName = responseData.data.attributes.user_name;
                    const userType = responseData.user_type;
                    const email = responseData.data.attributes.email;
                    localStorage.setItem('token', accessToken);
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('userName', userName);
                    localStorage.setItem('userType', userType);
                    localStorage.setItem('email', email);
                    dispatch(authSuccess(accessToken, userId, userName,userType,email));
                    dispatch(setSsoLoginFlag(false));
                    window.location.assign("/");
                }
                else {
                    window.location.assign("/");
                  // dispatch(logout());
                }

            })
            .catch(err => {
                window.location.assign("/");
            })
           }
    else{

    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const userType = localStorage.getItem("userType");
    const email = localStorage.getItem("email");
    if (
      token === "undefined" ||
      userId === "undefined" ||
      userType === "undefined" ||
      email === "undefined" ||
      !token ||
      !userId ||
      !userType ||
      !email
    ) {
        dispatch(authFail("No user found"));
    } else {
      dispatch(authSuccess(token, userId, userName, userType, email));
    }
    }
  };
};

const fetchMiddleware = store => {
  return next => {
    return action => {
      //console.log('[Redux Middleware Dispatching]', action);
      const result = next(action);
      //console.log('[Redux Middleware next state]', store.getState());
      return result;
    };
  };
};

export default fetchMiddleware;

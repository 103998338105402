import { AUTH } from "../actionTypes";
import { updateObject } from "../utility";

const initialState = {
  userId: null,
  userName: null,
  userType: null,
  token: null,
  email: null,
  error: null,
  loading: true,
  authRedirectPath: "/",
  ssoLoginFlag     :false,
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    userName: action.userName,
    userId: action.userId,
    userType: action.userType,
    email: action.email,
    error: null,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const clearErrorMessage = state => {
  return updateObject(state, {
    error: ""
  });
};

const authLogout = state => {
  return updateObject(state, {
    token: null,
    userId: null,
    userName: null
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, {
    authRedirectPath: action.path
  });
};


const setSsoLoginFlag = (state, action) => {
  return updateObject(state, {
    ssoLoginFlag : action.ssoLoginFlag
  });
}


export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH.START:
      return authStart(state, action);
    case AUTH.SUCCESS:
      return authSuccess(state, action);
    case AUTH.FAIL:
      return authFail(state, action);
    case AUTH.LOGOUT:
      return authLogout(state, action);
    case AUTH.CLEARERROR:
      return clearErrorMessage(state);
    case AUTH.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case AUTH.SET_SSO_LOGIN_FLAG:
      return setSsoLoginFlag(state,action);
    default:
      return state;
  }
};

let faqsPageIds ={
  frequently_asked_questions_label                  :   "Frequently Asked Questions",
  any_questions_label                               :   "Any Questions?",
  we_are_here_to_help_label                         :   "We are here to help",
  contact_us_label                                  :   "Contact us",
  how_to_join_frame_labes                           :   "How to join OYO Frames?",
  click_on_the_label                                :   "Click on the",
  join_now_label                                    :   "JOIN NOW",
  join_frame_with_details_label                     :   "button on the homepage. Fill the application with correct information and in as much detail as possible before submitting it. You will be required to submit a google drive link of your portfolio. You will be able to start photographing for OYO only after you have received an invitation from us and have an approved OYO Frames account.",
  where_you_can_shoot_question_label                :   "Which all geographies can I shoot in?",
  where_you_can_shoot_question_answer_label         :   "OYO Frames is currently active in India. We’re launching in US and UK very soon.",
  guidline_or_rule_question_label                   :   "Are there any guidelines or rules to follow while conducting a photoshoot?",
  guidline_or_rule_question_answer_label            :   "Yes, OYO has photography guidelines which one can access post login on the Frames portal.",
  selection_criteria_question_label                 :   "What is the selection criteria?",
  selection_criteria_question_answer_label_p1       :   "After you’ve submitted your application, our team will check the images and videos submitted and cross-check with references provided, to validate ownership and ensure they meet our partner expectations. Quality will take precedence over quantity, previous experience in the hospitality industry, specially ",
  selection_criteria_question_answer_label_p2       :   " budget hotels ",
  selection_criteria_question_answer_label_p3       :   "will be a definite advantage.",
  specific_equipment_question_label                 :   "Are there any specific equipment needed to become a photography partner at OYO?",
  oyo_require_photographer_have_label               :   "OYO requires photographer to have:",
  camera_details_label                              :   "A minimum 20 megapixels camera.",
  kind_of_lenses_label                              :   "Two kinds of lenses:",
  first_kind_of_lense                               :   "16mm to 40mm",
  second_kind_of_lense                              :   "24mm to 105mm",
  flashlight_label                                  :   "Flashlights",
  tripod_label                                      :   "Tripod"
}


export default {
    ...faqsPageIds
}
import React, { Component,Fragment } from "react";
import AppBar from "./Components/Appbar/Appbar";
import Footer from "./Components/Footer/Footer";
import Root from "./Containers/Root/Root";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "./Store/actions/index";
import "./CSS/App.css";
import "./CSS/OdometerDefaultTheme.css"
// import '@brainhubeu/react-carousel/lib/style.css';

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import IntlProviderWrapper from "./I18N/IntlProviderWrapper";
import CircularProgress from '@material-ui/core/CircularProgress';


class App extends Component {
  constructor(props) {
    super(props);
    this.state={
      pathName:window.location.pathname
    }
    if(!props.isAuthenticated)
      props.tryAutoSignup();
  }

  locationPath= (path) =>{
     this.setState({pathName:path});
  }
  render() {
    let path = window.location.pathname;
    let render = (<Fragment>
      <AppBar />
      <Root   className="app-content-container" locationPath={()=>this.locationPath(path)} pathName={window.location.pathname}/>
      <Footer  pathName={this.state.pathName}/>
    </Fragment>);
    if(this.props.isLoading || this.props.ssoLoginFlag) {
      render = (<CircularProgress />)
    }
    return (
      <IntlProviderWrapper locale={this.props.locale}>
        <BrowserRouter>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="App">
              {render}
            </div>
          </MuiPickersUtilsProvider>
        </BrowserRouter>
      </IntlProviderWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    locale: state.i18n.locale,
    ssoLoginFlag: state.auth.ssoLoginFlag,
    isLoading: state.auth.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    tryAutoSignup: () => dispatch(actionCreators.authCheckState())
  };
};

const AppWithRedux = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppWithRedux;

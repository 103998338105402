import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import { FaCrown, FaTrophy } from "react-icons/fa";
import StatusBar from "../../../../../Components/Custom/StatusBar";

const styles = _ => ({
  root: {
    textAlign: "left",
    margin: "10px",
    padding: "10px"
  },
  textField: {
    height: "32px",
    opacity: "0.75",
    color: "#000",
    fontSize: "30px",
    fontWeight: "bold"
  },
  score: {
    height: "20px",
    color: "#D4AF37",
    fontSize: "20px",
    fontWeight: "bold"
  },
  status: {
    textAlign: "center",
    fontSize: "16px",
    border: "5px transparent",
    fontWeight: "bold"
  },
  searchField: {}
});

class Header extends Component {
  render() {
    const { classes } = this.props;
    let propertyScoreDom = null;
    let jobStatusDom = null;
    propertyScoreDom =
      this.props && this.props.property_score ? (
        <span className={classes.score}>
          <FaCrown /> {this.props.property_score + " / 100"}
        </span>
      ) : null;
    jobStatusDom =
      this.props && this.props.job_status ? (
        <span className={classes.status}>
          <StatusBar status={this.props.job_status} />
        </span>
      ) : null;

    return (
      <div className={classes.root}>
        <Typography className={classes.textField}>
          <div className="row">
            <div className="col-sm-9">
              {this.props.property_name} &nbsp; {propertyScoreDom}
            </div>
            <div className="col-sm-3">{jobStatusDom}</div>
          </div>
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Header);

import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import * as vectors from "../../../../../Resources/Vectors/index";
import "./jobRequirementList.css";
import {FormattedMessage} from "react-intl";

const styles = theme => ({
  root: {
    display: "block",
    marginRight: "46px"
  },
  header: {
    fontFamily: "Inter",
    marginTop: "20px",
    position: "relative",
    marginLeft: "48px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0,0,0,0.54)"
  },
  requirementsContainer: {
    marginTop: "10px",
    marginLeft: "47px",
    width: "600px"
    // border: "1px solid rgba(0, 0, 0, 0.08)",
    // boxSizing: "border-box",
    // borderRadius: "8px"
  },
  categoryRow: {
    display: "flex"
  },
  firstRow: {
    display: "flex",
    marginTop: "24px"
  },
  firstColumn: {},
  secondColumn: {
    marginLeft: "32px"
  },
  verticalLine: {
    width: "2px",
    marginLeft: "12px",
    // minHeight: "63px",
    borderLeft: "2px solid #A1A1A1",
    height: "100%"
  },
  RequiremetLevelRight: {},
  staticBanner: {
    height: "24px",
    width: "24px"
  },
  level: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.54)"
  }
});

const list = ["room", "home", "parking", "car", "double bed"];
function JobRequirements(props) {
  const { classes } = props;
  var categoryDom = null;
  var propertyDom = null;
  var viewDom = null;

  let requirements_data = props.jobInfo.requirements_data;
  if(requirements_data.category_level.length === 0 && requirements_data.property_level.length === 0 && requirements_data.view_level.length === 0) {
    return(<div/>);
  }

  categoryDom = props.jobInfo.requirements_data.category_level.length > 0 ? (
    <div className={classes.categoryRow}>
      <div className={classes.firstColumn}>
        <div className={classes.RequiremetLevelRight}>
          <img
            className={classes.staticBanner}
            src={vectors.RequiremetLevelRight}
          />
        </div>
        {props.jobInfo.requirements_data.property_level.length > 0 ? (
            <div className={classes.verticalLine}></div>
        ) : (
            <div></div>
        )}
      </div>
      <div className={classes.secondColumn}>
        <Typography className={classes.level}>
          <FormattedMessage
            id={"category_level_label"}
            defaultMessage={"Category Level"}
          />
        </Typography>
        <ul className="viewList">
          {props.jobInfo.requirements_data.category_level.map(data => {
            var value = Object.values(data);
            var key = Object.keys(data);
            return <li>{`${value[0] ? key[0] + "(optional)" : key[0]} `}</li>;
          })}
        </ul>
      </div>
    </div>
  ) : (
      <div className={classes.firstRow}></div>
  );
  propertyDom = props.jobInfo.requirements_data.property_level.length > 0 ? (
    <div className={classes.firstRow}>
      <div className={classes.firstColumn}>
        <img
          className={classes.staticBanner}
          src={vectors.RequiremetLevelRight}
        />
        {props.jobInfo.requirements_data.view_level.length > 0 ? (
          <div className={classes.verticalLine}></div>
        ) : (
          <div></div>
        )}
      </div>
      <div className={classes.secondColumn}>
        <Typography className={classes.level}>
          <FormattedMessage
            id={"property_level_label"}
            defaultMessage={"Property Level"}
          />
        </Typography>
        <ul className="viewList">
          {props.jobInfo.requirements_data.property_level.map(data => {
            var value = Object.values(data);
            var key = Object.keys(data);
            return <li>{`${value[0] ? key[0] + "(optional)" : key[0]} `}</li>;
          })}
        </ul>
      </div>
    </div>
  ) : (
      <div className={classes.firstRow}></div>
  );

  viewDom =
    props.jobInfo.requirements_data.view_level.length > 0 ? (
      <div className={classes.firstRow}>
        <div className={classes.firstColumn}>
          <img
            className={classes.staticBanner}
            src={vectors.RequiremetLevelRight}
          />
        </div>
        <div className={classes.secondColumn}>
          <Typography className={classes.level}>
            <FormattedMessage
              id={"view_level_label"}
              defaultMessage={"View Level"}
            />
          </Typography>
          <ul className="viewList">
            {props.jobInfo.requirements_data.view_level.map(data => {
              var value = Object.values(data);
              var key = Object.keys(data);
              return <li>{`${value[0] ? key[0] + "(optional)" : key[0]} `}</li>;
            })}
          </ul>
        </div>
      </div>
    ) : (
      <div className={classes.firstRow}></div>
    );

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        <FormattedMessage
          id={"requirements_label"}
          defaultMessage={"Requirements"}
        />
      </Typography>
      <div className={classes.requirementsContainer}>
        {categoryDom}
        {propertyDom}
        {viewDom}
      </div>
    </div>
  );
}

export default withStyles(styles)(JobRequirements);

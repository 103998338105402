import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import DataComponent from "../../AllJobs/JobsInfo/DataComponent";
import { FaAngleDown, FaAngleRight, FaHandPointRight } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
const styles = theme => ({
  root: {
    margin: "10px",
    padding: "10px",
    borderWidth: "2px",
    borderRadius: "10px",
    backgroundColor: "white"
  },
  jobInformation: {
    margin: "10px",
    padding: "10px",
    float: "center",
    verticalAlign: "middle",
    textAlign: "center",
    minHeight: "96px",
    backgroundColor: "white",
    borderRadius: "10px",
    overflow: "scroll"
  },
  cards: {
    margin: "10px",
    padding: "10px",
    color: "black",
    borderWidth: "2px",
    borderRadius: "10px",
    backgroundColor: "#ECECEC",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle",
    overflow: "scroll"
  },
  cardsHeader: {
    margin: "10px",
    padding: "10px",
    color: "black",
    borderBottom: "solid",
    borderWidth: "2px",
    borderColor: "black",
    borderRadius: "10px",
    backgroundColor: "#8C8C8C",
    fontSize: "16px",
    position: "relative",
    verticalAlign: "middle",
    overflow: "scroll"
  }
});

class JobInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdminRejectionReasons: false,
      showAdminReassignmentReasons: false,
      showVendorRejectionReasons: false,
      showVendorCancellationReasons: false,
      showEditorRejectionReasons: false,
      showEditorPartialSubmissionReasons: false,
      showEditorNotes: false,
      showUploadedImagesZip: false,
      showOtaDriveLink: false,
      showPhotoshootJobCancellationByRequester: false,
      showJobRejectionByQC: false,
      showCommentByQC: false
    };
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(identifier) {
    switch (identifier) {
      case "AdminRejection":
        this.setState({
          showAdminRejectionReasons: !this.state.showAdminRejectionReasons
        });
        break;
      case "AdminReassignment":
        this.setState({
          showAdminReassignmentReasons: !this.state.showAdminReassignmentReasons
        });
        break;
      case "VendorRejection":
        this.setState({
          showVendorRejectionReasons: !this.state.showVendorRejectionReasons
        });
        break;
      case "VendorCancellation":
        this.setState({
          showVendorCancellationReasons: !this.state
            .showVendorCancellationReasons
        });
        break;
      case "EditorRejection":
        this.setState({
          showEditorRejectionReasons: !this.state.showEditorRejectionReasons
        });
        break;
      case "EditorNotes":
        this.setState({ showEditorNotes: !this.state.showEditorNotes });
        break;
      case "EditorPartialSubmission":
        this.setState({
          showEditorPartialSubmissionReasons: !this.state
            .showEditorPartialSubmissionReasons
        });
        break;
      case "UploadedImagesZip":
        this.setState({
          showUploadedImagesZip: !this.state.showUploadedImagesZip
        });
        break;
      case "otaDriveLink":
        this.setState({ showOtaDriveLink: !this.state.showOtaDriveLink });
        break;
      case "photoshootJobCancellationByRequester":
        this.setState({ showPhotoshootJobCancellationByRequester: !this.state.showPhotoshootJobCancellationByRequester });
        break;
      case "commentByQC":
        this.setState({ showCommentByQC: !this.state.showCommentByQC });
        break;
      case "jobRejectionByQC":
        this.setState({ showJobRejectionByQC: !this.state.showJobRejectionByQC });
        break;
    }
  }

  render() {
    const { classes } = this.props;
    let propertyDetailsDom = null;
    let requestedByDom = null;
    let oyoSpocDom = null;
    let jobDetailsDom = null;
    let photographerDetailsDom = null;
    let vendorRejectionDom = null;
    let vendorCancellationDom = null;
    let adminRejectionDom = null;
    let adminReassignmentDom = null;
    let editorRejectionDom = null;
    let editorNotesDom = null;
    let editorPartialSubmissionDom = null;
    let imagesZipLinkDom = null;
    let otaDriveLinkDom = null;
    let photoshootJobCancellationByRequester = null;
    let jobRejectionByQC = null;
    let commentByQC = null;

    if (
      this.props.jobInformation &&
      this.props.jobInformation.rejection_reasons_by_admin &&
      this.props.jobInformation.rejection_reasons_by_admin.length
    ) {
      let reasons_list = this.props.jobInformation.rejection_reasons_by_admin.map(
        reason => (
          <div className={classes.cards}>
            <div className="row">
              <div className="col-sm-9" style={{ textAlign: "left" }}>
                <FaHandPointRight /> {reason.content}
              </div>
              <div className="col-sm-3">{reason.timestamp}</div>
            </div>
          </div>
        )
      );
      adminRejectionDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("AdminRejection");
                }}
              >
                <span>
                  {this.state.showAdminRejectionReasons ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                  <FormattedMessage
                    id={"admin_rejection_reasons_label"}
                    defaultMessage={"Admin Rejection Reasons"}
                  />{" "}
                </b>
              </div>
            </div>
          </div>
          {this.state.showAdminRejectionReasons && reasons_list}
        </>
      );
    }

    if (
      this.props.jobInformation &&
      this.props.jobInformation.reassignment_reasons_by_admin &&
      this.props.jobInformation.reassignment_reasons_by_admin.length
    ) {
      let reasons_list = this.props.jobInformation.reassignment_reasons_by_admin.map(
        reason => {
          let temp = reason.content.split("=>");
          let content = temp[0].trim();
          let entity = temp[1].trim();
          return (
            <div className={classes.cards}>
              <div className="row">
                <div className="col-sm-6" style={{ textAlign: "left" }}>
                  <FaHandPointRight /> {content}
                </div>
                <div className="col-sm-3">{entity}</div>
                <div className="col-sm-3">{reason.timestamp}</div>
              </div>
            </div>
          );
        }
      );
      adminReassignmentDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("AdminReassignment");
                }}
              >
                <span>
                  {this.state.showAdminReassignmentReasons ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                  <FormattedMessage
                    id={"admin_reassignment_reasons_label"}
                    defaultMessage={"Admin Reassignment Reasons"}
                  />
                </b>
              </div>
            </div>
          </div>
          {this.state.showAdminReassignmentReasons && reasons_list}
        </>
      );
    }

    if (
      this.props.jobInformation &&
      this.props.jobInformation.rejection_reasons_by_vendor &&
      this.props.jobInformation.rejection_reasons_by_vendor.length
    ) {
      let reasons_list = this.props.jobInformation.rejection_reasons_by_vendor.map(
        reason => (
          <div className={classes.cards}>
            <div className="row">
              <div className="col-sm-9" style={{ textAlign: "left" }}>
                <FaHandPointRight /> {reason.content}
              </div>
              <div className="col-sm-3">{reason.timestamp}</div>
            </div>
          </div>
        )
      );
      vendorRejectionDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("VendorRejection");
                }}
              >
                <span>
                  {this.state.showVendorRejectionReasons ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                  <FormattedMessage
                    id={"vendor_rejection_reasons_label"}
                    defaultMessage={"Vendor Rejection Reasons"}
                  />{" "}
                </b>
              </div>
            </div>
          </div>
          {this.state.showVendorRejectionReasons && reasons_list}
        </>
      );
    }

    if (
      this.props.jobInformation &&
      this.props.jobInformation.cancellation_reasons_by_vendor &&
      this.props.jobInformation.cancellation_reasons_by_vendor.length
    ) {
      let reasons_list = this.props.jobInformation.cancellation_reasons_by_vendor.map(
        reason => {
          let temp = reason.content.split("=>");
          let content = temp[0].trim();
          let driveLink = temp[1].trim();
          return (
            <div className={classes.cards}>
              <div className="row">
                <div className="col-sm-6" style={{ textAlign: "left" }}>
                  <FaHandPointRight /> {content}
                </div>
                <div className="col-sm-3">
                  {driveLink == "" ? (
                    <></>
                  ) : (
                    <a href={driveLink} target="_blank">
                      <FormattedMessage
                        id={"browser_images_label"}
                        defaultMessage={"Browse Images"}
                      />
                    </a>
                  )}
                </div>
                <div className="col-sm-3">{reason.timestamp}</div>
              </div>
            </div>
          );
        }
      );
      vendorCancellationDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("VendorCancellation");
                }}
              >
                <span>
                  {this.state.showVendorCancellationReasons ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                  <FormattedMessage
                    id={"vendor_cancellation_reasons_label"}
                    defaultMessage={"Vendor Cancellation Reasons"}
                  />
                </b>
              </div>
            </div>
          </div>
          {this.state.showVendorCancellationReasons && reasons_list}
        </>
      );
    }

    if (
      this.props.jobInformation &&
      this.props.jobInformation.rejection_reasons_by_editor &&
      this.props.jobInformation.rejection_reasons_by_editor.length
    ) {
      let reasons_list = this.props.jobInformation.rejection_reasons_by_editor.map(
        reason => (
          <div className={classes.cards}>
            <div className="row">
              <div className="col-sm-9" style={{ textAlign: "left" }}>
                <FaHandPointRight /> {reason.content}
              </div>
              <div className="col-sm-3">{reason.timestamp}</div>
            </div>
          </div>
        )
      );
      editorRejectionDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("EditorRejection");
                }}
              >
                <span>
                  {this.state.showEditorRejectionReasons ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                  <FormattedMessage
                    id={"editor_rejection_reasons_label"}
                    defaultMessage={"Editor Rejection Reasons"}
                  />
                </b>
              </div>
            </div>
          </div>
          {this.state.showEditorRejectionReasons && reasons_list}
        </>
      );
    }

    if (
      this.props.jobInformation &&
      this.props.jobInformation.comments_for_editor &&
      this.props.jobInformation.comments_for_editor.length
    ) {
      let reasons_list = this.props.jobInformation.comments_for_editor.map(
        reason => (
          <div className={classes.cards}>
            <div className="row">
              <div className="col-sm-9" style={{ textAlign: "left" }}>
                <FaHandPointRight /> {reason.content}
              </div>
              <div className="col-sm-3">{reason.timestamp}</div>
            </div>
          </div>
        )
      );
      editorNotesDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("EditorNotes");
                }}
              >
                <span>
                  {this.state.showEditorNotes ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                  <FormattedMessage
                    id={"editinf_info_inst_for_editor_label"}
                    defaultMessage={
                      "Editing Information/Instructions for Editor"
                    }
                  />
                </b>
              </div>
            </div>
          </div>
          {this.state.showEditorNotes && reasons_list}
        </>
      );
    }

    if (
      this.props.jobInformation &&
      this.props.jobInformation.partial_submission_reasons_by_editor &&
      this.props.jobInformation.partial_submission_reasons_by_editor.length
    ) {
      let reasons_list = this.props.jobInformation.partial_submission_reasons_by_editor.map(
        reason => (
          <div className={classes.cards}>
            <div className="row">
              <div className="col-sm-9" style={{ textAlign: "left" }}>
                <FaHandPointRight /> {reason.content}
              </div>
              <div className="col-sm-3">{reason.timestamp}</div>
            </div>
          </div>
        )
      );
      editorPartialSubmissionDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("EditorPartialSubmission");
                }}
              >
                <span>
                  {this.state.showEditorPartialSubmissionReasons ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                  <FormattedMessage
                    id={"partial_submission_reasons_by_editor_label"}
                    defaultMessage={"Partial Submission Reasons by Editor"}
                  />
                </b>
              </div>
            </div>
          </div>
          {this.state.showEditorPartialSubmissionReasons && reasons_list}
        </>
      );
    }

    if (
      this.props.jobInformation &&
      this.props.jobInformation.photoshoot_images_zip_urls &&
      this.props.jobInformation.photoshoot_images_zip_urls.length
    ) {
      let active_zip_url_list = this.props.jobInformation.photoshoot_images_zip_urls.map(
        zip => (
          <div className={classes.cards}>
            <div className="row">
              <div className="col-sm-9" style={{ textAlign: "left" }}>
                <FaHandPointRight />{" "}
                <a href={zip.content} target="_blank">
                  {" "}
                  <FormattedMessage
                    id={"download_image_zip_label"}
                    defaultMessage={"Download Images Zip"}
                  />
                </a>
              </div>
              <div className="col-sm-3">{zip.created_at}</div>
            </div>
          </div>
        )
      );
      imagesZipLinkDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("UploadedImagesZip");
                }}
              >
                <span>
                  {this.state.showUploadedImagesZip ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                  <FormattedMessage
                    id={"uploaded_images_zip_download_link_label"}
                    defaultMessage={"Uploaded Images Zip Download Link"}
                  />
                </b>
              </div>
            </div>
          </div>
          {this.state.showUploadedImagesZip && active_zip_url_list}
        </>
      );
    }

    if (
      this.props.jobInformation &&
      this.props.jobInformation.ota_drive_link &&
      this.props.jobInformation.ota_drive_link.length
    ) {
      let ota_drive_link_list = this.props.jobInformation.ota_drive_link.map(
        link => (
          <div className={classes.cards}>
            <div className="row">
              <div className="col-sm-9" style={{ textAlign: "left" }}>
                <FaHandPointRight />{" "}
                <a href={link.content} target="_blank">
                  {" "}
                  <FormattedMessage
                    id={"way_to_ota_label"}
                    defaultMessage={"Way to OTA"}
                  />
                </a>
              </div>
              <div className="col-sm-3">{link.created_at}</div>
            </div>
          </div>
        )
      );
      otaDriveLinkDom = (
        <>
          <div className={classes.cardsHeader}>
            <div className="row">
              <div
                className="col-sm-12"
                style={{ textAlign: "left" }}
                onClick={() => {
                  this.onToggle("otaDriveLink");
                }}
              >
                <span>
                  {this.state.showOtaDriveLink ? (
                    <FaAngleDown />
                  ) : (
                    <FaAngleRight />
                  )}
                </span>{" "}
                <b>
                  <FormattedMessage
                    id={"ota_drive_link_label"}
                    defaultMessage={"OTA Drive Link"}
                  />
                </b>
              </div>
            </div>
          </div>
          {this.state.showOtaDriveLink && ota_drive_link_list}
        </>
      );
    }

    if (
        this.props.jobInformation &&
        this.props.jobInformation.photoshoot_job_cancellation_by_requester &&
        this.props.jobInformation.photoshoot_job_cancellation_by_requester.length
    ) {
      let reasons_list = this.props.jobInformation.photoshoot_job_cancellation_by_requester.map(
          reason => (
              <div className={classes.cards}>
                <div className="row">
                  <div className="col-sm-9" style={{ textAlign: "left" }}>
                    <FaHandPointRight /> {reason.content}
                  </div>
                  <div className="col-sm-3">{reason.timestamp}</div>
                </div>
              </div>
          )
      );
      photoshootJobCancellationByRequester = (
          <>
            <div className={classes.cardsHeader}>
              <div className="row">
                <div
                    className="col-sm-12"
                    style={{ textAlign: "left" }}
                    onClick={() => {
                      this.onToggle("photoshootJobCancellationByRequester");
                    }}
                >
                <span>
                  {this.state.showPhotoshootJobCancellationByRequester ? (
                      <FaAngleDown />
                  ) : (
                      <FaAngleRight />
                  )}
                </span>{" "}
                  <b>
                    <FormattedMessage
                        id={"photoshoot_job_cancellation_by_requester_label"}
                        defaultMessage={"Rejection Reason By Requester"}
                    />
                  </b>
                </div>
              </div>
            </div>
            {this.state.showPhotoshootJobCancellationByRequester && reasons_list}
          </>
      );
    }


    if (
        this.props.jobInformation &&
        this.props.jobInformation.job_rejection_by_qc &&
        this.props.jobInformation.job_rejection_by_qc.length
    ) {
      let reasons_list = this.props.jobInformation.job_rejection_by_qc.map(
          reason => (
              <div className={classes.cards}>
                <div className="row">
                  <div className="col-sm-9" style={{ textAlign: "left" }}>
                    <FaHandPointRight /> {reason.content}
                  </div>
                  <div className="col-sm-3">{reason.timestamp}</div>
                </div>
              </div>
          )
      );
      jobRejectionByQC = (
          <>
            <div className={classes.cardsHeader}>
              <div className="row">
                <div
                    className="col-sm-12"
                    style={{ textAlign: "left" }}
                    onClick={() => {
                      this.onToggle("jobRejectionByQC");
                    }}
                >
                <span>
                  {this.state.showJobRejectionByQC ? (
                      <FaAngleDown />
                  ) : (
                      <FaAngleRight />
                  )}
                </span>{" "}
                  <b>
                    <FormattedMessage
                        id={"job_rejection_by_qc_label"}
                        defaultMessage={"Rejection Reason By QC"}
                    />
                  </b>
                </div>
              </div>
            </div>
            {this.state.showJobRejectionByQC && reasons_list}
          </>
      );
    }

    if (
        this.props.jobInformation &&
        this.props.jobInformation.comment_by_qc &&
        this.props.jobInformation.comment_by_qc.length
    ) {
      let reasons_list = this.props.jobInformation.comment_by_qc.map(
          reason => (
              <div className={classes.cards}>
                <div className="row">
                  <div className="col-sm-9" style={{ textAlign: "left" }}>
                    <FaHandPointRight /> {reason.content}
                  </div>
                  <div className="col-sm-3">{reason.timestamp}</div>
                </div>
              </div>
          )
      );
      commentByQC = (
          <>
            <div className={classes.cardsHeader}>
              <div className="row">
                <div
                    className="col-sm-12"
                    style={{ textAlign: "left" }}
                    onClick={() => {
                      this.onToggle("commentByQC");
                    }}
                >
                <span>
                  {this.state.showCommentByQC ? (
                      <FaAngleDown />
                  ) : (
                      <FaAngleRight />
                  )}
                </span>{" "}
                  <b>
                    <FormattedMessage
                        id={"comment_by_qc_label"}
                        defaultMessage={"Comment by QC for Soft Live"}
                    />
                  </b>
                </div>
              </div>
            </div>
            {this.state.showCommentByQC && reasons_list}
          </>
      );
    }

    propertyDetailsDom = (
      <>
        <div className={classes.cardsHeader}>
          <div className="row">
            <div className="col-sm-12">
              <b>
                <FormattedMessage
                  id={"property_details_header_label"}
                  defaultMessage={"PROPERTY DETAILS"}
                />
              </b>
            </div>
          </div>
        </div>
        <div className={classes.jobInformation}>
          <div className="row">
            <div className="col-sm-2">
              <DataComponent
                messageId="oyo_id_label"
                header="OYO Id"
                value={this.props.hotelInformation.crs_id}
              />
            </div>
            <div className="col-sm-2">
              <DataComponent
                messageId="crs_status_label"
                header="CRS Status"
                value={this.props.hotelInformation.crs_status}
              />
            </div>
            <div className="col-sm-4">
              <DataComponent
                messageId="property_address_label"
                header="Property Address"
                value={this.props.hotelInformation.street_address}
              />
            </div>
            <div className="col-sm-2">
              <DataComponent
                messageId="property_type_label"
                header="Property Type"
                value={this.props.jobInformation.model_type}
              />
            </div>
            <div className="col-sm-2">
              <DataComponent
                messageId="city_type_label"
                header="City Type"
                value={this.props.jobInformation.city_type}
              />
            </div>
          </div>
        </div>
      </>
    );

    jobDetailsDom = (
      <>
        <div className={classes.cardsHeader}>
          <div className="row">
            <div className="col-sm-12">
              <b>
                <FormattedMessage
                  id={"job_details_header_label"}
                  defaultMessage={"JOB DETAILS"}
                />
              </b>
            </div>
          </div>
        </div>
        <div className={classes.jobInformation}>
          <div className="row">
            <div className="col-sm-3">
              <DataComponent
                messageId="shoot_time_label"
                header="Shoot Time"
                value={
                  <div>
                    <div>{this.props.jobInformation.shoot_date}</div>
                    <div>{this.props.jobInformation.shoot_slot}</div>
                  </div>
                }
              />
            </div>
            <div className="col-sm-3">
              <DataComponent
                messageId="assigned_to_label"
                header="Assigned To"
                value={this.props.jobInformation.assigned_to_id}
              />
            </div>
            <div className="col-sm-2">
              <DataComponent
                messageId="unique_images_label"
                header="Unique Images"
                value={this.props.jobInformation.unique_images_count}
              />
            </div>
            <div className="col-sm-2">
              <DataComponent
                messageId="google_drive_link_label"
                header="Google Drive Link"
                value={
                  this.props.jobInformation.drive_link ? (
                    <a
                      href={this.props.jobInformation.drive_link}
                      target="_blank"
                    >
                      <FormattedMessage
                        id={"browser_images_label"}
                        defaultMessage={" Browse Images"}
                      />
                    </a>
                  ) : (
                    "-"
                  )
                }
              />
            </div>
            {this.props.jobInformation.job_type != null ? (
              <div className="col-sm-2">
                <DataComponent
                  messageId="job_type_label"
                  header="Job Type"
                  value={this.props.jobInformation.job_type}
                />
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
    if (this.props.user_type !== "Editor") {
      if (
        this.props.photographerInformation &&
        this.props.photographerInformation.name &&
        this.props.photographerInformation.email &&
        this.props.photographerInformation.contact_number
      ) {
        photographerDetailsDom = (
          <>
            <div className={classes.cardsHeader}>
              <div className="row">
                <div className="col-sm-12">
                  <b>
                    <FormattedMessage
                      id={"photographer_details_header_label"}
                      defaultMessage={"PHOTOGRAPHER DETAILS"}
                    />
                  </b>
                </div>
              </div>
            </div>
            <div className={classes.jobInformation}>
              <div className="row">
                <div className="col-sm-4">
                  <DataComponent
                    messageId="name_label"
                    header="Name"
                    value={this.props.photographerInformation.name}
                  />
                </div>
                <div className="col-sm-4">
                  <DataComponent
                    messageId="email_label"
                    header="E-Mail"
                    value={this.props.photographerInformation.email}
                  />
                </div>
                <div className="col-sm-4">
                  <DataComponent
                    messageId="contact_number_label"
                    header="Contact Number"
                    value={this.props.photographerInformation.contact_number}
                  />
                </div>
              </div>
            </div>
          </>
        );
      }
    }

    requestedByDom = (
      <>
        <div className={classes.cardsHeader}>
          <div className="row">
            <div className="col-sm-12">
              <b>
                <FormattedMessage
                  id={"requested_by_label"}
                  defaultMessage={"REQUESTED BY"}
                />
              </b>
            </div>
          </div>
        </div>
        <div className={classes.jobInformation}>
          <div className="row">
            <div className="col-sm-4">
              <DataComponent
                messageId="name_label"
                header="Name"
                value={
                  this.props.oyoSpocInformation
                    ? this.props.oyoSpocInformation.name
                    : null
                }
              />
            </div>
            {/*<div className="col-sm-3"><DataComponent*/}
            {/*  header="Phone"*/}
            {/*  value={null}*/}
            {/*/></div>*/}
            <div className="col-sm-4">
              <DataComponent
                messageId="email_label"
                header="E-Mail"
                value={
                  this.props.oyoSpocInformation
                    ? this.props.oyoSpocInformation.email
                    : null
                }
              />
            </div>
            <div className="col-sm-4">
              <DataComponent
                messageId="requested_on_label"
                header="Requested On"
                value={this.props.jobInformation.job_created_at}
              />
            </div>
          </div>
          <br />
        </div>
      </>
    );
    if (this.props.oyoSpocInformation) {
      if (
        this.props.oyoSpocInformation &&
        this.props.oyoSpocInformation.oyo_spoc_name &&
        this.props.oyoSpocInformation.oyo_spoc_contact_number
      ) {
        oyoSpocDom = (
          <>
            <div className={classes.cardsHeader}>
              <div className="row">
                <div className="col-sm-12">
                  <b>
                    <FormattedMessage
                      id={"oyo_spoc_details_label"}
                      defaultMessage={"OYO SPOC DETAILS"}
                    />
                  </b>
                </div>
              </div>
            </div>
            <div className={classes.jobInformation}>
              <div className="row">
                <div className="col-sm-6">
                  <DataComponent
                    messageId="name_label"
                    header="Name"
                    value={
                      this.props.oyoSpocInformation
                        ? this.props.oyoSpocInformation.oyo_spoc_name
                        : null
                    }
                  />
                </div>
                <div className="col-sm-6">
                  <DataComponent
                    messageId="contact_number_label"
                    header="Contact Number"
                    value={
                      this.props.oyoSpocInformation
                        ? this.props.oyoSpocInformation.oyo_spoc_contact_number
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </>
        );
      }
    }

    return (
      <div className={classes.root}>
        {propertyDetailsDom}
        {jobDetailsDom}
        {photographerDetailsDom}
        {requestedByDom}
        {oyoSpocDom}
        {adminRejectionDom}
        {adminReassignmentDom}
        {vendorCancellationDom}
        {vendorRejectionDom}
        {editorRejectionDom}
        {editorPartialSubmissionDom}
        {editorNotesDom}
        {imagesZipLinkDom}
        {otaDriveLinkDom}
        {photoshootJobCancellationByRequester}
        {jobRejectionByQC}
        {commentByQC}
      </div>
    );
  }
}

export default withStyles(styles)(JobInformation);

import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Icon from "react-fa";
import { withStyles } from "@material-ui/core";
import ModalImage from "react-modal-image";
import DeleteIcon from "@material-ui/icons/Delete";
import Swal from "sweetalert2";
import axios from "../../Axios/OyoStudioWebServiceInstance";
import { FormattedMessage ,injectIntl } from 'react-intl';
const styles = theme => ({
  root: {
    display: "flex"
  },
  arrow: {
    alignSelf: "center",
    width: "10%"
  },
  carousel: {
    width: "80%"
  },
  image: {
    width: "280px",
    height: "200px",
    boxShadow: "0 8px 6px -6px black"
  },
  acceptedImage: {
    width: "200px",
    height: "120px",
    boxShadow: "0 8px 6px -6px black",
    border: "3px lightgreen solid"
  },

  rejectedImage: {
    width: "200px",
    height: "120px",
    boxShadow: "0 8px 6px -6px black",
    border: "3px indianred solid"
  },

  box: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "20px",
    boxShadow: "0 8px 6px -6px black",
    backgroundColor: "lightblue"
  },
  dialogBox: {
    top: 0,
    left: 0,
    position: "fixed",
    zIndex: 99999,
    minHeight: "100%",
    minWidth: "100%"
  },
  dialogBoxImage: {
    maxHeight: "95%",
    maxWidth: "100%",
    padding: 0
  },
  dialogBoxImageClose: {
    top: 0,
    right: "50px",
    position: "absolute"
  }
});

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      galleryLoader: true
    };
  }

  divStyle = {
    height: "50px",
    weight: "50px"
  };

  showFullImage(item) {
    this.setState({
      isOpen: true,
      dialogImage: item.url
    });
  }

  deleteImage(image_id) {
    const { intl } = this.props;
    Swal.fire({
      title: intl.formatMessage({
                      id: "are_you_sure_label",
                      defaultMessage:"Are you sure?"
                    }),
      text: intl.formatMessage({
                      id: "you_wont_be_able_to_revert_this_label",
                      defaultMessage:"You won't be able to revert this!"
                    }),
      type: "warning",
      showCancelButton: true,
      cancelButtonText: intl.formatMessage({
                          id: "cancel_buttoon_default_label",
                          defaultMessage:"Cancel"
                        }),
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: intl.formatMessage({
                      id: "yes_delete_it_button_label",
                      defaultMessage:"Yes, delete it!"
                    })
    }).then(result => {
      if (result.value) {
        let url = "/photoshoot_jobs/delete_image";
        let params = { image_id: image_id };
        axios
          .put(url, params)
          .then(response => {
            Swal.fire(intl.formatMessage({
                      id: "deleted_label",
                      defaultMessage:"Deleted!"
                    }), intl.formatMessage({
                      id: "your_file_has_been_deleted_label",
                      defaultMessage:"Your file has been deleted."
                    }), "success");
            this.props.onGalleryImageDeletion(image_id);
            if (!response.data.data.requirement_satisfied) {
              this.props.onUploadImagesRequirementStatusChange(
                "Not Accepted",
                response.data.data.job_status.other_requirements,
                response.data.data.job_status.accepted_requirements
              );
            }
          })
          .catch(() => {});
      }
    });

  }

  galleryItems() {
    let { classes,intl } = this.props;
    return this.props.requirement_images.map((item, i) => (
      <div key={`key-${i}`} className="imageDiv">
        <img
          className={
            item.image_status == "Accepted"
              ? classes.acceptedImage
              : classes.rejectedImage
          }
          src={item.url}
          onClick={() => this.showFullImage(item)}
        />
        <span className={`delete-${i}`}>
          <DeleteIcon
            onClick={() => this.deleteImage(item.id)}
            className={`delete-button-${i}`}
          />
        </span>
      </div>
    ));
  }

  thumbItem = (item, i) => (
    <div className={this.divStyle} onClick={() => this.Carousel._onDotClick(i)}>
      <img src={item} style={this.divStyle} className="ab" />
    </div>
  );

  responsive = {
    0: { items: 1 },
    600: { items: 2 },
    1024: { items: 4 }
  };

  render() {
    let { classes } = this.props;
    let items = this.galleryItems();
    let galleryDom = (
      <div className={classes.box}>
        <div className={classes.root}>
          <Icon
            className={classes.arrow}
            onClick={() => this.Carousel._slidePrev()}
            name="arrow-left"
          />
          <div className={classes.carousel}>
            <AliceCarousel
              items={items}
              dotsDisabled={true}
              buttonsDisabled={true}
              mouseDragEnabled={true}
              duration={400}
              startIndex={1}
              responsive={this.responsive}
              // autoPlay={true}
              // autoPlayInterval={2000}
              // autoPlayDirection="rtl"
              //   autoPlayActionDisabled={true}
              ref={el => (this.Carousel = el)}
            >
              <p></p>
            </AliceCarousel>

            {this.state.isOpen && (
              <div>
                <dialog
                  className={classes.dialogBox}
                  open
                  onClick={this.handleShowDialog}
                >
                  <img
                    className={classes.dialogBoxImage}
                    src={this.state.dialogImage}
                    onClick={this.handleShowDialog}
                    alt="no image"
                  ></img>

                  <div
                    className={classes.dialogBoxImageClose}
                    onClick={() => {
                      this.setState({ isOpen: false });
                    }}
                  >
                    Close
                  </div>
                </dialog>
              </div>
            )}
          </div>
          <Icon
            className={classes.arrow}
            onClick={() => this.Carousel._slideNext()}
            name="arrow-right"
          />
        </div>
      </div>
    );

    return <>{galleryDom}</>;
  }
}

export default injectIntl(withStyles(styles)(Gallery));

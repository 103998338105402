import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import logo from "../../Resources/Images/Logo.svg";
import * as vectors from "../../Resources/Vectors/index";
import * as actionCreators from "../../Store/actions/index";
import { FormattedMessage } from 'react-intl';
import "./Appbar.css";
import I18Info from "../Appbar/GlobeLogo/I18NInfo";
import { withStyles, Button ,Dialog,Menu,MenuItem } from '@material-ui/core';
import Login from "../../Containers/Auth/Login/Login";
import locales from '../../I18N/locales';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';




const styles = theme => ({
  root: {},
  paperFullWidth: {
    width: "90%",
    margin: "0  5%"
  },
  loginButton: {
    margin        :"20px 15px 20px 15px",
    color         : "#212121",
    height        : "40px",
    width         : "100px",
    border        : "1px solid #212121",
    borderRadius  : "8px",
   // margin       :"20px 0 20px 30px",
  },
  userButton: {
    margin        :"20px 0 20px 0",
    height        : "40px",
    width         : "auto",
    minWidth      : "40px",
    border        : "1px solid #212121",
    borderRadius  : "8px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "24px",
    alignItems: "center",
    textAlign: "center",
    fontFeatureSettings: "'liga' off",
    color: "#212121"
    
  }
});


class SimpleAppBar extends Component {
  constructor(props){
    super(props);
    this.state= {
      dialogFlag:false,
      mwebdialogFlag:false,
      userSelectOpen:null,
      languageOptionsFlag:false,
    }
  }



   componentDidMount = () => {
   this.setState({odometerValue1:170000, odometerValue2:600,odometerValue3:114000});
   window.addEventListener('scroll', this.handleScroll);
 }
  handleScroll = (event) => {
     const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop
      if(winScroll>= 10)
            document.getElementById("appbarContainer").style.boxShadow= "10px 8px 51px rgba(0, 0, 0, 0.05)"; 
      else 
            document.getElementById("appbarContainer").style.boxShadow= "none";
      
   try{
     if(winScroll>= 290)
        document.getElementById("mWebjoinNowContainer").style.display= "block";
     else
       document.getElementById("mWebjoinNowContainer").style.display= "none"; 
   }
   catch(error){}          
  }


  goToHomePage = () => {
    // window.location = "https://frames.oyorooms.com/";
    this.props.history.push("/");
  };



  openNav() {
    document.getElementById("sideNav").style.width = "300px";
  }

  closeNav() {
    document.getElementById("sideNav").style.width = "0";
  }

  menuOptionClickHandler = event => {
      this.props.logout();
      // this.goToHomePage();
  };

 handleClickOpen = () => {
    this.setState({dialogFlag:true});
  };

 mWebhandleClickOpen = () => {
    this.setState({mwebdialogFlag:true});
  };

  handleSelectOpen = event => {
    this.setState({userSelectOpen:event.currentTarget});
  };

   handleSelectClose = () => {
    this.setState({userSelectOpen:null});
  };

  handleClose = () => {
    this.setState({dialogFlag:false});
  };

  mWebhandleClose = () => {
    this.setState({mwebdialogFlag:false});
  };

  openLanguageOptions = () => {
    if(!this.state.languageOptionsFlag)
      this.setState({languageOptionsFlag:true});
    else 
     this.setState({languageOptionsFlag:false});
  }

  handleLanguageSelect = (item) => {
     this.props.changeLocale(item.key);
  }
  render() {
    const { classes } = this.props;
    let navBarDom = null;
    //  console.log("path is in appbar  ",window.location.pathname);
    
    let userDom = (
      <div className="user-dom-container">
       <Button   aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleSelectOpen} style={{ textTransform: "uppercase" }}>
       {localStorage.email}
      </Button>
      <Menu
        id="menu-list-grow"
        anchorEl={this.state.userSelectOpen}
        keepMounted
        open={Boolean(this.state.userSelectOpen)}
        onClose={this.handleSelectClose}
        style={{marginTop: "45px"}}
      >
        <MenuItem className="user-container-menu-item" onClick={this.handleSelectClose}>
          <a href={void 0} style={{ color: "grey", cursor: "none" }}>
              <FormattedMessage
                id={"navbar_user_my_profile_label"}
                defaultMessage={"MY PROFILE"}
              />
          </a>
        </MenuItem>
        <MenuItem className="user-container-menu-item" onClick={this.handleSelectClose}>
          <Link to={`/ResetPassword`}>
                <FormattedMessage
                  id={"navbar_user_reset_password_label"}
                  defaultMessage={"Reset Password"}
                />
            </Link>
        </MenuItem>
        <MenuItem className="user-container-menu-item" onClick={this.handleSelectClose}>
          <Link to={`/misPayment`}>
            <FormattedMessage
              id={"navbar_user_mis_report_label"}
              defaultMessage={"MIS Reports"}
            />
          </Link>
        </MenuItem>
        <MenuItem className="user-container-menu-item" onClick={this.handleSelectClose}>
          <a
              href={void 0}
              data-name="logout"
              onClick={this.menuOptionClickHandler}
            >
            <FormattedMessage
              id={"navbar_user_logout_label"}
              defaultMessage={"Sign Out"}
            />   
            </a></MenuItem>
         </Menu>
      </div>
   
    );

let loginDom = (
        <div className="login-button-dom">
              <Button  variant="outlined" color="primary" onClick={() => this.handleClickOpen()}>
                   <FormattedMessage
                        id={"navbar_login_label"}
                        defaultMessage={"Log in"}
                      /> 
              </Button>
                <Dialog open={this.state.dialogFlag}  maxWidth={"md"}  onClose={()=>this.handleClose()} >
                    <Login handleClose={()=>this.handleClose()}/>
                </Dialog>
        </div>
)

let mwebLoginDom = (
              <div className="login-button-dom">
                    <Button  variant="outlined" color="primary" onClick={()=>this.mWebhandleClickOpen()}>
                        <FormattedMessage
                              id={"navbar_login_label"}
                              defaultMessage={"Log in"}
                            /> 
                    </Button>
                      <Dialog open={this.state.mwebdialogFlag}  fullScreen  onClose={()=>this.mWebhandleClose()} >
                          <Login handleClose={()=>this.mWebhandleClose()}/>
                      </Dialog>
              </div>
            )

let languageDom = (
    <div className="language-options-list-container">
      {locales.map((item,index) => {
        return (<div key={index} style={{ padding: "10px 24px" }} onClick={()=>this.handleLanguageSelect(item)}>
                  <span style={{ paddingLeft: "2rem" }}>{item.caption}</span>
                  <div className="mobile-navbar-language-horizontal-line"></div>
               </div>)
      })}
    </div>
)


let preLogInDom = (
  <div className="mobile-pre-login-dom-container">
      
      <div className="mobile-pre-login-button-row">
         {mwebLoginDom}
      </div>

       <Link to={`/`} onClick={this.closeNav}>
          <img src={vectors.Home} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"navbar_pre_login_home_link_label"}
                defaultMessage={"Home"}
              />
          </span>
       </Link>
        
        <div className="mobile-navbar-horizontal-line"></div>
       
        <a
          href="https://www.oyorooms.com/weblink?url=https://www.oyorooms.com/about/"
          target="_blank"
          onClick={this.closeNav}
        >
          <img src={vectors.AboutUs} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"navbar_about_us_label"}
                defaultMessage={"About Us"}
              /> 
          </span>
        </a>
         <div className="mobile-navbar-horizontal-line"></div>
        
        <a
          href="https://www.oyorooms.com/weblink?url=https://www.oyorooms.com/officialoyoblog/"
          target="_blank"
          onClick={this.closeNav}
        >
          <img src={vectors.Blog} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"navbar_blog_label"}
                defaultMessage={"Blog"}
              /> 
          </span>
        </a>

        <div className="mobile-navbar-horizontal-line"></div>
        
        <div className="mweb-Language-selector-container" onClick={this.openLanguageOptions}>
          <img src={vectors.MWebGlobe} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"language_label"}
                defaultMessage={"Language"}
              />
          </span>
          <span style={{ paddingLeft: "4rem" ,textTransform: "uppercase" }}>{this.props.locale}{this.state.languageOptionsFlag ? <KeyboardArrowUpIcon style={{ marginLeft: "14px"  }}/> : <KeyboardArrowDownIcon style={{ marginLeft: "14px"  }}/>}</span>
          
              
        </div>
         
        <div className="mobile-navbar-horizontal-line"></div>
        {this.state.languageOptionsFlag ? (languageDom) : (<div></div>)}
        
        <Link to={`/contact`} onClick={this.closeNav}>
          <img src={vectors.PhoneCall} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"navbar_contact_us_label"}
                defaultMessage={"Contact Us"}
              />
          </span>
        </Link>
        
        <div className="mobile-navbar-horizontal-line"></div>
        
        <Link to={`/faqs`} onClick={this.closeNav}>
          <img src={vectors.Question} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"navbar_faqs_label"}
                defaultMessage={"FAQs"}
              /></span>
        </Link>

  </div>
)


let postLogInDom = (
  <div className="mobile-post-login-dom-container">

        <div className="post-login-profile-container">
            <Link
            to={`/`}
            onClick={this.closeNav}
            style={{ display: "grid", gridTemplateColumns: "1fr 3fr 1fr" }}
              >
            <div className="post-login-profile-container-img">
              <img src={vectors.User} />
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              <span>
                <FormattedMessage
                id={"navbar_user_my_profile_label"}
                defaultMessage={"MY PROFILE"}
              />
              </span>
              <h3>{this.props.userName}</h3>
            </div>
            <div  className="post-login-profile-next-arrow">
              <img
                src={vectors.ChevronRight}
              
              />
            </div>
          </Link>
        </div>

        <p>
          <FormattedMessage
                id={"navbar_post_login_general_label"}
                defaultMessage={"GENERAL"}
              />
        </p>

        <div className="mobile-navbar-horizontal-line"></div>

        <Link to={`/`} onClick={this.closeNav}>
          <img src={vectors.Home} />
          <span style={{ paddingLeft: "2rem" }}>
             <FormattedMessage
                id={"navbar_pre_login_home_link_label"}
                defaultMessage={"Home"}
              /> 
          </span>
        </Link>
       
        <a
          href="https://www.oyorooms.com/weblink?url=https://www.oyorooms.com/about/"
          target="_blank"
          onClick={this.closeNav}
        >
          <img src={vectors.AboutUs} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"navbar_about_us_label"}
                defaultMessage={"About Us"}
              /> 
          </span>
        </a>
       
        <a
          href="https://www.oyorooms.com/weblink?url=https://www.oyorooms.com/officialoyoblog/"
          target="_blank"
          onClick={this.closeNav}
        >
          <img src={vectors.Blog} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"navbar_blog_label"}
                defaultMessage={"Blog"}
              /> 
          </span>
        </a>
        
        {/* <div className="mobile-navbar-horizontal-line"></div> */}
        
        <div className="mweb-Language-selector-container" onClick={this.openLanguageOptions}>
          <img src={vectors.MWebGlobe} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"language_label"}
                defaultMessage={"Language"}
              />
          </span>
          <span style={{ paddingLeft: "4rem" ,textTransform: "uppercase" }}>{this.props.locale}{this.state.languageOptionsFlag ? <KeyboardArrowUpIcon style={{ marginLeft: "14px"  }}/> : <KeyboardArrowDownIcon style={{ marginLeft: "14px"  }}/>}</span>  
        </div>

         {this.state.languageOptionsFlag ? (languageDom) : (<div></div>)}

        <Link to={`/contact`} onClick={this.closeNav}>
          <img src={vectors.PhoneCall} />
          <span style={{ paddingLeft: "2rem" }}>
             <FormattedMessage
                id={"navbar_contact_us_label"}
                defaultMessage={"Contact Us"}
              />
          </span>
        </Link>
        
        <Link to={`/faqs`} onClick={this.closeNav}>
          <img src={vectors.Question} />
          <span style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"navbar_faqs_label"}
                defaultMessage={"FAQs"}
              />
          </span>
        </Link>

        <p>
           <FormattedMessage
                id={"navbar_post_login_my_account_label"}
                defaultMessage={"MY ACCOUNT"}
              />
        </p>

        <div className="mobile-navbar-horizontal-line"></div>
       
        <Link to={`/ResetPassword`} onClick={this.closeNav}>
          <img src={vectors.ChangePassword} />
          <span style={{ paddingLeft: "2rem" }}>
             <FormattedMessage
                id={"navbar_post_login_change_password_label"}
                defaultMessage={"Change Password"}
              />
          </span>
        </Link>
        
        <a
          href={void 0}
          onClick={this.menuOptionClickHandler}
          style={{ color: "white" }}
        >
          <img src={vectors.logOut} />
          <span data-name="logout" style={{ paddingLeft: "2rem" }}>
            <FormattedMessage
                id={"navbar_post_login_log_out_label"}
                defaultMessage={"Log out"}
              /> 
          </span>
        </a>
  </div> 
)


    navBarDom = (
      <div className="appbar-container" id="appbarContainer">
       

       <div className="appbar-desktop-container">
         <div className="appbar-logo-outer-container" style={{ cursor: "pointer" }} onClick={()=>this.goToHomePage()}>
          <div className="appbar-logo-inner-container"><img src ={logo}/></div>
          <p>
             <FormattedMessage
                        id={"world_leading_photography_parter_network_label"}
                        defaultMessage={"World's leading photography partner network"}
                      /> 
          </p>
       </div>

       <div className="appbar-menu-container">
            <a href="https://www.oyorooms.com/about/" target="_blank">
              <FormattedMessage
                id={"navbar_about_us_label"}
                defaultMessage={"About Us"}
              /> 
            </a>
              <a
              href="https://www.oyorooms.com/officialoyoblog/"
              target="_blank"
            >
              <FormattedMessage
                id={"navbar_blog_label"}
                defaultMessage={"Blog"}
              /> 
            </a>
            <Link to={`/contact`}>
              <FormattedMessage
                id={"navbar_contact_us_label"}
                defaultMessage={"Contact Us"}
              />
            </Link>
              <Link to={`/faqs`}>
              <FormattedMessage
                id={"navbar_faqs_label"}
                defaultMessage={"FAQs"}
              />
            </Link>
            <div className="appbar-menu-verticle-container"></div>
             <I18Info />
            {!this.props.isAuthenticated ? (
              loginDom
            ) : (
              userDom
            )}
       </div>

       </div>
       
       <div className="appbar-mobile-contanier" id="appbarMobileContainer">
          
          <div id="sideNav" className="sidenav">
            <a href={void 0} className="mobile-navbar-close-btn" onClick={this.closeNav}>
              <img src={vectors.ArrowRight} />
            </a>
            {!this.props.isAuthenticated ? preLogInDom : postLogInDom}
          </div>
          
         <div style={{ display: "block", padding: "28px 16px 16px 16px" }}>
            <img src={vectors.MWebDrawer} onClick={this.openNav} />
         </div>
        
        <div className="mobile-content-container" >
         <div className="mobile-logo-container" style={{ cursor: "pointer" }} onClick={()=>this.goToHomePage()}><img src ={vectors.MWebLogo}/></div>
       </div>

       </div>
      
      </div>
   
    );

    return navBarDom;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userType: state.auth.userType,
    userName: state.auth.userName,
    locale : state.i18n.locale
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
    changeLocale: (locale) => dispatch( actionCreators.changeLocale(locale) )
  };
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(SimpleAppBar)));

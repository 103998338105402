import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./Footer.css";
import * as vectors from "../../Resources/Vectors/index";
import { FormattedMessage } from 'react-intl';
class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // let pathDependentDom = null;
    let footerDom = null;
    var d = new Date();
    let currentYear = d.getFullYear();

    footerDom = (
    <div className="footer-outer-container">
           <div className="footer-dom-container">
         {this.props.pathName === '/' ? (<div className="mweb-footer-horizontal-line-container"></div>) : (<div className="horizontal-line-container"></div>)}
         {/* <div className="horizontal-line-container"></div> */}
         <div className="header-container-footer">
           <img src={vectors.OYO} />
           <h4>
              <FormattedMessage
                    id={"world_fastes_hotel_chain_label"}
                    defaultMessage={"World's fastest growing hotel chain."}
                />  
           </h4>
         </div>
       
        <div className="horizontal-line-container-1"></div>
         
         <div className="footer-dom-content-container">

           <div className="left-container">
              
             <div className="inner-left-container">
                <a
                  href="https://www.oyorooms.com/officialoyoblog/"
                  target="_blank"
                 >
                <FormattedMessage
                      id={"oyo_official_blog_label"}
                      defaultMessage={" OYO official blog"}
                  />  
                </a>

                <Link to={`/contact`}>
                <FormattedMessage
                      id={"contact_us_label"}
                      defaultMessage={"Contact us"}
                  />  
                </Link>


               </div>

             <div className="inner-right-container">
               <a
                  href=""
                  // target="_blank"
                 >
                <FormattedMessage
                      id={"terms_of_use_label"}
                      defaultMessage={"Terms of Use"}
                  />  
                </a>

                <a
                  href=""
                  // target="_blank"
                 >
                <FormattedMessage
                      id={"partner_ship_agreement_label"}
                      defaultMessage={"Partnership Agreement"}
                  />  
                </a>

                <a
                  href=""
                  // target="_blank"
                 >
                <FormattedMessage
                      id={"privacy_policy_label"}
                      defaultMessage={"Privacy Policy"}
                  />  
                </a>
             </div>
             
           </div>

          <div className="right-container">
                <p>
                <FormattedMessage
                      id={"download_oyo_app_label"}
                      defaultMessage={"Download OYO app for exciting offers"}
                  />  
                </p>
               
                <div className="social-icon-apple-google">
                    <a
                      href="https://apps.apple.com/in/app/oyo-rooms-book-delightful/id988141624"
                      target="_blank"
                    >
                      <img src={vectors.iStore}  />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.oyo.consumer&hl=en"
                      target="_blank"
                    >
                      <img src={vectors.googlePlay}  />
                    </a>
                </div>
                
                <p>
                <FormattedMessage
                      id={"feeling_lucky_label"}
                      defaultMessage={"Feeling lucky? Try out a random OYO."}
                  />
                <a
                  href="https://www.oyorooms.com/?utm_source=Frames_Web&utm_medium=1&utm_content=oyoframeswebfooter"
                  target="_blank"
                  style={{
                    textDecorationLine: "underline",
                    color: "#222222",
                    fontWeight: "bolder"
                  }}
                >
                  <FormattedMessage
                      id={"find_oyo_label"}
                      defaultMessage={" Find OYO"}
                  />    
                 </a>
              </p>

          </div>

         </div>
         
         <div className="horizontal-line-container"></div>
         
         <div className="social-media-container">
           
           <div className="left-social-media-container">
                  <a href="https://www.facebook.com/oyorooms/" target="_blank">
                    <img src={vectors.Facebook} />
                  </a>
                  <a
                    href="https://www.instagram.com/oyorooms"
                    target="_blank"
                  >
                    <img src={vectors.Instagram} />
                  </a>
                  <a href="https://twitter.com/oyorooms/" target="_blank">
                    <img src={vectors.Twitter} />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UC1b6tyXZTHdIZ5vmgoAqn9w/"
                    target="_blank"
                  >
                    <img src={vectors.Youtube} />
                  </a>
                  <a href="https://www.pinterest.com/oyorooms/" target="_blank">
                    <img src={vectors.Pinterest} />
                  </a>
           </div>

           <div className="right-social-media-container">
              2018 - {currentYear} &copy; Oravel Stays Private Limited
           </div>
         </div>

      </div>

    </div>
        );

    return footerDom;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userType: state.auth.userType,
    error: state.auth.error,
    loading: state.auth.loading
  };
};

export default connect(mapStateToProps)(Footer);

import React from 'react';

import "./DateRange.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRange = (props) => {
  let {startDate, endDate, changeDate} = props;
  return (
    <div className={"date-range__block"}>
      <div className={"date-range__block--start-date"}>
        <p className={"date-range__block--label"}>From Date</p>
        <DatePicker
          selected={startDate}
          onChange={date => changeDate(date, "startDate")}
          placeholderText={"From"}
          dateFormat={"dd/MM/yyyy"}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className={"date-range__block--end-date"}>
        <p className={"date-range__block--label"}>To Date</p>
        <DatePicker
          selected={endDate}
          onChange={date => changeDate(date, "endDate")}
          placeholderText={"To"}
          dateFormat={"dd/MM/yyyy"}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </div>
    </div>
  );
};

export default DateRange;